import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContactFinderForm from './ContactFinderForm';

const UNKNOWN_ATTRIBUTE_VALUE = 'Unknown';
const NO_ADDITIONAL_INFO_VALUE = 'None';

const FoundPetsCards = ({ foundPets, fbFunctions, user }) => {
  const [showForm, setShowForm] = useState(false);
  const [popup, setPopup] = useState(<ContactFinderForm petName="Pet Name" DPID="DPID" setShowForm={setShowForm} />);

  /**
   * Sorts pets by DPID.
   * Helper function for the sort method.
   */
  const compare = (a, b) => {
    // DP comes before FP
    const aID = a.FPID || a.DPID;
    const bID = b.FPID || b.DPID;
    if (aID.substring(0, 1) < bID.substring(0, 1)) return -1;
    if (aID.substring(0, 1) > bID.substring(0, 1)) return 1;
  
    if (aID.length < bID.length) return -1;
    if (aID.length > bID.length) return 1;
    if (aID < bID) return -1;
    if (aID > bID) return 1;
    return 0;
  }

  foundPets.sort(compare);

  /**
   * Returns the ContactFinderForm component.
   * @param {string} petName The name of the pet.
   * @param {string} ID The Disaster Pets ID of the pet.
   */
  const contactForm = (petName, ID, ownerEmail, currentContactEmail) => (
    <ContactFinderForm
      petName={petName}
      DPID={ID}
      ownerEmail={ownerEmail}
      currentContactEmail={currentContactEmail}
      setShowForm={setShowForm}
      fbFunctions={fbFunctions}
      user={user}
    />
  );

  /**
   * Displays a popup form when clicking the contact owner button.
   * @param {string} petName The name of the pet.
   * @param {string} ID The Disaster Pets ID of the pet.
   */
  const onClickContactOwner = (petName, ID, ownerEmail, currentContactEmail) => {
    setShowForm(true);
    setPopup(contactForm(petName, ID, ownerEmail, currentContactEmail, setShowForm));
  };

  return (
    <div>
      <div className="contact-owner-popup">
        {showForm ? popup : null }
      </div>

      {/* Create cards for pets that are within the bounds of the map */}
      <div className="card-container">
        {foundPets.map((pet) => {
          const {
            DPID, FPID, PetName, PetSpecies, PetBreed, URL,
            PetChip, PetTattooNumber, PetAdditionalDetails,
            OwnerEmail, CurrentContactEmail
          } = pet;

          let petChipValue = PetChip;
          let petTattoValue = PetTattooNumber;
          let petAdditionalDetails = PetAdditionalDetails;

          // Need to generalize the ID because the database contains 2 keys for
          // Found pets and Registered pets
          let ID;

          if (DPID === undefined || DPID === '') {
            ID = FPID;
          } else {
            ID = DPID;
          }

          if (petAdditionalDetails === undefined || petAdditionalDetails === '') {
            petAdditionalDetails = NO_ADDITIONAL_INFO_VALUE;
          }

          if (petChipValue === undefined || petChipValue === '') {
            petChipValue = UNKNOWN_ATTRIBUTE_VALUE;
          }

          if (petTattoValue === undefined || petTattoValue === '') {
            petTattoValue = UNKNOWN_ATTRIBUTE_VALUE;
          }

          return (
            <div key={DPID} id={DPID} className="card">
              <img src={URL} alt="animal" className="margin-bottom-10 card-img-top" />
              <h2 className="card-pet-header">
                {`Disaster Pets ID #: ${ID}`}
                <br />
                {`Name: ${PetName}`}
              </h2>
              <div className="card-body">
                <p>
                  {`Species: ${PetSpecies}`}
                  <br />
                  {`Breed: ${PetBreed}`}
                  <br />
                  {`Microchip: ${petChipValue}`}
                  <br />
                  {`Tattoo: ${petTattoValue}`}
                  <br />
                </p>
                <p className="card-additional-details">{`Additional Details: ${petAdditionalDetails}`}</p>
              </div>
              <div className="contact-owner-button-container">
                <button
                  type="submit"
                  className="card-contact-owner-button"
                  onClick={() => onClickContactOwner(PetName, ID, OwnerEmail, CurrentContactEmail)}
                >
                  Contact Finder
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

FoundPetsCards.propTypes = {
  foundPets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fbFunctions: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
};

export default FoundPetsCards;
