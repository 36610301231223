import React, { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="page_container" className="text-align-center">
      <div className="content-wrap">
        <div className="div_standard_text">
          <h2 className="padding-bottom-50 margin-top-0">Contact Disaster Pets</h2>

          <p>
            If you have any questions, comments, or concerns, please contact us via email at
            <a href="mailto:disasterpetsca@gmail.com" className="link-blue"> disasterpetsca@gmail.com</a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
