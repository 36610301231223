import React from 'react';
import PropTypes from 'prop-types';

const OrganizationAdmin = ({ change, submit }) => (
  <div className="col-md-4">
    <form onSubmit={submit}>
      <div className="form-group">
        <label htmlFor="org-email">
          <span style={{ float: 'left' }}>Organization Admin</span>
          <input
            type="email"
            onChange={change}
            className="form-control margin-top-10 margin-bottom-10"
            placeholder="Enter Email"
            id="gorg-email"
            required
          />
          <button type="submit" className="btn btn-primary btnDisable">Add Organization Admin</button>
        </label>
      </div>
    </form>
  </div>
);
OrganizationAdmin.propTypes = {
  change: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};
export default OrganizationAdmin;
