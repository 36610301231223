import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import Modal from 'react-modal';
import TermsAndConditions from '../TermsAndConditions';

import LoggedInMenu from './LoggedInMenu';

const VIDEO_FIRESTORE_KEY = 'promotionVideo';

Modal.setAppElement('#root');

const Index = ({ user, firebase, firestore }) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [verifyEmailMessage, setVerifyEmailMessage] = useState(false);

  const [signupEmail, setSignupEmail] = useState('');
  const [signupPassword, setSignupPassword] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [termsAgreedForSignUp, setTermsAgreedForSignUp] = useState(false);
  const [termsAgreedForGoogleSignIn, setTermsAgreedForGoogleSignIn] = useState(false);


  /**
   * Get Video URL
   */
  useEffect(() => {
    if (firestore) {
      firestore.collection(VIDEO_FIRESTORE_KEY).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc && doc.data() && doc.data().url) {
            setVideoUrl(doc.data().url);
          }
        });
      });
    }
  }, [firestore]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const trySignUpWithEmail = () => {
    if (!termsAgreedForSignUp) {
      window.alert("Please check that you have read and accept the Terms and Conditions.");
      return;
    }

    if (signupEmail.length === 0 || signupPassword.length === 0) {
      window.alert("Email or Password cannot be empty.");
      return;
    }

    createAccount();
  }

  const tryGoogleLogin = () => {
    if (!termsAgreedForGoogleSignIn) {
      window.alert("Please check that you have read and accept the Terms and Conditions.");
      return;
    }

    googleLogin();
  }

  /**
   * Login with Google
   */
  const googleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await firebase.auth().signInWithPopup(provider);
    } catch (err) {
      window.alert(err);
    }
  };

  /**
   * Create account with email
   */
  const createAccount = async () => {
    try {
      await firebase.auth().createUserWithEmailAndPassword(signupEmail, signupPassword);
      firebase.auth().currentUser.sendEmailVerification();
      await firebase.auth().signOut();
      window.alert("An e-mail was sent for account confirmation.\nPlease check your junk folder if the message doesn't appear in your inbox.");
      setEmailSent(true);
      setSignupEmail('');
      setSignupPassword('');
    } catch (err) {
      window.alert(err.message);
    }
  };

  const toggleTermsAndConditionsForSignUp = () => {
    setTermsAgreedForSignUp(!termsAgreedForSignUp);
  }

  const toggleTermsAndConditionsForGoogle = () => {
    setTermsAgreedForGoogleSignIn(!termsAgreedForGoogleSignIn);
  }

  /**
   * Login with Email
   */
  const loginWithEmail = async (e) => {
    e.preventDefault();

    if (loginEmail.length === 0 || loginPassword.length === 0) {
      window.alert("Email or Password cannot be empty.");
      return;
    }

    try {
      await firebase.auth()
        .signInWithEmailAndPassword(loginEmail, loginPassword);
      if (!firebase.auth().currentUser.emailVerified) {
        setVerifyEmailMessage(true);
      } else {
        window.scrollTo(0, 0);
      }
    } catch (err) {
      if (err.code === 'auth/user-not-found') {
        window.alert(`${err.message} Please Sign Up.`);
      } else {
        window.alert(err.message);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (user.isAuthenticating()) {
    return (<div className="loader">Loading</div>);
  }

  if (user.isAuthenticated()) {
    return <LoggedInMenu />;
  }

  return (
    <div className="flex-column align-items-center justify-content-center padding-top-30">
      <div className="border padding-10 text-align-center margin-bottom-30" style={{ maxWidth: 800, width: '90%' }}>
        <h2 className="margin-top-5 margin-bottom-20">
          <strong>Disaster Pets Communication and Mapping System</strong>
        </h2>
        <h3 className="margin-bottom-20">
          Connecting pet owners, animal organizations,
          and government agencies for collaborative disaster animal response
        </h3>
        <iframe className="max-width border-none" id="promotionVideoUrl" src={videoUrl} allowFullScreen title="Disaster Pets Video Promotion" style={{ height: 400, maxHeight: 400 }} />
      </div>
      <div className="max-width justify-content-center index-forms">
        <form className="background-grey flex-column padding-20 margin-left-10 margin-right-10 text-align-center login-form" onSubmit={loginWithEmail}>
          <h2 className="margin-0 margin-bottom-20">Login</h2>

          <div className="form-check mb-3 text-align-left">
            <input className="form-check-input" type="checkbox" value="" id="termsAndConditionsForGoogle" onChange={toggleTermsAndConditionsForGoogle} />
            <label className="form-check-label" htmlFor="termsAndConditionsForGoogle">
              I acknowledge that I have read and accept the <a href="#" onClick={openModal} className="link-blue margin-top-20 cursor-pointer"><u>Terms and Conditions</u></a>.
            </label>
          </div>

          <button type="button" className="color-white border-radius-5 text-align-left h3 padding-5 margin-bottom-20 cursor-pointer" style={{ backgroundColor: '#dd4b39' }} onClick={tryGoogleLogin}>
            <FontAwesomeIcon icon={faGoogle} className="margin-right-20" />
            Sign in with Google
          </button>

          <div className="separator">OR</div>

          {verifyEmailMessage && (<p>Please verify your email account</p>)}
          <input className="border-radius-5 border-none padding-5 border-grey margin-bottom-20 h3 padding-left-10" type="email" placeholder="Email" value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} />
          <input className="border-radius-5 border-none padding-5 border-grey margin-bottom-20 h3 padding-left-10" type="password" placeholder="Password" value={loginPassword} onChange={(e) => setLoginPassword(e.target.value)} />
          <input type="submit" value="Login with Email" className="border-radius-5 border-none padding-5 border-grey h3 padding-left-10 background-one color-white cursor-pointer" />
          <Link to="/forgot_password" className="link-blue margin-top-20 cursor-pointer">Forgot Password?</Link>
        </form>
        <form className="background-grey flex-column padding-20 margin-left-10 margin-right-10 text-align-center login-form">
          <h2 className="margin-0 margin-bottom-20">Sign up</h2>
          <input className="border-radius-5 border-none padding-5 border-grey margin-bottom-20 h3 padding-left-10" type="email" placeholder="Email" value={signupEmail} onChange={(e) => setSignupEmail(e.target.value)} />
          <input className="border-radius-5 border-none padding-5 border-grey margin-bottom-20 h3 padding-left-10" type="password" placeholder="Password" value={signupPassword} onChange={(e) => setSignupPassword(e.target.value)} />
          <div className="form-check mb-3 text-align-left">
            <input className="form-check-input" type="checkbox" value="" id="termsAndConditions" onChange={toggleTermsAndConditionsForSignUp} />
            <label className="form-check-label" htmlFor="termsAndConditions">
              I acknowledge that I have read and accept the <a href="#" onClick={openModal} className="link-blue margin-top-20 cursor-pointer"><u>Terms and Conditions</u></a>.
            </label>
          </div>
          <button type="button" className="border-radius-5 border-none padding-5 border-grey h3 padding-left-10 background-one color-white cursor-pointer" onClick={trySignUpWithEmail} >Signup with Email</button>
          {emailSent && (
            <span className="margin-top-20">
              Email has been sent for your account confirmation.
              <br />
              Please check your spam folder if the message doesn&apos;t appear in your inbox.
            </span>
          )}
        </form>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Terms and Conditions"
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '70px',
            left: '40px',
            right: '40px',
            bottom: '40px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
          }
        }}
      >
        <TermsAndConditions firestore={firestore} />

        <div className="text-align-center">
          <button onClick={closeModal}>Close</button>
        </div>
      </Modal>
    </div>
  );
};

Index.propTypes = {
  user: PropTypes.shape().isRequired,
  firebase: PropTypes.shape().isRequired,
  firestore: PropTypes.shape().isRequired,
};

export default Index;
