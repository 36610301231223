import React from 'react';
import PropTypes from 'prop-types';

const CareInfo = ({careLocation, careName, carePhone, careEmail}) => (
  <div className="margin-bottom-30">
    <b>Location</b>
    :
    {careLocation}
    <br />
    <b>Contact</b>
    :
    {careName}
    <br />
    <b>Phone</b>
    :
    {carePhone}
    <br />
    <b>Email</b>
    :
    {careEmail}
  </div>
);

CareInfo.propTypes = {
  careLocation: PropTypes.string.isRequired,
  careName: PropTypes.string.isRequired,
  carePhone: PropTypes.string.isRequired,
  careEmail: PropTypes.string.isRequired,
};

export default CareInfo;
