import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

/**
 * Private Route Higher Order Component to handle Routes that need authentication to access.
 */
const AdminRoute = ({ component: Component, user, ...rest }) => {
  /**
   * Authentication check whether the user has permission to access the page.
   */
  const authenticateUser = () => user.isAuthenticated();
  const hasPermissions = () => user.getAdminPermissions();

  if (user.isAuthenticating()) {
    return null;
  }

  if (!hasPermissions()) {
    alert('You must be an authorized user in order to access this page. Please contact Disaster Pets for more information.');
  }

  return (
    <Route
      {...rest}
      render={() => (authenticateUser() && hasPermissions() ? <Component {...rest} user={user} /> : <Redirect to="/" />)}
    />
  );
};

AdminRoute.propTypes = {
  component: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

export default AdminRoute;
