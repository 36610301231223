import React, { useEffect } from 'react';
import DonateButton from './DonateButton';
import List from './List';

/**
 * Text before the donate button.
 */
const beforeDonateButton = [
  {
    objectID: 0,
    title: 'DONATIONS',
    text:
    'Disaster Pets communications and mapping systems provide support to those involved in disaster animal response, from rescue request to reunification.\n'
    + 'There are many additional functions we would like to add to this site to help improve the management of disaster animal response.\n'
    + 'Your contribution allows us to maintain this site and continuously improve our systems in response to their needs',
  },
];

/**
 * Text after the donate button.
 */
const afterDonateButton = [
  {
    objectID: 1,
    title: 'SPONSORSHIP',
    text: 'If your company would like to sponsor Disaster Pets, please contact us at: disasterpetsca@gmail.com',
  },

  {
    objectID: 2,
    title: 'GRANTS AND FINANCIAL AID',
    text: 'If you know of any grants or funding sources which might apply to Disaster Pets, please let us know! disasterpetsca@gmail.com',
  },
];

const Donate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="text-align-center">
      <List list={beforeDonateButton} />
      <DonateButton />
      <List list={afterDonateButton} />
    </div>
  );
};

export default Donate;
