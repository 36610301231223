import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const PreRegisterConfirm = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="page_container" className="text-align-center">
      <div className="padding-top-30">
        <div className="centered_button">
          <div className="adjusted_box">
            <h2 className="margin-top-30 text-align-center">
              You have successfully&nbsp;
              <b>
                PRE-REGISTERED
              </b>
              &nbsp;this pet.
            </h2>
            <h2 className="text-align-center">
              No request for assistance will be registered until you
              activate that request at the time of an evacuation.
            </h2>
            <br />
            <h4 className="text-align-center">
              To activate a request for assistance, complete the following steps:
            </h4>
            <br />
            <ul>
              <li className="padding-bottom-10 li-1rem">
                Click My Registered Pets
              </li>
              <li className="padding-bottom-10 li-1rem">
                Click Go to Pet Profile Page for the pet requiring assistance
              </li>
              <li className="padding-bottom-10 li-1rem">
                Click Activate Request for Assistance
              </li>
              <li className="padding-bottom-10 li-1rem">
                Review the pre-registered information and make any necessary edits
              </li>
              <li className="padding-bottom-10 li-1rem">
                Pay close attention to Pet Rescue Location and indicate if your pet
                is at an exact address or a general location.
              </li>
              <li className="padding-bottom-10 li-1rem">
                Click Submit
              </li>
            </ul>
          </div>
          <button
            className="primary_button form-buttons"
            type="button"
            onClick={(() => (
              history.push('/myRegisteredPets')
            ))}
          >
            GO TO MY REGISTERED PETS
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreRegisterConfirm;
