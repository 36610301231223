import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LostPetsMap from './LostPetsMap';
import LostPetsCards from './LostPetsCards';
import getPetsArrayInBound from './FilterPetsInBound';
import VISIBLE_PET_ATTRIBUTES from './VisiblePetAttributes';

const REGISTERED_PET_COLLECTION = 'registeredPets';
const VALID_LOST_STATUS = ['REQUIRES EVACUATION', 'LOST PET'];

const INITIAL_LAT = 49.251;
const INITIAL_LNG = -122.999;
const DIFFERENCE = 3;

const INITIAL_MIN_LAT = INITIAL_LAT - DIFFERENCE;
const INITIAL_MAX_LAT = INITIAL_LAT + DIFFERENCE;
const INITIAL_MIN_LNG = INITIAL_LNG - DIFFERENCE;
const INITIAL_MAX_LNG = INITIAL_LNG + DIFFERENCE;

const LostPets = ({ user, firestore, fbFunctions }) => {
  const [lostPets, setLostPets] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [keywordPets, setKeywordPets] = useState([]);
  const [allLostPets, setAllLostPets] = useState([]);
  const [mapMinLng, setMapMinLng] = useState(INITIAL_MIN_LNG);
  const [mapMinLat, setMapMinLat] = useState(INITIAL_MIN_LAT);
  const [mapMaxLng, setMapMaxLng] = useState(INITIAL_MAX_LNG);
  const [mapMaxLat, setMapMaxLat] = useState(INITIAL_MAX_LAT);
  const [mapChanged, setMapChanged] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Reads from the database once to get all lost pets.
   */
  useEffect(() => {
    /**
     * Gets the collection of lost pets from the database.
     */
    const getLostPetsCollection = async () => {
      try {
        const snapshot = await firestore.collection(REGISTERED_PET_COLLECTION)
          .where('PetStatus', 'in', VALID_LOST_STATUS)
          .orderBy('DateInitialEntry')
          .get();
        return snapshot.docs.map((element) => element.data());
      } catch (err) {
        console.error(err);
        return [];
      }
    };

    const getLostPets = async () => {
      const lostPetsCollection = await getLostPetsCollection();

      setAllLostPets(lostPetsCollection);
      setKeywordPets(lostPetsCollection);
      setLostPets(lostPetsCollection);
    };
    if (user.isAuthenticated()) {
      getLostPets();
    }
  }, [user]);

  /**
   * Updates the lost pets cards that matches the keyword.
   */
  const searchKeyword = () => {
    const setOfPets = new Set();
    const currentPetsOnMap = getPetsArrayInBound(
      allLostPets,
      mapMinLat,
      mapMinLng,
      mapMaxLat,
      mapMaxLng,
    );

    if (keyword === undefined || keyword === '') {
      setKeywordPets(allLostPets);
      setLostPets(currentPetsOnMap);
      return;
    }

    for (let i = 0; i < currentPetsOnMap.length; i += 1) {
      if (setOfPets.length === currentPetsOnMap.length) break;
      for (let j = 0; j < VISIBLE_PET_ATTRIBUTES.length; j += 1) {
        let val = currentPetsOnMap[i][VISIBLE_PET_ATTRIBUTES[j]];

        if (val === undefined) {
          val = 'Unknown';
        }
        if (val.toLowerCase().includes(keyword.toLowerCase())) {
          setOfPets.add(currentPetsOnMap[i]);
        }
      }
    }
    const filteredPets = Array.from(setOfPets);
    setKeywordPets(filteredPets);
    setLostPets(filteredPets);
  };

  /**
   * Updates the keyword.
   * @param {*} event A synthetic event.
   */
  const handleChange = (event) => {
    setKeyword(event.target.value);
  };

  /**
   * Listens for change in search bar.
   * Changes the lost pets displayed based on keyword.
   */
  useEffect(() => {
    searchKeyword();
  }, [keyword, mapChanged]);

  /**
   * Search keyword when user pressess the Enter button.
   * @param {*} event A synthetic event.
   */
  // const handleEnterButton = (event) => {
  //   if (event.key === 'Enter') {
  //     searchKeyword();
  //   }
  // };

  return (
    <div className="text-align-center lost-pets-container padding-top-20">
      <h2>Lost Pets</h2>
      <LostPetsMap
        setLostPets={setLostPets}
        keywordPets={keywordPets}
        allLostPets={allLostPets}
        setMapMinLng={setMapMinLng}
        setMapMaxLng={setMapMaxLng}
        setMapMinLat={setMapMinLat}
        setMapMaxLat={setMapMaxLat}
        setMapChanged={setMapChanged}
      />

      <br />
      <p>
        The pets listed on this page are currently missing or requiring evacuation.
        If you have found a pet listed on this page, please click the &quot;Contact Owner&quot;
        button to inform the owner.
      </p>

      <p>
        Zoom in or out on the map to view the Lost Pets located in that area. Exact pet
        locations are visible only to authorized government officials and responding agencies.
      </p>

      <p>Use the Search bar to narrow your search (eg. &quot;dog&quot; or &quot;Daisy&quot;).</p>
      <br />
      <span>
        <strong>Search: </strong>
        <input type="text" className="lost-pets-search-bar" onChange={handleChange} />
        <button type="submit" className="lost-pets-search-button">Submit</button>
      </span>
      <br />
      <br />

      <LostPetsCards lostPets={lostPets} fbFunctions={fbFunctions} user={user} />

      <p className="lost-pets-small-text">
        *Disaster Pets is a communications platform and does not have the legal authority
        to give permission to individuals or organizations to enter a dwelling or private property.
        <br />
        Additionally, we have no ability to verify that the individual who is registering the pet
        is actually the owner of either that pet or the property. In order to cover yourself
        legally, Disaster Pets recommends that any groups or individuals first obtain explicit
        permission from authorities to enter a dwelling or property before doing so.
        Disaster Pets does not take responsibility for the actions taken by others and does not
        take on the liability for any damage caused to property, or any injury or
        death to pets or responders.
      </p>
    </div>

  );
};

LostPets.propTypes = {
  user: PropTypes.shape().isRequired,
  firestore: PropTypes.shape().isRequired,
  fbFunctions: PropTypes.shape().isRequired,
};

export default LostPets;
