import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const RegisterConfirm = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="page_container" className="text-align-center">
      <div className="padding-top-30">
        <div className="centered_button">
          <div className="adjusted_box">
            <h4 className="margin-top-30"><b>Your pet has successfully been registered</b></h4>
            <br />
            <ul>
              <li className="padding-bottom-10">
                Disaster Pets may not be being monitored by your local authorities or
                animal welfare organizations. Please confirm that they are using this
                communications platform. Otherwise, your pet rescue request may go unseen!
              </li>
              <li className="padding-bottom-10">
                Periodically check the following for potential updates on your pet&apos;s
                Status/Location:
              </li>
              <ul>
                <li className="padding-bottom-10">
                  Pet Profile page for Status/Location update.
                </li>
                <li className="padding-bottom-10">
                  Your email, including the Junk folder, to see if someone has messaged you.
                </li>
                <li className="padding-bottom-10">
                  See All Lost/Found Pets page to see if someone has registered your pet.
                </li>
              </ul>
              <li className="padding-bottom-10">
                Please change the Evacuation Status to “Reunited With Owner” on the Pet Profile
                page when the pet and owner are reunited.
              </li>
            </ul>
          </div>
          <button
            className="primary_button form-buttons"
            type="button"
            onClick={(() => (
              history.push('/myRegisteredPets')
            ))}
          >
            GO TO MY REGISTERED PETS
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterConfirm;
