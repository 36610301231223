import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RescueTimelineModal from './RescueTimelineModal';

const RescueTimeline = ({RescueTimeline,userID, petDbId, submit}) => {
  const [showCommentModal, setShowCommentModal] = useState(false);
  let RescueTreversed = RescueTimeline.sort((a, b) => a.DateUpdated < b.DateUpdated ? 1 : -1);
  // for prod
  let timeline =  RescueTreversed.map((field) =>(
    <div key={field.DocumentID} className="StatusHistoryField" >
      <p>
        Date/Time: <b>{field.DateUpdated}</b>
      </p>
      <p>
        Comment: <b>{field.RescueComment}</b>
      </p>
      <p>
        Organization: <b>{field.Organization}</b>
      </p>
      <p>
        Contact Name: {field.ContactName}
      </p>
      <p>
        Contact Number: {field.ContactNumber}
      </p>
      <p>
        Contact Email: {field.ContactEmail}
      </p>
    </div>
  ));
  let show = RescueTimeline.length != 0 ? timeline : <p> There Are No Comments </p>;
  const toggleShowCommentModal = () => {
    const currentShowCommentModal = showCommentModal;
    setShowCommentModal(!currentShowCommentModal);
  };
  return (
    <div id="rescue_timeline_div" className="margin-top">
      <br />
      <div>
        <button type="button" onClick={toggleShowCommentModal} className="btn btn-danger margin-bottom-20">Add a Rescue Comment</button>
        {showCommentModal ? <RescueTimelineModal userID={userID} petDbId={petDbId} submit={submit} close={toggleShowCommentModal}/>: null}
        {show}
      </div>
    </div>
  );
};

// RescueTimeline.propTypes = {
//   userID: PropTypes.string.isRequired,
//   petDbId: PropTypes.string.isRequired,
//   submit: PropTypes.func().isRequired,
// };

export default RescueTimeline;
