const FeedingStationFormInputs = `
  <hr>
  <section>
      <div class="text-align-left flex-column">
          <label for='food-type-input'>Food Type: </label>
          <input id='food-type-input' type='text' required>
      </div>
      <div class="text-align-left flex-column">
          <label for='food-amount-input'>Amount (lbs): </label>
          <input id='food-amount-input' type='text' required> 
      </div>
  </section>
  <hr>
  <section>
          <div class="text-align-left flex-column">
              <label for='observed-pets-input'>Pets Observed: </label>
              <input id='observed-pets-input' type='text' placeholder="e.g. DP1, DP2" required>
          </div>
  </section>
  <hr>
  <section>
      <div class="text-align-left flex-column">
          <label for='comment-input'>Make a Comment: </label>
          <textarea id='comment-input' rows='4' cols='50' required></textarea>
      </div>
  </section>
  <hr>
  <section>
    <div class="text-align-left flex-column">
        <label for='name-input'>Name*: </label>
        <input id='name-input' type='text' required>
    </div>
    <div class="text-align-left flex-column">
        <label for='phone-number-input'>Phone Number*: </label>
        <input id='phone-number-input' type='text' required>
    </div>
    <br>
    <div class="text-align-left flex-column">
        <br>
        <label for='email-input'>Email*: </label>
        <input id='email-input' type='text' required>
    </div>
  </section>
`;

export const FeedingStationForm = `
  <form id="feeding-station-form" class="text-align-center">
    <h2>Add a Feeding Station</h2>
    <section>
      <div class="text-align-left flex-column">
        <label for='feeding-station-name-input'>Feeding Station's Name:</label>
        <input id='feeding-station-name-input' type='text' required>
      </div>
    </section>
    <hr>
    <section>
      <div class="text-align-left flex-column">
        <label for='location-input'>Location:</label>
        <input id='location-input' type='text' required>
      </div>
    </section>
    ${FeedingStationFormInputs}
    <hr>
    <section>
      <div class='entire-column button-wrapper'>
        <button 
            id='feeding-station-submit-button' 
            class="padding-10 cursor-pointer"
            style="color: white; background-color: green;"
            type='button'
        >
            Add
        </button>
        <button 
            id='feeding-station-cancel-button'
            class="padding-10 cursor-pointer"
            style="color: white; background-color: red;"
            type='button'
        >
            Cancel
        </button>
      </div>
    </section>
  </form>
`;

export const FeedingStationInfoWindow = `
<div id="feeding-station-info-window" class="text-align-center">
    <h2>Feeding Station</h2>
    <h3 id='feeding-station-header'></h3>
    <section>
        <div class='text-align-left'>
            <label class='feeding-station-label' for='feeding-station-date'>Date Added: </label>
            <input id='feeding-station-date' class='feeding-station-input' disabled>
        </div>
        <div class='text-align-left'>
            <label class='feeding-station-label' for='food-place'>Location: </label>
            <input id='food-place' class='feeding-station-input' disabled>
        </div>
    </section>
    <hr>
    <section>
        <div class='entire-column button-wrapper flex-row'>
            <button 
                id='feeding-station-timeline-button'
                type='button'
                class="padding-10 cursor-pointer flex margin-20"
                style="color: white; background-color: green;"
            >
                Add to Timeline
            </button>
            <button
                style="color: white; background-color: red;"
                id='feeding-station-delete-button'
                class="padding-10 cursor-pointer flex margin-20"
                type='button'
            >
                Delete Feeding Station
            </button>
        </div>
    </section>
    <section>
        <div id='hidden-input-section' style='display: none;'>
            ${FeedingStationFormInputs}
            <br>
            <div class='entire-column button-wrapper'>
                <button 
                    id='comment-submit-button' 
                    class='feeding-station-button'
                    style="color: white; background-color: green; padding: 10px; border-radius: 5px;"
                    type='button'>Add
                </button>
            </div>
        </div>
    </section>
    <hr>
    <section>
        <div id='comment-section' class='entire-column'>
            <h4>Feeding Station Timeline</h4>
        </div>
    </section>
    </div>
`;
