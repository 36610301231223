import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

/**
 * Private Route Higher Order Component to handle Routes that need authentication to access.
 */
const PrivateRoute = ({ component: Component, user, ...rest }) => {
  /**
   * Authentication check whether the user is currently logged in.
   */
  const authenticateUser = () => user.isAuthenticated();

  if (user.isAuthenticating()) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={() => (authenticateUser() ? <Component {...rest} user={user} /> : <Redirect to="/" />)}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

export default PrivateRoute;
