import React from 'react';
import PropTypes from 'prop-types';

const SubPolicyNoList = ({
  uniqueKey, policyID, subPolicyID, subPolicyText,
}) => (
  <div key={uniqueKey}>
    <p>
      {policyID}
      .
      {subPolicyID}
      &nbsp;&nbsp;&nbsp;&nbsp;
      {subPolicyText}
    </p>
  </div>
);

SubPolicyNoList.propTypes = {
  uniqueKey: PropTypes.string.isRequired,
  policyID: PropTypes.number.isRequired,
  subPolicyID: PropTypes.number.isRequired,
  subPolicyText: PropTypes.string.isRequired,
};

export default SubPolicyNoList;
