import React from 'react';
import PropTypes from 'prop-types';

const SubPolicyWithList = ({
  uniqueKey, policyID, subPolicyID, subPolicyText, subPolicyList,
}) => (
  <div key={uniqueKey}>
    <p>
      {policyID}
      .
      {subPolicyID}
      &nbsp;&nbsp;&nbsp;&nbsp;
      {subPolicyText}
    </p>
    <ul>
      {subPolicyList.map((listPoints) => <li key={listPoints}>{listPoints}</li>)}
    </ul>
  </div>
);

SubPolicyWithList.propTypes = {
  uniqueKey: PropTypes.string.isRequired,
  policyID: PropTypes.number.isRequired,
  subPolicyID: PropTypes.number.isRequired,
  subPolicyText: PropTypes.string.isRequired,
  subPolicyList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SubPolicyWithList;
