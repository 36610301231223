import React from 'react';
import statusHistoryField from './StatusHistoryField';
const changeStatus = ({statusHistory}) => {
  const history = statusHistory.length !== 0 ? statusHistory.map((field) => {
    let contactinfo = field.NewStatus === "IN TRANSIT/CARE"? 
      (
        <div key={field.DocumentID}>
          <p>
            Contact Name: {field.NewContactName}
          </p>
          <p>
            Location: {field.NewLocation}
          </p>
          <p>
            Contact Phone Number:
            {field.NewContactPhoneNumber}
          </p>
          <p>
            Contact Email:
            {field.NewContactEmail}
          </p>
        </div>
): null;

    return (
    <div key={field.DocumentID} className="StatusHistoryField">
      <p>
        Date/Time:
        <b>
          {field.DateUpdated}
        </b>
      </p>
      <p>
        Status:
        <b>
          {field.NewStatus}
        </b>
      </p>
      {contactinfo}
    </div>
    );
  })
    : (
      <p id="no_history">
        <br />
        There is no history
      </p>
    );
  return (
    <div id="history_div" className=" margin-top">
      <br />
      <div>
        { history }
      </div>
      <br />
    </div>
  );
};

export default changeStatus;
