import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import pdf from '../../data/how_to_get_video_url.pdf';

const PROMOTION_VIDEO_COLLECTION = 'promotionVideo';
const TERMS_AND_CONDITIONS_COLLECTION = 'termsAndConditions';

const AdminPage = ({ firestore }) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [newVideoUrl, setNewVideoUrl] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState('');

  const updatePromotionVideoUrl = () => {
    const url = newVideoUrl.trim();

    // Validate user input
    if (url.length === 0) {
      alert('Please enter the new URL');
      return;
    }

    // Save new url to firebase
    firestore.collection(PROMOTION_VIDEO_COLLECTION).doc('video').set({ url })
      .then(() => {
        setVideoUrl(url);
        setNewVideoUrl('');
        alert('Video was successfully updated!');
      })
      .catch((error) => {
        console.error('Error saving new video url: ', error);
        alert('Something went wrong!');
      });
  };

  const updateTermsAndConditions = () => {
    const body = termsAndConditions.trim();

    // Validate user input
    if (body.length === 0) {
      alert('Terms and conditions cannot be empty.');
      return;
    }

    // Save terms and conditions to firebase
    firestore.collection(TERMS_AND_CONDITIONS_COLLECTION).doc('page').set({ body })
      .then(() => {
        setTermsAndConditions(body);
        alert('Terms and Conditions have been successfully updated!');
      })
      .catch((error) => {
        console.error('Error saving terms and conditions: ', error);
        alert('Something went wrong!');
      });
  }

  useEffect(() => {
    // Retrieve promotion video url
    firestore.collection(PROMOTION_VIDEO_COLLECTION).doc('video').get()
      .then((doc) => {
        if (doc.exists) {
          setVideoUrl(doc.data().url);
          console.log(doc.data().url);
        }
      })
      .catch((error) => {
        console.log('Error getting current video url: ', error);
      });

    // Retrieve terms and conditions
    firestore.collection(TERMS_AND_CONDITIONS_COLLECTION).doc('page').get()
      .then((doc) => {
        if (doc.exists) {
          setTermsAndConditions(doc.data().body);
        }
      })
      .catch((error) => {
        console.log('Error getting terms and conditions: ', error);
      }); 

  }, []);

  return (
    <div className="text-align-center">
      <div className="text-align-center margin-bottom-20">
        <h2 className="margin-top-20 margin-bottom-10">Admin Page</h2>
      </div>
      <div className="wrapper flex-row justify-content-center">
        <div className="card" style={{ minWidth: '80%' }}>
          <div className="card-header">
            Update Promotion Video
          </div>
          <div className="card-body">
            <h6 className="card-title">Current Video URL</h6>
            <div className="form-group margin-bottom">
              <input type="text" className="form-control" disabled value={videoUrl} id="currVideoUrl" />
            </div>
            <h6 className="card-title">New Video URL</h6>
            <div className="form-group">
              <input type="text" className="form-control" onChange={(e) => setNewVideoUrl(e.target.value)} />
            </div>
            <p><a href={pdf} target="blank">How to get a video url?</a></p>
            <button type="submit" className="btn btn-primary" onClick={updatePromotionVideoUrl}>Update</button>
          </div>

          <div className="card-header" style={{ borderTop: '1px solid rgba(0,0,0,.125)'}}>
            Update Terms and Conditions
          </div>
          <div className="card-body">
            <div className="form-group">
              <textarea className="form-control" rows="10" onChange={(e) => setTermsAndConditions(e.target.value)} value={termsAndConditions}>
              </textarea>
            </div>
            <button type="button" className="btn btn-primary" onClick={updateTermsAndConditions}>Update</button>
          </div>
        </div>
      </div>
    </div>
  );
};

AdminPage.propTypes = {
  firestore: PropTypes.shape().isRequired,
};

export default AdminPage;
