export default function getDateTimeString() {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  month = (month < 10) ? `0${month}` : month;
  let day = today.getDate();
  day = (day < 10) ? `0${day}` : day;
  const dateStr = [year, month, day].join('-');

  let hours = today.getHours();
  let minutes = today.getMinutes();
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes}`;
  return `${dateStr} ${strTime}`;
}
