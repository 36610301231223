import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';

const RescueTimelineModal = ({userID, petDbId, submit, close}) => {
  const [organizationName, setOrganizationName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [rescueComment, setRescueComment] = useState('');
  const organizationNameChange = (event) => {
    setOrganizationName(event.target.value);
  };
  const contactNameChange = (event) => {
    setContactName(event.target.value);
  };
  const contactPhoneChange = (event) => {
    setContactPhone(event.target.value);
  };
  const contactEmailChange = (event) => {
    setContactEmail(event.target.value);
  };
  const rescueCommentChange = (event) => {
    setRescueComment(event.target.value);
  };
  const onSubmit = () => {
    submit(userID, petDbId, organizationName, contactName, contactPhone, contactEmail, rescueComment);
    close();
  };

  return (
    <div className="ChangeStatus">
      <form>
        <h5>Add a comment:</h5>
        <label htmlFor="orgName" className="ChangeStatusFormLabel">
          <b>Organization Name:</b>
          <input
            type="text"
            name="orgName"
            id="orgName"
            required
            onChange={(event) => {
              organizationNameChange(event);
            }}
          />
        </label>
        <label htmlFor="new_contact_name" className="ChangeStatusFormLabel">
          <b>Contact Name:</b>
          <input
            type="text"
            name="new_contact_name"
            id="new_contact_name"
            required
            onChange={(event) => {
              contactNameChange(event);
            }}
          />
        </label>
        <label htmlFor="new_contact_phone" className="ChangeStatusFormLabel">
          <b>Phone Number:</b>
          <input
            type="tel"
            name="new_contact_phone_number"
            id="new_contact_phone_number"
            required
            onChange={(event) => {
              contactPhoneChange(event);
            }}
          />
        </label>

        <label htmlFor="new_contact_email" className="ChangeStatusFormLabel">
          <b>Email:</b>
          <input
            type="email"
            name="new_contact_email"
            id="new_contact_email"
            required
            onChange={(event) => {
              contactEmailChange(event);
            }}
          />
        </label>

        <label htmlFor="new_comment" className="ChangeStatusFormLabel">
          <b>Comment:</b>
          <br />
          <textarea
            style={{padding: '1.2rem', width: '100%'}}
            name="new_comment"
            id="new_comment"
            rows="3"

            required
            onChange={(event) => {
              rescueCommentChange(event);
            }}
          />
        </label>

        <Button type="button" value="submit" variant="primary" onClick={onSubmit}>Submit</Button>
        <Button type="button" variant="secondary" onClick={close}>Close</Button>
      </form>
    </div>
  );
};

RescueTimelineModal.propTypes = {
  userID: PropTypes.string.isRequired,
  petDbId: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default RescueTimelineModal;
