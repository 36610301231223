import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContactOwnerForm from './ContactOwnerForm';

const UNKNOWN_ATTRIBUTE_VALUE = 'Unknown';
const NO_ADDITIONAL_INFO_VALUE = 'None';

const LostPetsCards = ({ lostPets, fbFunctions, user }) => {
  const [showForm, setShowForm] = useState(false);
  const [popup, setPopup] = useState(<ContactOwnerForm petName="Pet Name" DPID="DPID" setShowForm={setShowForm} />);

    // Sort the DPIDs in ascending order
    lostPets = lostPets.sort((a, b) => {
      let regex = /[a-z]+|[^a-z]+/gi;
      let numA = a['DPID'].match(regex)[1];
      let numB = b['DPID'].match(regex)[1];
  
      return numA - numB;
    });

  /**
   * Returns the ConactOwnerForm component.
   * @param {string} petName The name of the pet.
   * @param {string} DPID The Disaster Pets ID of the pet.
   */
  const contactForm = (petName, DPID, ownerEmail) => (
    <ContactOwnerForm
      petName={petName}
      DPID={DPID}
      ownerEmail={ownerEmail}
      setShowForm={setShowForm}
      fbFunctions={fbFunctions}
      user={user}
    />
  );

  /**
   * Displays a popup form when clicking the contact owner button.
   * @param {string} petName The name of the pet.
   * @param {string} DPID The Disaster Pets ID of the pet.
   */
  const onClickContactOwner = (petName, DPID, ownerEmail) => {
    setShowForm(true);
    setPopup(contactForm(petName, DPID, ownerEmail, setShowForm));
  };


  return (
    <div>
      <div className="contact-owner-popup">
        {showForm ? popup : null }
      </div>

      {/* Create cards for pets that are within the bounds of the map */}
      <div className="card-container">
        {lostPets.map((pet) => {
          const {
            DPID, PetName, PetSpecies, PetBreed, URL,
            PetChip, PetTattooNumber, PetAdditionalDetails,
            OwnerEmail,
          } = pet;

          let petChipValue = PetChip;
          let petTattoValue = PetTattooNumber;
          let petAdditionalDetails = PetAdditionalDetails;

          if (petChipValue === undefined || petChipValue === '') {
            petChipValue = UNKNOWN_ATTRIBUTE_VALUE;
          }

          if (petTattoValue === undefined || petTattoValue === '') {
            petTattoValue = UNKNOWN_ATTRIBUTE_VALUE;
          }

          if (petAdditionalDetails === undefined || petAdditionalDetails === '') {
            petAdditionalDetails = NO_ADDITIONAL_INFO_VALUE;
          }

          return (
            <div key={DPID} id={DPID} className="card">
              <img src={URL} alt="animal" className="margin-bottom-10 card-img-top" />
              <h2 className="card-pet-header">
                {`Disaster Pets ID #: ${DPID}`}
                <br />
                {`Name: ${PetName}`}
              </h2>
              <div className="card-body">
                <p>
                  {`Species: ${PetSpecies}`}
                  <br />
                  {`Breed: ${PetBreed}`}
                  <br />
                  {`Microchip: ${petChipValue}`}
                  <br />
                  {`Tattoo: ${petTattoValue}`}
                  <br />
                </p>
                <p className="card-additional-details">{`Additional Details: ${petAdditionalDetails}`}</p>
              </div>
              <div className="contact-owner-button-container">
                <button
                  type="submit"
                  className="card-contact-owner-button"
                  onClick={() => onClickContactOwner(PetName, DPID, OwnerEmail)}
                >
                  Contact Owner
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

LostPetsCards.propTypes = {
  lostPets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fbFunctions: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
};

export default LostPetsCards;
