import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';

/**
 * Header Component for all pages.
 */
const Header = ({ user }) => {
  const dropDownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    const currentState = dropDownRef.current.classList;
    currentState.toggle('display-block');
  };

  // Adds Event Listener to see if user clicks outside (to close dropdown)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current
        && !buttonRef.current.contains(event.target)
        && dropDownRef.current
        && !dropDownRef.current.contains(event.target)
      ) {
        if (dropDownRef.current && dropDownRef.current.classList.contains('display-block')) {
          toggleDropdown();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [dropDownRef]);

  return (
    <header className="background-one dropdown max-width background-color-two-hover" style={{ position: 'fixed', top: 0, zIndex: 100 }}>
      <div className="color-white userTitle" style={{ position: 'absolute', padding: 18 }}>
        <b>{user.getPermissionName()}</b>
      </div>
      <button ref={buttonRef} type="button" className="h2 background-none max-height color-white cursor-pointer" style={{ width: '100vw' }} onClick={toggleDropdown}>
        <strong className="flex-row align-items-center justify-content-center">
          Disaster&nbsp;
          <span style={{ color: 'rgb(65,65,65)' }}>Pets&nbsp;</span>
          Menu
          <MenuIcon className="padding-left-10" fontSize="large" />
        </strong>
      </button>
      {/* Dropdown Content */}
      <div ref={dropDownRef} className="dropdown-content" style={{ zIndex: 1000 }}>
        <div className="flex-column background-grey text-align-center">
          {/* Add Links here */}
          <Link to="/" className="padding-5" onClick={toggleDropdown}>Home</Link>
          <Link to="/RegisterLostPet/:id" className="padding-5" onClick={toggleDropdown}>Register Lost Pet</Link>
          <Link to="/RegisterFoundPet" className="padding-5" onClick={toggleDropdown}>Register Found Pet</Link>
          <Link to="/PreRegisterPet" className="padding-5" onClick={toggleDropdown}>Pre-register Pet (No Current Emergency)</Link>
          <Link to="/myRegisteredPets" className="padding-5" onClick={toggleDropdown}>My Registered Pets</Link>
          <Link to="/lostpets" className="padding-5" onClick={toggleDropdown}>See All Lost Pets</Link>
          <Link to="/foundpets" className="padding-5" onClick={toggleDropdown}>See All Found Pets</Link>
          <Link to="/responseMap" className="padding-5" onClick={toggleDropdown}>Evacuation Response Map</Link>
          <Link to="/petDatabase" className="padding-5" onClick={toggleDropdown}>Search Pet Database</Link>
          <Link to="/AuthenticateUser" className="padding-5" onClick={toggleDropdown}>Authentication Manager</Link>
          {user.getAdminPermissions() && (
            <Link to="/admin" className="padding-5" onClick={toggleDropdown}>Admin Page</Link>
          )}
          <Link to="/agencies" className="padding-5" onClick={toggleDropdown}>Authorized Response Agencies</Link>
          <Link to="/donate" className="padding-5" onClick={toggleDropdown}>Donate</Link>
          <Link to="/privacy_policy" className="padding-5" onClick={toggleDropdown}>Privacy Policy</Link>
          <Link to="/about" className="padding-5" onClick={toggleDropdown}>About</Link>
          <Link to="/contact" className="padding-5" onClick={toggleDropdown}>Contact</Link>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  user: PropTypes.shape().isRequired,
};

export default Header;
