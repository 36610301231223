import React from 'react';

const Privacy = ({ list }) => list.map((paragraph) => {
  switch (paragraph.paragraphID) {
    case 1:
      return (
        <div key={paragraph.paragraphID}>
          <p>
            {paragraph.text}
            <a style={{ color: 'blue', textDecoration: 'underline' }} href={paragraph.anchorLink}>{paragraph.anchorText}</a>
          </p>
        </div>
      );
    case 2:
      return (
        <div key={paragraph.paragraphID}>
          <h2 className="padding-top-20 padding-bottom-20">{paragraph.subTitle}</h2>
          <p>{paragraph.text}</p>
          <p>
            <u>{paragraph.underlinedText}</u>
            {paragraph.textContinued}
          </p>
        </div>
      );
    default:
      return (
        <div key={paragraph.paragraphID}>
          <p>{paragraph.text}</p>
        </div>
      );
  }
});

export default Privacy;
