import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ProfileField from './ProfileField';
import ChangeStatus from './ChangeStatus';
import StatusHistory from './StatusHistory';
import RescueTimeline from './RescueTimeline';
import CareInfo from './CareInfo';

// get from firebase
const profileFields = [
  { Label: 'Disaster Pets ID #', id: 'DPID', value: 'Stan' },
  { Label: 'Name', id: 'PetName', value: 'Stan' },
  { Label: 'Species', id: 'PetSpecies', value: 'Lion' },
  { Label: 'Breed', id: 'PetBreed', value: 'African' },
  { Label: 'Additional Pet Details', id: 'PetAdditionalDetails', value: ['tagged', 'skiddish'] },
  { Label: 'Microchip #', id: 'PetChipNumber', value: '1234' },
  { Label: 'Tattoo #', id: 'PetTattooNumber', value: '1234' },
  { Label: 'Owner FirstName', id: 'OwnerFirstName', value: 'Steve' },
  { Label: 'Owner LastName', id: 'OwnerLastName', value: 'Smith' },
  { Label: 'Owner Phone', id: 'OwnerPhoneNumber', value: '124-444-2213' },
  { Label: 'Owner Email', id: 'OwnerEmail', value: '1234' },
  { Label: 'Date Added', id: 'DateInitialEntry', value: '01/01/2019' },
  { Label: 'Rescue Location', id: 'RescueLocation', value: 'Vancouver, BC' },
  { Label: 'Permission to Use Forcible Entry', id: 'PermissionToEnter', value: 'true' },
];
let docId = '';
const Petprofile = ({ firestore, user }) => {
  const userID = user.getUID();
  const [showUpdatePetState, setShowUpdatePetState] = useState(false);
  const [showPetHistoryState, setShowPetHistoryState] = useState(false);
  const [showRescueTimelineState, setShowRescueTimelineState] = useState(false);
  const [statusUpdates, setStatusUpdates] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [imgURL, setImgURL] = useState('');
  const [bgStatusColor, setBgStatusColor] = useState('');
  const [txtStatusColor, setTxtStatusColor] = useState('black');
  const [petRescueTimeline, setPetRescueTimeline] = useState([]);
  // get from props
  const params = useParams();
  const petDbId = params.id;
  let collectionName;
  let idType;
  if (petDbId.startsWith('FP')) {
    collectionName = 'foundPets';
    idType = 'FPID';
  }
  else if (petDbId.startsWith('PP')) {
    collectionName = 'preregisteredPet';
    idType = 'PPID';
  }
  else {
    collectionName = 'registeredPets';
    idType = 'DPID';
  }
  profileFields[0].id = idType;

  const PetStatusColor = (status) => {
    switch (status) {
      case 'REQUIRES EVACUATION':
        setBgStatusColor('red');
        setTxtStatusColor('white');
        break;
      case 'IN TRANSIT/CARE':
        setBgStatusColor('yellow');
        break;
      case 'REUNITED WITH OWNER':
        setBgStatusColor('chartreuse');
        break;
      case 'LOST PET':
        setBgStatusColor('#bf80ff');
        break;
      case 'FOUND PET':
        setBgStatusColor('orange');
        break;
      case 'PRE-REGISTERED':
        setBgStatusColor('#00FFFF');
        break;
      default:
        setBgStatusColor('#00FFFF');
        setTxtStatusColor('black');
        break;
    }
  };

  const getStatusHistory = async (petDbId) => {
    try {
      const snapshot = await firestore.collection('petStatusUpdates').where('PetDbId', '==', petDbId).orderBy("DateUpdated", "desc").get();
      return snapshot.docs.map((doc) => {
        var data = doc.data();
        data['DocumentID'] = doc.id;
        return data;
      });
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getRescueTimeline = async (petDbId) => {
    try {
      const snapshot = await firestore.collection('petRescueTimeline').where('PetDbId', '==', petDbId).get();
      return snapshot.docs.map((doc) => {
        var data = doc.data();
        data['DocumentID'] = doc.id;
        return data;
      });
    } catch (err) {
      console.log(err);
      return [];
    }
  };


  const getPetProfile = async(petDbId) => {
    try {
      const snapshot = await firestore.collection(collectionName).where(idType, '==', petDbId).get();
      snapshot.docs.forEach((element) => { docId = element.id; });
      return snapshot.docs.map((element) => element.data());
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getPetData = async () => {
      const newPetProfile = await getPetProfile(petDbId);
      const newStatusHistory = await getStatusHistory(petDbId);

      // Only authorized users can see the rescue timeline.
      if (user.getPermissions()) {
        const newRescueTimeline = await getRescueTimeline(petDbId);
        setPetRescueTimeline(newRescueTimeline);
      }
      setImgURL(newPetProfile[0].URL);
      setProfileData(newPetProfile[0]);
      PetStatusColor(newPetProfile[0].PetStatus);
      setStatusUpdates(newStatusHistory);
    };

    if (user.isAuthenticated()) {
      getPetData();
    }
  }, [user]);

  const getDateTimeString = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    month = (month < 10) ? `0${month}` : month;
    let day = today.getDate();
    day = (day < 10) ? `0${day}` : day;
    const dateStr = [year, month, day].join('-');

    let hours = today.getHours();
    let minutes = today.getMinutes();
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes}`;
    return `${dateStr} ${strTime}`;
  };

  const updateProfileStatus = async (newPetStatus) => {
    await firestore.collection(collectionName).doc(docId).update({
      PetStatus: newPetStatus.PetStatus,
      CareProviderLocation: newPetStatus.CareProviderLocation || '',
      CurrentContactName: newPetStatus.CurrentContactName || '',
      CurrentContactPhoneNumber: newPetStatus.CurrentContactPhoneNumber || '',
      CurrentContactEmail: newPetStatus.CurrentContactEmail || '',
    });
  };
  
  const addCommentToTimeline = async (userID,
    petDbId, organizationName, contactName, contactPhone, contactEmail, rescueComment) => {
    const dt = getDateTimeString();
    firestore.collection('petRescueTimeline').add({
      UserID: userID,
      PetDbId: petDbId,
      DateUpdated: dt,
      Organization: organizationName,
      ContactName: contactName,
      ContactNumber: contactPhone,
      ContactEmail: contactEmail,
      RescueComment: rescueComment,
    });

    const newRescueTimeline = await getRescueTimeline(petDbId);
    setPetRescueTimeline(newRescueTimeline);
  };

  const updatePetStatusToCollection = async (event, newPetStatus) => {
    event.preventDefault();
    const newDate = getDateTimeString();
    await firestore.collection('petStatusUpdates').add({
      PetDbId: newPetStatus.DPID || newPetStatus.FPID,
      NewStatus: newPetStatus.PetStatus,
      UserID: newPetStatus.UserID,
      DateUpdated: newDate,
      NewLocation: newPetStatus.CareProviderLocation || '',
      NewContactName: newPetStatus.CurrentContactName || '',
      NewContactPhoneNumber: newPetStatus.CurrentContactPhoneNumber || '',
      NewContactEmail: newPetStatus.CurrentContactEmail || '',
    });
    await updateProfileStatus(newPetStatus);
    alert("You've successfully changed the status/location of this pet.");
    window.location.reload();
  };

  const showStatusHandlerFalse = () => {
    setShowUpdatePetState(false);
  };

  const showStatusHandlerTrue = () => {
    setShowUpdatePetState(true);
  };

  const toggleShowHistoryHandler = () => {
    const currentShowHistoryState = showPetHistoryState;
    setShowPetHistoryState(!currentShowHistoryState);
  };

  const toggleShowRescueTimelineHandler = () => {
    const currentshowRescueTimelineState = showRescueTimelineState;
    setShowRescueTimelineState(!currentshowRescueTimelineState);
  };

  const profile = profileFields.map((field) => {
    if (field.id === 'RescueLocation') {
      return (<ProfileField key={field.id} label={field.Label} value={profileData["PetLocationKnown"] == false ? 'Unknown' : `${profileData.PetSuiteNumber ? `${profileData.PetSuiteNumber}, ` : ''} ${profileData.PetAddress}, ${profileData.PetCity}, ${profileData.PetProvinceState}, ${profileData.PetCountry}${profileData.PetPostalCode ? `, ${profileData.PetPostalCode}` : ''}`} />);
    }
    else {
      return (<ProfileField key={field.id} label={field.Label} value={profileData[field.id] == null ? 'N/A' : profileData[field.id]} />);
    }
  });
  return (
    <div className="PetProfileContainer">
      <h4>Pet Profile</h4>
      <div className="pet_profile margin-top-30">
        {userID === profileData.UserID
          ? (<Link to={`/EditPetProfile/${petDbId}`}><button className="margin-top-30 margin-bottom-30 background-four color-white padding-5 cursor-pointer border-grey padding-left-10 padding-right-10 h4" id="editPetButton" type="button">Edit Your Pet's Information</button></Link>) : null}
        <br />
        <img src={imgURL} alt="pet" id="pet_photo" className="margin-bottom-30" />
        <br />
        <div className="margin-bottom-30">
          {profile}
        </div>
        <br />
      </div>
      <div id="status_div" style={{ backgroundColor: bgStatusColor, color: txtStatusColor }}>
        <h3 id="status_text" className="margin-bottom-30">
          Current Pet Status:
          {` ${profileData.PetStatus}`}
        </h3>
        {profileData.PetStatus === 'IN TRANSIT/CARE'
          ? (
            <CareInfo
              careLocation={profileData.CareProviderLocation}
              careName={profileData.CurrentContactName}
              carePhone={profileData.CurrentContactPhoneNumber}
              careEmail={profileData.CurrentContactEmail}
            />
          )
          : null}
        <Link to={`/RegisterLostPet/${petDbId}`}><button type="button" style={{ display: idType === 'PPID' ? 'show' : 'none' }} id="btn_register_pet" className="profile_buttons margin-top-10 margin-bottom-10 background-four color-white padding-5 padding-left-10 padding-right-10 h4">Activate Request for Assistance</button></Link>
        <button type="button" style={{ display: idType !== 'PPID' ? 'show' : 'none' }} onClick={showStatusHandlerTrue} className="profile_buttons profile_buttons margin-top-10 margin-bottom-10 background-four cursor-pointer border-grey color-white padding-5 padding-left-10 padding-right-10 h4">Change Evacuation Status</button>
      </div>
      <br />
      {
        showUpdatePetState
          ? (
            <ChangeStatus
              close={showStatusHandlerFalse}
              submit={updatePetStatusToCollection}
              profile={profileData}
            />
          )
          : null
      }
      <button type="button" style={{ display: idType !== 'PPID' ? 'show' : 'none' }} onClick={toggleShowHistoryHandler} className="profile_buttons margin-top-10 margin-bottom-10 background-four color-white padding-5 padding-left-10 padding-right-10 cursor-pointer border-grey h4">Show Status History</button>
      <br />
      {showPetHistoryState ? <StatusHistory statusHistory={statusUpdates} /> : null}
      {user.getPermissions() ? 
        <button 
          type="button" 
          style={{ display: idType !== 'PPID' ? 'show' : 'none' }} 
          onClick={toggleShowRescueTimelineHandler} 
          className="profile_buttons margin-top-10 margin-bottom-10 background-four color-white padding-5 padding-left-10 padding-right-10 h4 cursor-pointer border-grey">
          Show Rescue Timeline
        </button>
        : null
      }
        
      <br />
      {
        showRescueTimelineState ?
          (
            <RescueTimeline
              RescueTimeline={petRescueTimeline}
              petDbId={petDbId}
              userID={profileData.UserID}
              submit={addCommentToTimeline}
            />
          )
          : null
      }
    </div>
  );
};

Petprofile.propTypes = {
  firestore: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
};

export default Petprofile;
