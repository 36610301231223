import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PetRow = ({ pet, colour, selectPet }) => (
  <tr key={pet.FPID || pet.DPID}>
    <td className="border-black text-align-center" style={{ backgroundColor: colour }}>
      {pet.FPID || pet.DPID}
    </td>
    <td className="border-black text-align-center" style={{ backgroundColor: colour }}>
      {`${pet.PetSuiteNumber} ${pet.StreetAddress}`}
    </td>
    <td className="border-black text-align-center" style={{ backgroundColor: colour }}>
      <img src={pet.PetImageAddress} alt={pet.PetName} width="80%" />
    </td>
    <td className="border-black text-align-center" style={{ backgroundColor: colour }}>
      {pet.PetSpecies}
    </td>
    <td className="border-black text-align-center" style={{ backgroundColor: colour }}>
      {pet.PetBreed}
    </td>
    <td className="border-black text-align-center" style={{ backgroundColor: colour }}>
      <b>Tattoo #:</b>
      <br />
      {pet.PetTattooNumber}
      <br />
      <b>Microchip #:</b>
      <br />
      {pet.PetChipNumber}
      <br />
      <b>Additional Details:</b>
      <br />
      {pet.PetAdditionalDetails}
    </td>
    <td className="border-black text-align-center" style={{ backgroundColor: colour }}>
      {pet.PetName}
    </td>
    <td className="border-black text-align-center" style={{ backgroundColor: colour }}>
      <b>Owner Contact:</b>
      <br />
      {`${pet.OwnerFirstName} ${pet.OwnerLastName}`}
      <br />
      <b>P)</b>
      <br />
      {pet.OwnerPhoneNumber}
      <br />
      <b>E)</b>
      <br />
      {pet.OwnerEmail}
    </td>
    <td className="border-black text-align-center" style={{ backgroundColor: colour }}>
      <Link to={`/PetProfile/${pet.DPID || pet.FPID}`}>
        <button className="background-four color-white p padding-5 cursor-pointer margin-bottom-20 border-grey" type="button">Go To Pet Profile</button>
      </Link>
    </td>
    <td className="border-black text-align-center" style={{ backgroundColor: colour }}>
      <b>
        {pet.PetStatus}
        <br />
        <button
          className="background-four color-white p padding-5 cursor-pointer margin-10 border-grey"
          type="button"
          onClick={() => selectPet(pet)}
        >
          CHANGE EVACUATION STATUS
        </button>
      </b>
    </td>
  </tr>
);

PetRow.propTypes = {
  pet: PropTypes.shape().isRequired,
  colour: PropTypes.string.isRequired,
  selectPet: PropTypes.func.isRequired,
};

export default PetRow;
