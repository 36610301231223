import React from 'react';

const Definitions = ({ list }) => list.map((definition) => (
  <p key={definition.definitionID}>
    <u>
      {definition.underlinedText}
    </u>
    {definition.text}

  </p>
));

export default Definitions;
