import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ContactOwnerForm = ({
  petName, DPID, ownerEmail, setShowForm, fbFunctions, user,
}) => {
  const [contactInfo, setContactInfo] = useState('');
  const [contactSubject, setContactSubject] = useState('');
  const [contactMessage, setContactMessage] = useState('');

  /**
   * Closes the popup contact form.
   */
  const closePopup = () => {
    setShowForm(false);
  };

  /**
   * Sends email to the owner of the lost pet.
   */
  const sendEmailToOwner = () => {
    const NOT_FILLED = '';
    const SUBMIT_BUTTON = document.getElementById('submit-button');

    if (contactInfo === NOT_FILLED
      || contactSubject === NOT_FILLED
      || contactMessage === NOT_FILLED) {
      alert('Please fill in all information.');
      return;
    }
    const sendMail = fbFunctions.httpsCallable('sendMissingPetGmail');

    SUBMIT_BUTTON.disabled = true;

    sendMail({
      email: ownerEmail,
      uuid: user.uid,
      pet: `${DPID}`,
      contact: contactInfo,
      subject: contactSubject,
      message: contactMessage,
    }).then((result) => {
      if (result.data == "error") {
        alert('Email could not be sent. Please contact the admin to resolve the issue.');
      } else {
        alert('An email has been sent to the pet owner');
      }
      closePopup();
    }).catch(() => {
      alert('Email could not be sent. Please contact the admin to resolve the issue.');
      closePopup();
    });
  };

  /**
   * Handles the submit button when it is clicked.
   */
  const onClickSubmitButton = () => {
    setContactInfo(contactInfo);
    setContactSubject(contactSubject);
    setContactMessage(contactMessage);
    sendEmailToOwner();
  };

  return (
    <div className="contact-owner-modal">
      <form className="form-container">
        <h1 className="contact-owner-title">Contact Owner</h1>
        <h2>{`${petName}, ${DPID}`}</h2>
        <p>
          You must include your contact details in this message in order for the recipient to
          respond to you.
        </p>
        <label htmlFor="contact"><b>Your Email Address or Phone Number:</b></label>
        <br />
        <input type="text" name="contact" id="contact" onChange={(event) => setContactInfo(event.target.value)} required />

        <label htmlFor="subject"><b>Subject:</b></label>
        <br />
        <input type="text" name="subject" id="subject" onChange={(event) => setContactSubject(event.target.value)} required />

        <label htmlFor="message"><b>Message:</b></label>
        <br />
        <input type="text" name="message" id="message" onChange={(event) => setContactMessage(event.target.value)} required />

        <p>
          By clicking Submit, you agree to use this messaging system for the explicit purpose of
          reuniting a pet with its owner. Administrative action may be taken if there is
          misuse of this system.
        </p>

        <button type="button" id="submit-button" className="submit-contact-form-button" onClick={() => onClickSubmitButton()}>Submit</button>
        <button type="button" className="close-button" onClick={() => closePopup()}>Close</button>
      </form>
    </div>
  );
};

ContactOwnerForm.propTypes = {
  petName: PropTypes.string.isRequired,
  DPID: PropTypes.string.isRequired,
  setShowForm: PropTypes.func.isRequired,
  ownerEmail: PropTypes.string,
  fbFunctions: PropTypes.shape(),
  user: PropTypes.shape(),
};

ContactOwnerForm.defaultProps = {
  ownerEmail: 'email@domain.com',
  fbFunctions: null,
  user: {},
};

export default ContactOwnerForm;
