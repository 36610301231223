/**
 * Returns an array of lost pets that are in bound of the current map.
 */
const getPetsArrayInBound = (arrayOfPets, minLat, minLng, maxLat, maxLng) => {
  const PETS_IN_BOUND = [];
  for (let i = 0; i < arrayOfPets.length; i += 1) {
    if (arrayOfPets[i].PetLattitude >= minLat
      && arrayOfPets[i].PetLattitude <= maxLat
      && arrayOfPets[i].PetLongitude >= minLng
      && arrayOfPets[i].PetLongitude <= maxLng) {
      PETS_IN_BOUND.push(arrayOfPets[i]);
    }
  }
  return PETS_IN_BOUND;
};

export default getPetsArrayInBound;
