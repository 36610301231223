import React from 'react';
import PolicyText from './PolicyText';

const Policies = ({ list }) => list.map((policy) => (
  <div key={policy.policyID}>
    <h5 className="padding-top-10 padding-bottom-10">{policy.title}</h5>

    <PolicyText
      key={policy.policyID}
      policyID={policy.policyID}
      list={policy.subPolicies}
    />
  </div>
));

export default Policies;
