import React from 'react';

const profileField = (props) => (
  <div>
    <b>{props.label}</b>
    :
    <span>{` ${props.value}`}</span>
    <br />
  </div>
);

export default profileField;
