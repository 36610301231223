import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AuthAlert from '../AuthAlert';

const TransferModal = ({
  close,
  show,
  fromInfo,
  emailChange,
  submit,
  showAlert,
  variant,
  alertMessage,
  handleCloseAlert,
}) => (
  <>
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Transfer Access</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-warning">Transfer requires the recipient to be granted the same authentication level.</div>
      </Modal.Body>
      <Modal.Body>
        <form onSubmit={submit}>
          <div className="form-group">
            <label htmlFor="transfer-email">
              Transferring all users of {fromInfo.dataEmail} to:
              <input
                onChange={emailChange}
                type="email"
                className="form-control"
                placeholder="Enter Email"
                name="name"
                id="transfer-email"
                required
              />
            </label>
          </div>
          <AuthAlert
            show={showAlert}
            msg={alertMessage}
            close={handleCloseAlert}
            variant={variant}
          />
          <button type="button" className="btn btn-secondary margin-right-10" onClick={close}>close</button>
          <button type="submit" className="btn btn-primary">update</button>
        </form>
      </Modal.Body>
    </Modal>
  </>
);

TransferModal.propTypes = {
  close: PropTypes.func.isRequired,
  show: PropTypes.func.isRequired,
  fromInfo: PropTypes.shape().isRequired,
  emailChange: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  alertMessage: PropTypes.string.isRequired,
  handleCloseAlert: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
};

export default TransferModal;
