import React, { useEffect } from 'react';
import Privacy from './Privacy';
import Definitions from './Definitions';
import Policies from './Policies';
import Contact from './Contact';

/**
 * Contains text for the "Privacy Policy" section.
 */
const privacy = [
  {
    paragraphID: 1,
    text: 'Disaster Pets communications and mapping systems require the collection, use, and disclosure of the information that you provide for the rescue or reunification of pets during disasters. This site is in compliance with ',
    anchorText: 'British Columbia’s Personal Information Protection Act.',
    anchorLink: 'https://www.bclaws.ca/civix/document/id/complete/statreg/03063_01',
  },
  {
    paragraphID: 2,
    subTitle: 'Purpose For Collecting Information',
    text: 'The information that you provide includes contact information for yourself, identification details of either your animal or an animal in your possession, and the location of that animal.',
    underlinedText: 'This information is disclosed to animal organizations and government officials ',
    textContinued: 'so that they may effectively communicate and conduct organized disaster animal response.',
  },
  {
    paragraphID: 3,
    text: 'Information including the identification details of your animal or an animal in your possession as well as the general location of that animal is also disclosed to the public so that they may either contact you regarding the status of your pet or to reclaim the pet that you have in your possession. Contact is made through an anonymous messaging system and does not include your personal contact details.',
  },
  {
    paragraphID: 4,
    text: 'By choosing to use this site you give consent to the collection, use, or disclosure of the information which you provide.',
  },
];

/**
 * Contains text for the "Definitions" section.
 */
const definitions = [
  {
    definitionID: 1,
    underlinedText: 'Personal Information:',
    text: ' Includes information that is reasonably capable of identifying a particular individual either alone or when combined '
        + 'with information from other available sources. Some examples of personal information which are collected by this site are: Name and address',
  },
  {
    definitionID: 2,
    underlinedText: 'Contact Information:',
    text: ' Information that would enable an individual to be contacted at a place of business and includes name, position name or title, '
        + 'business telephone number, business address, business email or business fax number. Contact information is not covered by this policy or PIPA.',
  },
  {
    definitionID: 3,
    underlinedText: 'Privacy Officer: ',
    text: ' The individual designated responsibility for ensuring that Disaster Pets complies with this policy and PIPA.',
  },
];

/**
 * Contains the list of policies in the "Personal Information Protection Policy" section.
 */
const policies = [
  {
    policyID: 1,
    title: 'Policy 1 - Accountability',
    subPolicies: [
      {
        subPolicyID: 1,
        subPolicyText: 'British Columbia’s Personal Information Protection Act (PIPA) sets out rules for how organizations collect,'
                        + 'use and disclose personal information. As with all BC businesses and non-profits, Disaster Pets is accountable for'
                      + 'compliance with PIPA. We are committed to being accountable for how we handle personal information, as well as how we'
                      + 'follow the rules and procedures outlined in this policy.',
      },
    ],
  },

  {
    policyID: 2,
    title: 'Policy 2 - Identifying Purposes & Limiting Collection',
    subPolicies: [
      {
        subPolicyID: 1,
        subPolicyText: 'Disaster Pets will collect, use, and disclose personal information only for purposes that a reasonable person would '
        + 'consider appropriate in the circumstances and if we have received consent or are authorized to collect without consent.',
      },
      {
        subPolicyID: 2,
        subPolicyText: 'We will collect only the minimum amount of personal information required to achieve the purpose for the collection.',
      },
      {
        subPolicyID: 3,
        subPolicyText: 'The following are the types of personal information that Disaster Pets will collect.',
        list: ['Name (First and Last)', 'Phone Number', 'Email Address', 'Pet Rescue Location Address'],
      },
      {
        subPolicyID: 4,
        subPolicyText: 'The purposes for collecting personal information are as follows.',
        list: ['To verify identity, and', 'To provide requested services or products.'],
      },
    ],
  },

  {
    policyID: 3,
    title: 'Policy 3 - Consent',
    subPolicies: [
      {
        subPolicyID: 1,
        subPolicyText: 'We will obtain individuals’ consent to collect, use, or disclose their personal information'
                      + '(except where we are legally authorized or required by law to do so without consent as is outlined below)',
      },
      {
        subPolicyID: 2,
        subPolicyText: 'We will obtain in the following ways: ',
        list: [
          'In order to notify individuals of the purpose for collecting their personal information we have '
          + 'added alerts wherever personal information may be submitted;',

          'Individuals can consent electronically by indicating on the site that they consent to the collection, use, '
          + 'and distribution of that information prior to submitting their personal information;',

          'Consent may be implied where a client is given notice and a reasonable opportunity to opt-out of providing that personal information;',
          'Consent is also implied where the purpose for collecting, using, or disclosing the personal information would be considered '
          + 'obvious and the client voluntarily provides personal information for that purpose.',
        ],
      },
      {
        subPolicyID: 3,
        subPolicyText: 'Consent will not be required beyond what is necessary to provide a product or service.',
      },
      {
        subPolicyID: 4,
        subPolicyText: 'Subject to certain exceptions (e.g., the personal information is necessary to provide the service or product,'
          + 'or the withdrawal of consent would frustrate the performance of a legal obligation), clients can withhold or withdraw their '
          + 'consent for Disaster Pets to use their personal information in certain ways. A client’s decision to withhold or withdraw their consent'
          + 'to certain uses of personal information may restrict Disaster Pets\' ability to provide a particular service or product. '
          + 'If so, we will explain the situation to assist the client in making the decision.',
      },
      {
        subPolicyID: 5,
        subPolicyText: 'We may collect, use, or disclose personal information without the client’s knowledge or consent in the following limited circumstances:',
        list: [
          'When the collection, use, or disclosure of personal information is permitted or required by law;',
          'When the personal information is available from a public source (e.g., a telephone directory);',
          'When we require legal advice from a lawyer;',
          'To protect ourselves from fraud;',
          'To investigate an anticipated breach of an agreement or a contravention of law.'],
      },
    ],
  },
  {
    policyID: 4,
    title: 'Policy 4 - Limiting Use and Disclosure',
    subPolicies: [
      {
        subPolicyID: 1,
        subPolicyText: 'The use and disclosure of personal information is limited to purposes that a reasonable person would '
        + 'consider appropriate in the circumstances and where Disaster Pets has either received consent or is authorized to collect without consent.',
      },
      {
        subPolicyID: 2,
        subPolicyText: 'We will not collect, use, or disclose personal information except for the identified purposes for '
        + 'collection, unless we have received additional consent or the processing is authorized without consent.',
      },
      {
        subPolicyID: 3,
        subPolicyText: 'We will disclose personal information where authorized by PIPA or required by law '
        + '(for example, in the event of a court order, subpoena, or search warrant).',
      },
    ],
  },
  {
    policyID: 5,
    title: 'Policy 5 - Retention',
    subPolicies: [
      {
        subPolicyID: 1,
        subPolicyText: 'We will keep personal information used to make a decision that directly affects '
        + 'individuals for at least one year after we make that decision.',
      },
      {
        subPolicyID: 2,
        subPolicyText: 'We will retain client personal information only as long as necessary to fulfill the identified purposes or a legal or business purpose',
      },
    ],
  },
  {
    policyID: 6,
    title: 'Policy 6 - Accuracy',
    subPolicies: [
      {
        subPolicyID: 1,
        subPolicyText: 'We will make reasonable efforts to ensure that client personal information is accurate and complete where it may '
        + 'be used to make a decision about the client or disclosed to another organization.',
      },
      {
        subPolicyID: 2,
        subPolicyText: 'Clients may request correction to their personal information in order to ensure its accuracy and completeness. '
        + 'A request to correct personal information must be made in writing and provide sufficient detail to identify the personal information '
        + 'and the correction being sought and should be forwarded to the Privacy Officer.',
      },
    ],
  },
  {
    policyID: 7,
    title: 'Policy 7 - Safeguards',
    subPolicies: [
      {
        subPolicyID: 1,
        subPolicyText: 'The following security measures will be followed to ensure that client personal information is '
        + 'appropriately protected: the use of user IDs, passwords.',
      },
      {
        subPolicyID: 2,
        subPolicyText: 'We will use appropriate security measures when destroying client’s personal information such as '
        + 'shredding documents, and deleting electronically stored information.',
      },
      {
        subPolicyID: 3,
        subPolicyText: 'We will continually review and update our security policies and controls as technology changes to '
        + 'ensure ongoing personal information security.',
      },
    ],
  },
  {
    policyID: 8,
    title: 'Policy 8 - Individual Access',
    subPolicies: [
      {
        subPolicyID: 1,
        subPolicyText: 'Clients have a right to access their personal information, subject to limited exceptions.',
      },
      {
        subPolicyID: 2,
        subPolicyText: 'A request to access personal information must be made in writing and provide sufficient detail '
           + 'to identify the personal information being sought.',
      },
      {
        subPolicyID: 3,
        subPolicyText: 'Upon request, we will also tell clients how we use their personal information and to whom it has been disclosed if applicable.',
      },
      {
        subPolicyID: 4,
        subPolicyText: 'We will make the requested information available within 30 business days, or provide written notice of an extension '
        + 'where additional time is required to fulfill the request.',
      },
      {
        subPolicyID: 5,
        subPolicyText: 'If a request is refused in full or in part, we will notify the client in writing, providing the reasons for refusal '
        + 'and the recourse available to the client.',
      },
    ],
  },
  {
    policyID: 9,
    title: 'Policy 9 - Challenging Compliance',
    subPolicies: [
      {
        subPolicyID: 1,
        subPolicyText: 'The Privacy Officer is responsible for ensuring Disaster Pets\' compliance with this policy '
        + 'and the Personal Information Protection Act.',
      },
      {
        subPolicyID: 2,
        subPolicyText: 'Clients should direct any complaints, concerns or questions regarding Disaster Pets\' compliance in writing to the Privacy Officer. '
        + 'If the Privacy Officer is unable to resolve the concern, the client may also write to the Information and Privacy Commissioner of British Columbia.',
      },
    ],
  },
];

/**
 * Contains the name and email of the contactee.
 */
const contactInformation = [
  {
    title: 'Contact Information for Disaster Pets\' Privacy Officer:',
    names: [
      {
        name: 'Michelle Verville',
        email: 'disasterpetsca@gmail.com',
      },
    ],
  },
];

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="margin-left-40 margin-right-40">
      <h1 className="privacy-h1 padding-bottom-20"><b><u>Privacy Policy</u></b></h1>
      <Privacy list={privacy} />

      <h1 className="privacy-h1 padding-top-10 padding-bottom-10"><b><u>Personal Information Protection Policy</u></b></h1>
      <p className="padding-top-20 padding-bottom-20"><b>Definitions</b></p>
      <Definitions list={definitions} />

      <Policies list={policies} />
      <Contact list={contactInformation} />
    </div>
  );
};

export default PrivacyPolicy;
