import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import AgencyField from './AgencyField';

const AuthorizedResponseAgencies = ({ firestore, user }) => {
  console.log(user);
  const history = useHistory();
  const [agencies, setAgencies] = useState([]);
  const [newAgency, setNewAgency] = useState('');
  const getResponseAgenciesHandler = async() => {
    try {
      const snapshot = await firestore.collection('responseAgencies').orderBy('name').get();
      // return snapshot.docs.map((element) => element.data());
      return snapshot;
    }
    catch (err) {
      console.log(err);
      return [];
    }
  };

  useEffect(() => {
    if (!user) {
      alert('You must be anloggied in order to access this page. Please sign up with Disaster Pets for more information.');
      history.push('/');
    }
    const getData = async () => {
      const snapshot = await getResponseAgenciesHandler();
      const agencyData = snapshot.docs.map((element) => ({ id: element.id, data: element.data() }));
      setAgencies(agencyData);
    };
    getData();
  }, []);

  const agencyDeleteHandler = async (e, docId) => {
    e.preventDefault();
    console.log(docId);
    await firestore.collection('responseAgencies').doc(docId).delete();
    window.location.reload();
  };

  const submitNewAgencyHandler = async (e) => {
    e.preventDefault();
    console.log(newAgency);
    const check = await firestore.collection('responseAgencies').where('name', '==', newAgency).get();
    if (check.empty) {
      await firestore.collection('responseAgencies').add({ name: newAgency });
      window.location.reload();
    }
  };

  const newAgencyOnChangeHandler = (e) => {
    setNewAgency(e.target.value);
  };

  const agencyelements = agencies.map((field) => (
    <AgencyField field={field} deleteHandler={agencyDeleteHandler} admin={ user.isAdmin } />
  ));

  const addAgency = user.isAdmin
    ? (
      <div className="row justify-content-center">
        <div className="col-sm-4 admin">
          <form id="add-agency-form" className="admin" style={{ margin: '30px auto', maxWidth: '300px' }} onSubmit={submitNewAgencyHandler}>
            <div className="form-group">
              <label htmlFor="agency-email">
                Response Agency
                <input
                  onChange={newAgencyOnChangeHandler}
                  type="text"
                  className="form-control"
                  placeholder="Enter Agency Name"
                  name="name"
                  id="agency-email"
                  required
                />
              </label>
            </div>
            <button type="submit" className="btn btn-primary btnDisable">Add Response Agency</button>
          </form>
        </div>
      </div>
    ) : null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid margin-top-10" style={{ textAlign: 'center'}}>
      <div className="jumbotron jumbotron-fluid padding-40 margin-top-30">
        <div className="container">
          <h2 className="h2">Authorized Response Agencies</h2>
          <p style={{ fontSize: '1.3rem'}}>
            Authorized Response Agencies have been given access
            to the Evacuation Response Map, Search Pet Database,
            Authentication Manager, and Pet Profile pages. They
            have been granted access by Disaster Pets or an
            authorized Government Administrator. Contact Disaster
            Pets if your organization requires this access.
          </p>
        </div>
      </div>
      {addAgency}
      <br />
      <div className="container col-sm-6">
        <ul style={{paddingLeft: 0 }}>
          {agencyelements}
        </ul>
      </div>
    </div>
  );
};

AuthorizedResponseAgencies.propTypes = {
  firestore: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
};

export default AuthorizedResponseAgencies;
