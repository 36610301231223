import React from 'react';
import PropTypes from 'prop-types';

const AgencyField = ({ field, deleteHandler, admin }) => {
  const deleteButton = admin
    ? (
      <div className="col-sm-3">
        <button
          type="button"
          className="btn btn-danger"
          onClick={(event) => deleteHandler(event, field.id)}
        >
          Remove Agency
        </button>
      </div>
    ) : null;
  const nameAlign = admin ? 'col-sm-9' : 'col-sm-12';
  return (
    <li className="padding-10 margin-0 list-group-item" style={{ listStyleType: 'none' }}>
      <div className="row">
        <h4 className={nameAlign} style={{ fontSize: '2rem' }}>{field.data.name}</h4>
        {deleteButton}
      </div>
    </li>
  );
};

AgencyField.propTypes = {
  admin: PropTypes.bool.isRequired,
  field: PropTypes.shape().isRequired,
  deleteHandler: PropTypes.func.isRequired,
};

export default AgencyField;
