/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
const windowWidth = window.innerWidth;
let googleMapObject;
let googleMapsObject;
let autocomplete;
let geocoder;
let marker;
let userID;

let mapOpened = false;
/**
 * Pre-Register Pet Page
 */
const PreRegisterPet = ({ firebase, firestore, user }) => {
  const emailRegex = '^[\\w!#$%&\'*+-/=?^_`{|}~]+[\\w.!#$%&\'*+-/=?^_`{|}~]+[\\w!#$%&\'*+-/=?^_`{|}~]+@[a-zA-Z_.]+?\\.[a-zA-Z]{2,3}$';
  const numberOnlyRegex = '^[0-9]+$';
  const addressRegex = '^[A-Za-z0-9#]+[A-Za-z0-9\\s.]+[A-Za-z.]+$';
  const cityRegex = '^[A-Za-z-\\s]+$';
  const countryRegex = '^[A-Za-z\\s.]+$';
  const postalRegex = '^[A-Za-z0-9\\s]+$';

  const inputRef = useRef(null);
  const searchBarRef = useRef(null);
  const history = useHistory();

  // Creates pet location.
  const mapZoomInValue = 11;
  const initialLocation = {
    lat: 49.251,
    lng: -122.999,
  };
  const [ownerFirstName, setOwnerFirstName] = useState('');
  const [ownerLastName, setOwnerLastName] = useState('');
  const [ownerPhoneNumber, setOwnerPhoneNumber] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [petName, setPetName] = useState('');
  const [petSpecies, setPetSpecies] = useState('');
  const [petBreed, setPetBreed] = useState('');
  const [petPhotoUploadPath, setPetPhotoUploadPath] = useState('upload');
  const [petDetail, setPetDetail] = useState('');
  const [petChip, setPetChip] = useState('');
  const [petTattoo, setPetTattoo] = useState('');
  const [petLocationSuite, setPetLocationSuite] = useState('');
  const [petAddress, setPetAddress] = useState('');
  const [petCity, setPetCity] = useState('');
  const [petProvinceState, setPetProvinceState] = useState('');
  const [petCountry, setPetCountry] = useState('');
  const [petPostalCode, setPetPostalCode] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  let petLocationKnown = false;
  let petLocationSet = false;
  let petLatitude = 0;
  let petLongitude = 0;

  /**
   * Generates UUID for user randomly.
   * @returns randomly generated UUID
   */
  const createUUID = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });

  /**
   * Set up the map objects and interfaces.
   * @param {*} map google map Object
   * @param {*} maps google maps Object
   */
  const handleApiLoaded = (map, maps) => {
    googleMapObject = map;
    googleMapsObject = maps;
    geocoder = new maps.Geocoder();
    autocomplete = new maps.places.Autocomplete(inputRef.current, { types: ['geocode'] });
    googleMapObject.controls[
      googleMapsObject.ControlPosition.TOP_LEFT].push(searchBarRef.current);
  };

  /**
   * Returns MapOptions object for Google Map.
   * @param {} maps Google Maps Object
   */
  const createMapOptions = (maps) => {
    if (windowWidth < 500) {
      return {
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: maps.ControlPosition.LEFT_TOP,
        },
        streetViewControl: true,
        streetViewControlOptions: {
          position: maps.ControlPosition.RIGHT_BOTTOM,
        },
        zoomControlOptions: {
          position: maps.ControlPosition.RIGHT_BOTTOM,
        },
        fullscreenControlOptions: {
          position: maps.ControlPosition.TOP_RIGHT,
        },
      };
    }
    return {
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      streetViewControl: true,
      streetViewControlOptions: {
        position: maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_BOTTOM,
      },
      fullscreenControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
    };
  };

  /**
   * Display search result on the map.
   */
  const searchMap = async () => {
    const place = autocomplete.getPlace();

    try {
      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        googleMapObject.fitBounds(place.geometry.viewport);
      } else {
        googleMapObject.setCenter(place.geometry.location);
        googleMapObject.setZoom(mapZoomInValue);
      }

      marker.setPosition(place.geometry.location);
    } catch (err) {
      alert('Please use the mouse or arrows to select a location from the dropdown list.');
    }
  };

  /**
   * Display the thumbnail of the image file.
   */
  const showPetImage = (ev) => {
    var file = ev.target.files[0];
    if (file != null) {
      setPetPhotoUploadPath(ev.target.value);
      setSelectedFile(file);

      const preview = document.getElementById('petImage');
      const fileName = document.getElementById("imageFileName");
      const removePhoto = document.getElementById("removePhotoButton");

      preview.style.display = 'block';
      preview.src = URL.createObjectURL(file);
      fileName.textContent = file.name;
      removePhoto.style.display = 'block';
      console.log(file.name);
    }
  };

  const removePhoto = () => {
    const preview = document.getElementById('petImage');
    const fileName = document.getElementById("imageFileName");
    const removePhoto = document.getElementById("removePhotoButton");

    preview.src = '';
    preview.style.display = 'none';
    fileName.textContent = '';
    removePhoto.style.display = 'none';
    setSelectedFile(null);
    document.getElementById("image_button").value = "";
  }

  /**
   * Set marker position if it exists; otherwise create a new one.
   * @param locationMarker Marker object
   */
  const placeMarker = (locationMarker) => {
    if (marker === null || marker === undefined) {
      marker = new googleMapsObject.Marker({
        position: locationMarker,
        map: googleMapObject,
        icon: 'https://maps.google.com/mapfiles/ms/icons/red.png',
      });
    } else {
      marker.setPosition(locationMarker);
    }
  };

  /**
   * Removes require and value tag, and its value for the fields related to address.
   */
  const resetAddressInput = () => {
    document.getElementById('pet_location_address').removeAttribute('required');
    document.getElementById('pet_location_city').removeAttribute('required');
    document.getElementById('pet_location_province_state').removeAttribute('required');
    document.getElementById('pet_location_country').removeAttribute('required');
    document.getElementById('pet_location_postal_code').removeAttribute('required');
    document.getElementById('pet_location_address').removeAttribute('value');
    document.getElementById('pet_location_city').removeAttribute('value');
    document.getElementById('pet_location_province_state').removeAttribute('value');
    document.getElementById('pet_location_country').removeAttribute('value');
    document.getElementById('pet_location_postal_code').removeAttribute('value');
    document.getElementById('pet_location_address').value = '';
    document.getElementById('pet_location_city').value = '';
    document.getElementById('pet_location_province_state').value = '';
    document.getElementById('pet_location_country').value = '';
    document.getElementById('pet_location_postal_code').value = '';
  };

  /**
   * Get address from google map plugin
   */
  const getAddressFromMap = () => {
    mapOpened = true;
    document.getElementById('submitButton').disabled = false;
    if (document.getElementById('locViaMapRadio').checked) {
      // Set address fields to not required
      resetAddressInput();
      document.getElementById('outerMapDiv').style.display = 'inline';
      document.getElementById('outerAddressDiv').style.display = 'none';

      let latlng;
      // google map init function - tracks the location of device and display screen on the location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          if (petLongitude !== 0 && petLatitude !== 0) {
            latlng = new googleMapsObject.LatLng(petLatitude, petLongitude);
          } else {
            latlng = new googleMapsObject.LatLng(
              position.coords.latitude,
              position.coords.longitude,
            );
          }
          googleMapObject.setCenter(latlng);
          marker.setPosition(latlng);
        }, () => {
          googleMapObject.setCenter(initialLocation);
          marker.setPosition(initialLocation);
        });
      } else {
        googleMapObject.setCenter(initialLocation);
        marker.setPosition(initialLocation);
      }

      marker = new googleMapsObject.Marker({
        icon: 'https://maps.google.com/mapfiles/ms/icons/red.png',
        draggable: true,
      });
      marker.setMap(googleMapObject);

      marker.setPosition(latlng);
      marker.setVisible(true);
    }

    autocomplete.addListener('place_changed', () => { searchMap(); });
  };

  /**
   * Display all the text input
   */
  const getAddressTextInput = () => {
    mapOpened = false;
    if (marker !== undefined) {
      marker.setMap(null);
    }
    document.getElementById('submitButton').disabled = false;
    document.getElementById('outerMapDiv').style.display = 'none';
    document.getElementById('outerAddressDiv').style.display = 'inline';
    document.getElementById('pet_location_address').required = true;
    document.getElementById('pet_location_city').required = true;
    document.getElementById('pet_location_province_state').required = true;
    document.getElementById('pet_location_country').required = true;
    document.getElementById('pet_location_postal_code').required = true;
  };

  /**
   * Return today datetime string in 'YYYY-MM-DD hh:mm' format
   */
  const getDateTimeString = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    month = (month < 10) ? `0${month}` : month;
    let day = today.getDate();
    day = (day < 10) ? `0${day}` : day;
    const dateStr = [year, month, day].join('-');

    let hours = today.getHours();
    let minutes = today.getMinutes();
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes}`;
    return `${dateStr} ${strTime}`;
  };

  /**
   * Inserts data to database after geocoding address to coordinates.
   * @param {*} newUserIndex string
   * @param {*} downloadURL api url returned from firestore or non
   */
  const savePetInfo = async (newUserIndex, downloadURL) => {
    console.log('Checking conditions');
    // Check if chip or tattoo exist in lost or found databases:
    if (petChip) {
      await firestore.collection('registeredPets').get().then((snapshot) => {
        snapshot.docs.forEach((element) => {
          if (petChip === element.data().PetChipNumber) {
            alert(`Pet Chip Number Match! Registered Pet ${element.data().DPID} has the same Chip Number! Please record this information. The other individual that registered this Chip Number can be contacted through the 'See All Lost Pets' page.`);
          }
        });
      });
      await firestore.collection('preregisteredPet').get().then((snapshot) => {
        snapshot.docs.forEach((element) => {
          if (petChip === element.data().PetChipNumber) {
            alert(`Pet Chip Number Match! Registered Pet ${element.data().PPID} has the same Chip Number! Please record this information. The other individual that registered this Chip Number can be contacted through the 'See All Lost Pets' page.`);
          }
        });
      });
      await firestore.collection('foundPets').get().then((snapshot) => {
        snapshot.docs.forEach((element) => {
          if (petChip === element.data().PetChipNumber) {
            alert(`Pet Chip Number Match! Registered Pet ${element.data().FPID} has the same Chip Number! Please record this information. The other individual that registered this Chip Number can be contacted through the 'See All Found Pets' page.`);
          }
        });
      });
    }
    if (petTattoo) {
      await firestore.collection('registeredPets').get().then((snapshot) => {
        snapshot.docs.forEach((element) => {
          if (petTattoo === element.data().PetTattooNumber) {
            alert(`Pet Tattoo Match! Registered Pet ${element.data().DPID} has the same Tattoo Number! Please record this information. The other individual that registered this Tattoo Number can be contacted through the 'See All Lost Pets' page.`);
          }
        });
      });
      await firestore.collection('preregisteredPet').get().then((snapshot) => {
        snapshot.docs.forEach((element) => {
          if (petTattoo === element.data().PetTattooNumber) {
            alert(`Pet Tattoo Match! Registered Pet ${element.data().PPID} has the same Tattoo Number! Please record this information. The other individual that registered this Tattoo Number can be contacted through the 'See All Lost Pets' page.`);
          }
        });
      });
      await firestore.collection('foundPets').get().then((snapshot) => {
        snapshot.docs.forEach((element) => {
          if (petTattoo === element.data().PetTattooNumber) {
            alert(`Pet Tattoo Match! Registered Pet ${element.data().FPID} has the same Tattoo Number! Please record this information. The other individual that registered this Tattoo Number can be contacted through the 'See All Found Pets' page.`);
          }
        });
      });
    }
    console.log('conditions passed');

    let closestCity;
    let country;
    let administrativeArea;
    let petNearestCityLat;
    let petNearestCityLong;

    // getting the above variables from geocoding
    const GeoCode = async () => {
      geocoder = new googleMapsObject.Geocoder();
      try {
        if (!mapOpened) {
          console.log('geocoding address from input');
          const geoAddress = `${petAddress} ${petCity} ${petProvinceState} ${petCountry}`;
          console.log(geoAddress);
          const result = await new Promise((resolve, reject) => {
            geocoder.geocode({ address: geoAddress }, (results, status) => {
              if (status === googleMapsObject.GeocoderStatus.OK) {
                resolve({
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                });
              } else {
                reject(new Error('Could not find the address'));
              }
            });
          });
          petLatitude = result.lat;
          petLongitude = result.lng;
        } else {
          const latLng = new googleMapsObject.LatLng(
            marker.getPosition().lat(), marker.getPosition().lng(),
          );
          console.log(latLng);
          const result = await new Promise((resolve, reject) => {
            geocoder.geocode({ latLng }, (results, status) => {
              if (status === googleMapsObject.GeocoderStatus.OK) {
                resolve(results[0].address_components);
              } else {
                reject(new Error('Could not find the latLng'));
              }
            });
          });
          for (let i = result.length - 1; i >= 0; i -= 1) {
            for (let j = 0; j < result[i].types.length; j += 1) {
              if (result[i].types[j] === 'country') {
                country = result[i].long_name;
              }
              if (result[i].types[j] === 'administrative_area_level_1') {
                administrativeArea = result[i].long_name;
              }
              if (result[i].types[j] === 'locality') {
                closestCity = result[i].long_name;
              } else if (result[i].types[j] === 'sublocality' && closestCity === null) {
                closestCity = result[i].long_name;
              } else if (result[i].types[j] === 'neighborhood' && closestCity === null) {
                closestCity = result[i].long_name;
              } else if (result[i].types[j] === 'postal_town' && closestCity === null) {
                closestCity = result[i].long_name;
              } else if (result[i].types[j] === 'administrative_area_level_2' && closestCity === null) {
                closestCity = result[i].long_name;
              }
            }
          }
          const address = `${closestCity}, ${administrativeArea}, ${country}`;
          const resultBounds = await new Promise((resolve, reject) => {
            geocoder.geocode({ address }, (results, status) => {
              if (status === googleMapsObject.GeocoderStatus.OK) {
                resolve(results[0].geometry.location);
              } else {
                reject(new Error('Could not find the nearest city'));
              }
            });
          });
          petNearestCityLat = resultBounds.lat();
          petNearestCityLong = resultBounds.lng();
        }
      } catch (err) {
        console.log(err);
      }
    };
    await GeoCode();

    const petStatus = 'PRE-REGISTERED';
    const uniqueIdentifier = `PP${newUserIndex}`;
    const newUUID = createUUID();
    const permissionToEnter = 'N/A';
    const updateDatabase = async () => {
      if (!mapOpened) {
        console.log('map not open clicked');
        try {
          await firestore.collection('preregisteredPet').add({
            UserID: userID,
            OwnerFirstName: ownerFirstName,
            OwnerLastName: ownerLastName,
            OwnerPhoneNumber: ownerPhoneNumber,
            OwnerEmail: ownerEmail,
            OwnerUUID: newUUID,
            PetName: petName,
            PetSpecies: petSpecies,
            PetBreed: petBreed,
            PetImage: petPhotoUploadPath,
            PetAdditionalDetails: petDetail,
            PetChipNumber: petChip,
            PetTattooNumber: petTattoo,
            PetSuiteNumber: petLocationSuite,
            PetAddress: petAddress,
            PetCity: petCity,
            PetProvinceState: petProvinceState,
            PetCountry: petCountry,
            PetPostalCode: petPostalCode,
            PetLattitude: petLatitude,
            PetLongitude: petLongitude,
            DateInitialEntry: getDateTimeString(),
            PetStatus: petStatus,
            PPID: uniqueIdentifier,
            URL: downloadURL,
            PermissionToEnter: permissionToEnter,
            PetLocationKnown: petLocationKnown,
          }).then(() => {
            console.log('trying to redirect');
            history.push('/PreRegisterConfirm');
          });
        } catch (error) {
          console.log(error);
          alert('Error in registering. Please contact the admin.');
        }
      } else {
        console.log('map open clicked');
        try {
          await firestore.collection('preregisteredPet').add({
            UserID: userID,
            OwnerFirstName: ownerFirstName,
            OwnerLastName: ownerLastName,
            OwnerPhoneNumber: ownerPhoneNumber,
            OwnerEmail: ownerEmail,
            OwnerUUID: newUUID,
            PetName: petName,
            PetSpecies: petSpecies,
            PetBreed: petBreed,
            PetImage: petPhotoUploadPath,
            PetAdditionalDetails: petDetail,
            PetChipNumber: petChip,
            PetTattooNumber: petTattoo,
            PetNearestCityLat: petNearestCityLat,
            PetNearestCityLong: petNearestCityLong,
            petNearestCityName: closestCity,
            PetLattitude: marker.getPosition().lat(),
            PetLongitude: marker.getPosition().lng(),
            DateInitialEntry: getDateTimeString(),
            PetStatus: petStatus,
            PPID: uniqueIdentifier,
            URL: downloadURL,
            PermissionToEnter: permissionToEnter,
            PetLocationKnown: petLocationKnown,
          }).then(() => {
            console.log('trying to redirect');
            history.push('/PreRegisterConfirm');
          });
        } catch (error) {
          console.log(error);
          alert('Error in registering. Please contact the admin.');
        }
      }
    };
    await updateDatabase();
  };

  const submitInfo = async (e) => {
    console.log('submit');
    // prevent the default form submit
    e.preventDefault();
    if (ownerEmail !== emailConfirm) {
      alert("Your emails don't match please try again");
      return;
    }

    // Check if user specified whether pet location is known or not
    if (document.getElementById('locViaMapRadio').checked) {
      petLocationKnown = false;
      petLocationSet = true;
      resetAddressInput();
    }
    if (document.getElementById('locViaAddressRadio').checked) {
      petLocationKnown = true;
      petLocationSet = true;
    }

    if (!petLocationSet) {
      alert('Please specify whether the pet is located inside the address provided.');
      return;
    }

    document.getElementById('submitButton').disabled = true;
    document.getElementById('submitButton').value = 'Updating, please wait';

    document.getElementById('submitButton').disabled = true;
    document.getElementById('submitButton').style.backgroundColor = '#A9A9A9';

    alert('Thank you for your patience, this will take just a moment. Please click “Okay” and then wait until you are taken to the confirmation page');

    let newUserIndex;
    const incrementRef = await firestore.collection('preregisteredPet').doc('incrementation');
    try {
      incrementRef.get()
        .then(async (doc) => {
          if (!doc.exists) {
            console.log('No such document!');
          } else {
            console.log('got incrementation');
            newUserIndex = await doc.data().incrementer + 1;
            incrementRef.update({ incrementer: newUserIndex })
              .then(async () => {
                console.log('incrementation updated');

                // an image has not been uploaded
                if (selectedFile == null) {
                  // setting default image, this function is for no image uploaded
                  console.log('no image uploaded');
                  const downloadURL = 'https://firebasestorage.googleapis.com/v0/b/disaster-pets.appspot.com/o/notfound.png?alt=media&token=b0a8286e-93d4-4257-8c41-ba9745c1f436';
                  await savePetInfo(newUserIndex, downloadURL);
                } else { // an image has been uploaded
                  console.log('image uploaded');

                  const imageRef = await firebase.storage().ref(`/petImages/PPID-PP${newUserIndex}-Image-${userID}`);
                  const uploadTaskSnapShot = await imageRef.put(selectedFile);

                  uploadTaskSnapShot.ref.getDownloadURL().then(async (imageURL) => {
                    await savePetInfo(newUserIndex, imageURL);
                  });
                }
              }).catch((err) => {
                console.log('Error updating document', err);
              });
          }
        })
        .catch((err) => {
          console.log('Error getting document', err);
        });
    } catch (err) {
      alert('Error in registering. Please contact the organization.');
      document.getElementById('submitButton').disabled = false;
      document.getElementById('submitButton').style.backgroundColor = '#6BC9F2';
      document.getElementById('submitButton').value = 'Submit';
      console.log(err);
    }
  };

  /**
   * Move view to the top.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Get database information if user is authenticated
   */
  useEffect(() => {
    /**
     * Get UserId from database
     */
    const getUID = async () => {
      userID = user.getUID();
    };

    if (user.isAuthenticated()) {
      getUID();
    }
  }, [user]);

  return (
    <div id="page_container">
      <div className="padding-top-30">
        <div className="wrapper">
          <form className="padding-1rem" onSubmit={submitInfo}>
            <h2 className="text-align-center">Pre-register Pet</h2>
            <br />
            <p>
              <b style={{ color: 'red' }}>Warning:</b>
              &nbsp;The Authorized Response Agencies page lists the responding
              organizations and government agencies which are monitoring this site. If your local
              authorities are not listed, please contact them to inform them of this platform
              so that your pet evacuation request does not go unseen.
            </p>
            <br />
            <p>
              <b style={{ color: 'red' }}>Warning:</b>
              &nbsp;You are about to PRE-REGISTER your pet. This means that your information
              will be saved for future requests for assistance if necessary. If you require
              immediate assistance, submit your information through Register a Lost Pet.
            </p>
            <p className="disclaimer">
              &apos;*&apos;Denotes Required Field
            </p>
            <button type="button" className="color-white background-four form-buttons" onClick={window.print} style={{ padding: '5px 20px' }}>Print Page</button>
            <div>
              <h3 className="margin-top-30 text-align-center">
                Owner Information:
              </h3>
              <p>
                <label htmlFor="firstName" className="max-width">
                  Owner First Name: *
                  <input
                    className="form-control"
                    type="text"
                    id="pet_owner_first_name"
                    name="pet_owner_first_name"
                    value={ownerFirstName}
                    onChange={((ev) => setOwnerFirstName(ev.target.value))}
                    required
                  />
                </label>
              </p>
              <p>
                <label htmlFor="lastName" className="max-width">
                  Owner Last Name: *
                  <input
                    className="form-control"
                    type="text"
                    id="pet_owner_last_name"
                    name="pet_owner_last_name"
                    value={ownerLastName}
                    onChange={((ev) => setOwnerLastName(ev.target.value))}
                    required
                  />
                </label>
              </p>
              <p>
                <label htmlFor="phoneNumber" className="max-width">
                  Owner Phone Number: *
                  <input
                    className="form-control"
                    type="text"
                    id="pet_owner_phone_number"
                    name="pet_owner_phone_number"
                    value={ownerPhoneNumber}
                    placeholder="1234567890"
                    pattern={numberOnlyRegex}
                    title="Number only."
                    onChange={((ev) => setOwnerPhoneNumber(ev.target.value))}
                    required
                  />
                </label>
              </p>
              <p>
                <label htmlFor="email" className="max-width">
                  Owner Email: *
                  <input
                    className="form-control"
                    type="email"
                    id="pet_owner_email"
                    name="pet_owner_email"
                    value={ownerEmail}
                    pattern={emailRegex}
                    title="Must follow email convention"
                    onChange={((ev) => setOwnerEmail(ev.target.value))}
                    required
                  />
                </label>
              </p>
              <p>
                <label htmlFor="emailConfirm" className="max-width">
                  Email Confirmation: *
                  <input
                    className="form-control"
                    type="email"
                    id="pet_owner_email_confirm"
                    name="petpet_owner_email_confirm_owner_email"
                    value={emailConfirm}
                    pattern={emailRegex}
                    title="Must match your email"
                    onChange={((ev) => setEmailConfirm(ev.target.value))}
                    required
                  />
                </label>
              </p>
            </div>
            <div>
              <h3 className="text-align-center">
                Pet Information:
              </h3>
              <p>
                <label htmlFor="petName" className="max-width">
                  Pet Name: *
                  <input
                    className="form-control"
                    type="text"
                    id="pet_name"
                    name="pet_name"
                    value={petName}
                    onChange={((ev) => setPetName(ev.target.value))}
                    required
                  />
                </label>
              </p>
              <p>
                <label htmlFor="petSpecies" className="max-width">
                  Pet Species: *
                  <input
                    className="form-control"
                    type="text"
                    id="pet_species"
                    name="pet_species"
                    value={petSpecies}
                    placeholder="Examples: Dog, Cat, Horse"
                    onChange={((ev) => setPetSpecies(ev.target.value))}
                    required
                  />
                </label>
              </p>
              <p>
                <label htmlFor="petBreed" className="max-width">
                  Pet Breed:
                  <input
                    className="form-control"
                    type="text"
                    id="pet_breed"
                    name="pet_breed"
                    value={petBreed}
                    placeholder="Examples: Collie, Tabby, Thoroughbred, Mutt"
                    onChange={((ev) => setPetBreed(ev.target.value))}
                  />
                </label>
              </p>
              <p>
                {/* <!-- add the current pet photo --> */}
                <label htmlFor="petImage" className="max-width">
                  Photo of Pet:
                </label>
                <img id="petImage" alt="Profile Pic" style={{ display: 'none', width: '200px' }} />
                <button type="button" id="removePhotoButton" onClick={removePhoto}>Remove Photo</button>
                <label 
                  htmlFor="image_button" 
                  className="select_photo_button">Select Photo</label>
                <span id="imageFileName"></span>
                <input
                  type="file"
                  style={{ visibility: 'hidden' }}
                  id="image_button"
                  name="pet_image"
                  accept="image/*"
                  onChange={((ev) => showPetImage(ev))}
                />
                <br />
              </p>
              <br />
              <p>
                <label htmlFor="petDetail" className="max-width">
                  Additional Details:
                  <input
                    className="form-control"
                    type="text"
                    id="additional_details_text"
                    name="additional-details-text"
                    placeholder="Examples: Hides under couch when scared, Snake is venomous, Requires medication"
                    value={petDetail}
                    onChange={((ev) => setPetDetail(ev.target.value))}
                  />
                </label>
                <br />
                <br />
              </p>
              <p>
                <label htmlFor="petChip" className="max-width">
                  Microchip #:
                  <input
                    className="form-control"
                    type="text"
                    id="pet_chip_number"
                    name="pet_chip_number"
                    value={petChip}
                    onChange={((ev) => setPetChip(ev.target.value))}
                  />
                </label>
                <br />
              </p>
              <p>
                <label htmlFor="petTattoo" className="max-width">
                  Tattoo #:
                  <input
                    className="form-control"
                    type="text"
                    id="pet_tattoo_number"
                    name="pet_tattoo_number"
                    value={petTattoo}
                    onChange={((ev) => setPetTattoo(ev.target.value))}
                  />
                </label>
                <br />
              </p>
              <br />
            </div>
            <h3 className="text-align-center">Pet Rescue Location:</h3>
            <b>
              Select whether the pet is located at a specific address or a general area
              (to be selected on a map): *
            </b>
            <br />
            <input type="radio" name="addressOrMapRadio" id="locViaAddressRadio" onClick={getAddressTextInput} />
            &nbsp;Exact pet location provided via address.
            <br />
            <input type="radio" name="addressOrMapRadio" id="locViaMapRadio" onClick={getAddressFromMap} />
            &nbsp;General pet location provided via map.
            <br />
            <br />
            <div id="outerMapDiv" style={{ display: 'none' }}>
              <div id="map" style={{ width: '100%', height: windowWidth < 500 ? '500px' : '300px' }}>
                <div ref={searchBarRef} style={{ fontSize: '18px', marginTop: '12px', marginLeft: '12px' }}>
                  <input
                    id="map-input"
                    type="text"
                    placeholder="Enter a location"
                    style={{
                      width: '200px',
                      minWidth: '150px',
                      minHeight: '36px',
                    }}
                    ref={inputRef}
                  />
                  <button
                    id="map-search-button"
                    className="form-buttons"
                    type="button"
                    style={{
                      minHeight: '36px',
                    }}
                    onClick={searchMap}
                  >
                    Search
                  </button>
                </div>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY, libraries: ['places', 'geocoder'] }}
                  defaultCenter={initialLocation}
                  defaultZoom={mapZoomInValue}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) => {
                    handleApiLoaded(map, maps);
                  }}
                  onClick={placeMarker}
                  options={createMapOptions}
                />
              </div>
            </div>
            <div id="outerAddressDiv">
              <p>
                <label htmlFor="petLocationSuite" className="max-width">
                  Pet&apos;s Apartment/Suite #:
                  <input
                    className="form-control"
                    type="text"
                    id="pet_location_suite"
                    name="pet_location_suite"
                    placeholder="Examples: #104, or Second Basement Suite"
                    value={petLocationSuite}
                    title="Must begin with '#', alphabets, or numbers; end with alphabets or numbers. "
                    onChange={((ev) => setPetLocationSuite(ev.target.value))}
                  />
                </label>
                <br />
              </p>
              <p>
                <label htmlFor="petAddress" className="max-width">
                  Pet&apos;s Current Street Address: *
                  <br />
                  <b>
                    Please do not add the Apartment/Suite # in this field or it may display
                    incorrectly in Google Maps.
                  </b>
                  <input
                    className="form-control"
                    type="text"
                    id="pet_location_address"
                    name="pet_location_address"
                    value={petAddress}
                    pattern={addressRegex}
                    title="Must begin with '#', alphabets, or number; end with '.', alphabets or numbers."
                    onChange={((ev) => setPetAddress(ev.target.value))}
                  />
                </label>
                <br />
              </p>
              <p>
                <label htmlFor="petCity" className="max-width">
                  Pet&apos;s Current City: *
                  <input
                    className="form-control"
                    type="text"
                    id="pet_location_city"
                    name="pet_location_city"
                    value={petCity}
                    pattern={cityRegex}
                    title="Alphabet only."
                    onChange={((ev) => setPetCity(ev.target.value))}
                  />
                </label>
                <br />
              </p>
              <p>
                <label htmlFor="petProvinceState" className="max-width">
                  Pet&apos;s Current Province/State: *
                  <input
                    className="form-control"
                    type="text"
                    id="pet_location_province_state"
                    name="pet_location_province_state"
                    value={petProvinceState}
                    pattern={countryRegex}
                    title="Full name or abbreviation."
                    onChange={((ev) => setPetProvinceState(ev.target.value))}
                  />
                </label>
                <br />
              </p>
              <p>
                <label htmlFor="petCountry" className="max-width">
                  Pet&apos;s Current Country: *
                  <input
                    className="form-control"
                    type="text"
                    id="pet_location_country"
                    name="pet_location_country"
                    value={petCountry}
                    pattern={countryRegex}
                    title="Full name or abbreviation."
                    onChange={((ev) => setPetCountry(ev.target.value))}
                  />
                </label>
                <br />
              </p>
              <p>
                <label htmlFor="petPostalCode" className="max-width">
                  Pet&apos;s Current Postal Code: *
                  <input
                    className="form-control"
                    type="text"
                    id="pet_location_postal_code"
                    name="pet_location_postal_code"
                    value={petPostalCode}
                    pattern={postalRegex}
                    title="Alphabets or numbers only."
                    onChange={((ev) => setPetPostalCode(ev.target.value))}
                  />
                </label>
                <br />
                <br />
              </p>
            </div>
            <div className="centered_button">
              <input className="submit_button form-buttons" type="submit" id="submitButton" disabled />
            </div>
          </form>
          <p>
            * If you are having difficulty submitting this entry, please refresh your page
            and type your answers as opposed to using autofill. If issues persist, please
            contact Disaster Pets.
          </p>
        </div>
      </div>
    </div>
  );
};

PreRegisterPet.propTypes = {
  user: PropTypes.shape().isRequired,
  firestore: PropTypes.shape().isRequired,
  firebase: PropTypes.shape().isRequired,
};

export default PreRegisterPet;
