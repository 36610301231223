import React from 'react';

const DonateButton = () => (
  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
    <input type="hidden" name="cmd" value="_s-xclick" />
    <input type="hidden" name="hosted_button_id" value="N8LG8P9GQ46WU" />

    <input
      type="image"
      src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
      border="0"
      name="submit"
      title="PayPal - The safer, easier way to pay online!"
      alt="Donate with PayPal button"
    />

    <img alt="" src="https://www.paypal.com/en_CA/i/scr/pixel.gif" width="1" height="1" />

  </form>
);

export default DonateButton;
