import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

const AuthAlert = ({ show, msg, close, variant}) => {
  let nmsg = msg;
  nmsg = msg.split('\n').map((str) => <p>{str}</p>);
  return (
    <Alert show={show} variant={variant} onClose={close} dismissible>
      <p>
        {nmsg}
      </p>
    </Alert>
  );
};

AuthAlert.propTypes = {
  show: PropTypes.bool.isRequired,
  msg: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};
export default AuthAlert;
