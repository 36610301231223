import React from 'react';

const Contact = ({ list }) => list.map((item) => (
  <div key={item.title}>
    <h4><u>{item.title}</u></h4>
    {
      item.names.map((person) => (
        <div key={person.name + person.email}>
          <p className="margin-bottom-5">{person.name}</p>
          <p>{person.email}</p>
        </div>
      ))
    }
  </div>
));

export default Contact;
