import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';

const ChangeStatus = ({ profile, submit, close }) => {
  const [statusProfileData, setProfileData] = useState({ ...profile });
  const [fieldDisabled, setFieldDisabled] = useState(statusProfileData.PetStatus !== 'IN TRANSIT/CARE');
  const [location, setLocation] = useState(statusProfileData.PetStatus !== 'IN TRANSIT/CARE' ? 'IN TRANSIT/CARE ONLY' : '');
  const [contactName, setContactName] = useState(statusProfileData.PetStatus !== 'IN TRANSIT/CARE' ? 'IN TRANSIT/CARE ONLY' : '');
  const [contactPhone, setContactPhone] = useState(statusProfileData.PetStatus !== 'IN TRANSIT/CARE' ? 'IN TRANSIT/CARE ONLY' : '');
  const [contactEmail, setContactEmail] = useState(statusProfileData.PetStatus !== 'IN TRANSIT/CARE' ? 'IN TRANSIT/CARE ONLY' : '');

  const handleFormFieldChange = (field, event) => {
    console.log(event.target.value);
    const updatedProfile = statusProfileData;
    updatedProfile[field] = event.target.value;
    setProfileData(updatedProfile);
  };

  const handleLocationChange = (field, event) => {
    const updatedProfile = statusProfileData;
    updatedProfile[field] = event.target.value;
    setLocation(event.target.value);
    setProfileData(updatedProfile);
  };

  const handleContactNameChange = (field, event) => {
    const updatedProfile = statusProfileData;
    updatedProfile[field] = event.target.value;
    setContactName(event.target.value);
    setProfileData(updatedProfile);
  };

  const handleContactPhoneChange = (field, event) => {
    const updatedProfile = statusProfileData;
    updatedProfile[field] = event.target.value;
    setContactPhone(event.target.value);
    setProfileData(updatedProfile);
  };

  const handleContactEmailChange = (field, event) => {
    console.log(event.target.value);
    const updatedProfile = statusProfileData;
    updatedProfile[field] = event.target.value;
    setContactEmail(event.target.value);
    setProfileData(updatedProfile);
  };

  return (
    <div className="ChangeStatus">
      <form onSubmit={(event) => submit(event, statusProfileData)}>
        <h5><b>Change Evacuation Status</b></h5>
        <select
          className="custom-select"
          id="status_drop_down"
          onChange={(event) => {
            if (event.target.value !== 'IN TRANSIT/CARE') {
              setLocation('IN TRANSIT/CARE ONLY');
              setContactName('IN TRANSIT/CARE ONLY');
              setContactPhone('IN TRANSIT/CARE ONLY');
              setContactEmail('IN TRANSIT/CARE ONLY');
              setFieldDisabled(true);
            } else {
              setLocation('');
              setContactName('');
              setContactPhone('');
              setContactEmail('');
              setFieldDisabled(false);
            }
            handleFormFieldChange('PetStatus', event);
          }}
        >
          <option id="evac_status" value="REQUIRES EVACUATION" selected={statusProfileData.PetStatus === 'REQUIRES EVACUATION'}>REQUIRES EVACUATION</option>
          <option id="in_transit_status" value="IN TRANSIT/CARE" selected={statusProfileData.PetStatus === 'IN TRANSIT/CARE'}>IN TRANSIT/CARE</option>
          <option it="reunited_status" value="REUNITED WITH OWNER" selected={statusProfileData.PetStatus === 'REUNITED WITH OWNER'}>REUNITED WITH OWNER</option>
        </select>
        <label htmlFor="new_location" className="ChangeStatusFormLabel">
          <b>Location: *</b>
          <input
            type="text"
            name="new_location"
            id="new_location"
            value={location}
            required
            disabled={fieldDisabled}
            style={{ backgroundColor: fieldDisabled ? 'grey' : 'white' }}
            onChange={(event) => {
              handleLocationChange('CareProviderLocation', event);
            }}
          />
        </label>
        <label htmlFor="new_contact_name" className="ChangeStatusFormLabel">
          <b>Contact Name: *</b>
          <input
            type="text"
            name="new_contact_name"
            id="new_contact_name"
            value={contactName}
            required
            disabled={fieldDisabled}
            style={{ backgroundColor: fieldDisabled ? 'grey' : 'white' }}
            onChange={(event) => {
              handleContactNameChange('CurrentContactName', event);
            }}
          />
        </label>

        <label htmlFor="new_contact_phone" className="ChangeStatusFormLabel">
          <b>Contact Phone #: *</b>
          <input
            type="tel"
            name="new_contact_phone_number"
            id="new_contact_phone_number"
            value={contactPhone}
            required
            disabled={fieldDisabled}
            style={{ backgroundColor: fieldDisabled ? 'grey' : 'white' }}
            onChange={(event) => {
              handleContactPhoneChange('CurrentContactPhoneNumber', event);
            }}
          />
        </label>

        <label htmlFor="new_contact_email" className="ChangeStatusFormLabel">
          <b>Contact Email: *</b>
          <input
            type="email"
            name="new_contact_email"
            id="new_contact_email"
            value={contactEmail}
            required
            disabled={fieldDisabled}
            style={{ backgroundColor: fieldDisabled ? 'grey' : 'white' }}
            onChange={(event) => {
              handleContactEmailChange('CurrentContactEmail', event);
            }}
          />
        </label>

        <Button type="submit" value="submit" className="margin-right-10" variant="info">Submit</Button>
        <Button type="button" variant="danger" onClick={close}>Close</Button>
      </form>
    </div>
  );
};

ChangeStatus.propTypes = {
  submit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default ChangeStatus;
