import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import GovernmentAdmin from './GovernmentAdmin';
import SubordinateField from './SubordinateField';
import TransferModal from './TransferModal';
import AuthAlert from './AuthAlert';
import OrganizationAdmin from './OrganizationAdmin';
import SpecialAccessUser from './SpecialAccessUser';

const AuthenticateUser = ({ firestore, fbFunctions, user, fbAuth }) => {
  const history = useHistory();
  const [govAdminEmail, setGovAdminEmail] = useState('');
  const [orgAdminEmail, setOrgAdminEmail] = useState('');
  const [specialAccessUserEmail, setSpecialAccessUserEmail] = useState('');
  const [transferEmail, setTransferEmail] = useState('');
  const [infoToTransferFrom, setInfoToTransferFrom] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessageModal, setAlertMessageModal] = useState('');
  const [alertVariant, setAlertVariant] = useState('info');

  const [users, setUsers] = useState([]);

  const handleClose = () => setShowModal(false);
  const handleShow = (fields) => {
    setInfoToTransferFrom(fields);
    setShowModal(true);
  };

  const handleShowAlert = (msg) => {
    setAlertMessage(msg);
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleShowAlertModal = (msg, variant) => {
    setAlertMessageModal(msg);
    setAlertVariant(variant);
    setShowAlertModal(true);
  };

  const handleCloseAlertModal = () => {
    setShowAlertModal(false);
  };

  const handleGovAdminChange = (e) => {
    setGovAdminEmail(e.target.value);
  };

  const handleGovernmentAdminSubmit = async (e) => {
    e.preventDefault();
    if (govAdminEmail === user.email) {
      handleShowAlert('you entered your own email');
      return;
    }
    const addGovRole = fbFunctions.httpsCallable('addGovRole');
    const signinMethods = await fbAuth.fetchSignInMethodsForEmail(govAdminEmail);
    if (signinMethods.length === 0) {
      handleShowAlert('email not registered');
    } else {
      const snapshot = await firestore.collection('authenticatedUsers').where('subordinate', '==', govAdminEmail).get();
      if (snapshot.empty) {
        const result = await addGovRole({ email: govAdminEmail });
        if (result.data.message === 'You are not worthy!') {
          handleShowAlert(result.data.message);
        } else {
          handleShowAlert(result.data.message);
          await firestore.collection('authenticatedUsers').add({
            role: 'Government Administrator',
            supervisor: user.email,
            subordinate: govAdminEmail,
          });
          window.location.reload();
        }
      } else {
        handleShowAlert('user is already authenticated');
      }
    }
  };

  const handleOrgAdminChange = (e) => {
    setOrgAdminEmail(e.target.value);
  };

  const handleOrgAdminSubmit = async (e) => {
    e.preventDefault();
    if (orgAdminEmail === user.email) {
      handleShowAlert('you entered your own email');
      return;
    }
    const addOrgRole = fbFunctions.httpsCallable('addOrgAdminRole');
    const signinMethods = await fbAuth.fetchSignInMethodsForEmail(orgAdminEmail);
    if (signinMethods.length === 0) {
      handleShowAlert('email not registered');
    } else {
      const snapshot = await firestore.collection('authenticatedUsers').where('subordinate', '==', orgAdminEmail).get();
      if (snapshot.empty) {
        const result = await addOrgRole({ email: orgAdminEmail });
        if (result.data.message === 'You are not worthy!') {
          handleShowAlert(result.data.message);
        } else {
          handleShowAlert(result.data.message);
          await firestore.collection('authenticatedUsers').add({
            role: 'Organization Administrator',
            supervisor: user.email,
            subordinate: orgAdminEmail,
          });
          window.location.reload();
        }
      } else {
        handleShowAlert('user is already authenticated');
      }
    }
  };

  const handleSpecialAccessUserChange = (e) => {
    setSpecialAccessUserEmail(e.target.value);
  };

  const handleSpecialAccessUserSubmit = async (e) => {
    e.preventDefault();
    if (specialAccessUserEmail === user.email) {
      handleShowAlert('you entered your own email');
      return;
    }
    const addSpecialAccessUserRole = fbFunctions.httpsCallable('addVolunteerRole');
    const signinMethods = await fbAuth.fetchSignInMethodsForEmail(specialAccessUserEmail);
    if (signinMethods.length === 0) {
      handleShowAlert('email not registered');
    } else {
      const snapshot = await firestore.collection('authenticatedUsers').where('subordinate', '==', specialAccessUserEmail).get();
      if (snapshot.empty) {
        const result = await addSpecialAccessUserRole({ email: specialAccessUserEmail });
        if (result.data.message === 'You are not worthy!') {
          handleShowAlert(result.data.message);
        } else {
          handleShowAlert(result.data.message);
          await firestore.collection('authenticatedUsers').add({
            role: 'Special Access User',
            supervisor: user.email,
            subordinate: specialAccessUserEmail,
          });
          window.location.reload();
        }
      } else {
        handleShowAlert('user is already authenticated');
      }
    }
  };

  const handleEmailTransferChange = (e) => {
    setTransferEmail(e.target.value);
  };

  const handleEmailTransferSubmit = async (e) => {
    e.preventDefault();
    if (infoToTransferFrom.dataEmail === transferEmail) {
      alert('you entered the same email');
      return;
    }
    const snapshot = await firestore.collection('authenticatedUsers').where('subordinate', '==', transferEmail).get();
    if (snapshot.empty) {
      handleShowAlertModal('account needs to sign up for disaster pets', 'warning');
      return;
    }
    let id;
    snapshot.docs.forEach((element) => { id = element.id; });
    const item = await firestore.collection('authenticatedUsers').doc(id).get();
    const item2 = await firestore.collection('authenticatedUsers').doc(infoToTransferFrom.dataid).get();

    const oldData = item2.data();
    const newData = item.data();
    if (oldData.role !== newData.role) {
      handleShowAlertModal(`Transfer requires the recipient to be granted the same authentication level.\n
      1. Individual must sign up with Disaster Pets.\n
      2. Grant individual desired authentication level above by using their Disaster Pets email.\n
      3. Transfer authentication/users by using their Disaster Pets email.\n
      4. Remove previous authenticated user’s authentication if required.
      `, 'danger');
    } else {
      const change = await firestore.collection('authenticatedUsers').where("supervisor", "==", infoToTransferFrom.dataEmail).get();
      const ids = [];
      change.forEach((doc) => {
        ids.push(doc.id);
      });
      handleShowAlertModal('success', 'success');
      await Promise.all(ids.map((subid) => firestore.collection('authenticatedUsers').doc(subid).update({ supervisor: transferEmail })));
      window.location.reload();
    }
  };

  const handleEmailRemove = async (e, info) => {
    e.preventDefault();
    const removeAllRoles = fbFunctions.httpsCallable('removeAllRoles');
    await removeAllRoles({ email: info.dataEmail });
    await firestore.collection('authenticatedUsers').doc(info.dataid).delete();
    handleShowAlert('successfully removed access');
    window.location.reload();
  };

  const getUsers = async () => {
    const newList = [];
    const snapshot = await firestore.collection('authenticatedUsers').where('supervisor', '==', user.email).orderBy('subordinate').get();
    const changes = snapshot.docChanges();
    changes.forEach((change) => {
      if (change.type === 'added') {
        newList.push(
          {
            dataid: change.doc.id,
            dataEmail: change.doc.data().subordinate,
            role: change.doc.data().role,
          },
        );
      }
    });
    return newList;
  };

  useEffect(() => {
    if (!user.isAdmin && !user.isGovernment && !user.isOrgAdmin) {
      alert('You must be an authenticated user in order to access this page. Please contact Disaster Pets for more information.');
      history.push('/');
    } else {
      const getUserData = async () => {
        const userList = await getUsers();
        setUsers(userList);
      };
      getUserData();
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const subordinateList = users.map((field) => (
    <SubordinateField fields={field} openModal={handleShow} remove={handleEmailRemove} />
  ));

  return (
    <div className="container-fluid margin-top-10" style={{ textAlign: 'center'}}>
      <h2>Authentication Manager</h2>
      <div className="margin-top-70 row justify-content-center">
        <GovernmentAdmin change={handleGovAdminChange} submit={handleGovernmentAdminSubmit} />
        <OrganizationAdmin change={handleOrgAdminChange} submit={handleOrgAdminSubmit} />
        <SpecialAccessUser
          change={handleSpecialAccessUserChange}
          submit={handleSpecialAccessUserSubmit}
        />
      </div>
      <div className="row justify-content-center">
        <AuthAlert
          show={showAlert}
          msg={alertMessage}
          close={handleCloseAlert}
          variant={alertVariant}
        />
      </div>
      <h2 className="margin-top-50"><b>Authenticated Users</b></h2>
      <div className="row justify-content-center">
        <ul className="col-sm-10">
          {subordinateList}
        </ul>
      </div>
      <TransferModal
        emailChange={handleEmailTransferChange}
        show={showModal}
        close={handleClose}
        fromInfo={infoToTransferFrom}
        submit={handleEmailTransferSubmit}
        variant={alertVariant}
        showAlert={showAlertModal}
        alertMessage={alertMessageModal}
        handleCloseAlert={handleCloseAlertModal}
      />
    </div>
  );
};

AuthenticateUser.propTypes = {
  fbFunctions: PropTypes.shape().isRequired,
  firestore: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  fbAuth: PropTypes.shape().isRequired,
};

export default AuthenticateUser;
