import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ForgotPasswordPage = ({ firebase }) => {
  const [email, setEmail] = useState('');

  // Sends password reset email to email provided by the user
  const sendResetEmail = async () => {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      alert(`An email to change your password has been sent to ${email}. You must follow the instructions in the email to change your password`);
      setEmail('');
    } catch (err) {
      alert(err);
    }
  };

  return (
    <div className="margin-left-auto margin-right-auto" style={{ maxWidth: 960 }}>
      <p>Enter your email and we&apos;ll send you a link to reset your password.</p>
      <input value={email} onChange={(e) => setEmail(e.target.value)} className="border-radius-5 border-none padding-5 border-grey margin-bottom-20 h3 padding-left-10" placeholder="Email" />
      <br />
      <button type="button" className="padding-10 h4" onClick={sendResetEmail}>SEND RESET EMAIL</button>
    </div>
  );
};

ForgotPasswordPage.propTypes = {
  firebase: PropTypes.shape().isRequired,
};

export default ForgotPasswordPage;
