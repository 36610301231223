/* eslint-disable global-require */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable object-property-newline */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import {
  Link,
} from 'react-router-dom';

import getDateTimeString from '../../helpers/getDateTimeString';

import { FeedingStationForm, FeedingStationInfoWindow } from './FeedingStationForm';

const BOWL_IMG = require('../../assets/bowl.png');

const FOUND_PET_COLLECTION = 'foundPets';
const REGISTERED_PET_COLLECTION = 'registeredPets';
const PET_STATUS_UPDATE_COLLECTION = 'petStatusUpdates';

// Order is important
const PET_STATUS = [
  'REQUIRES EVACUATION',
  'IN TRANSIT/CARE',
  'REUNITED WITH OWNER',
];

let googleMapObject;
let googleMapsObject;
let autocomplete;
let infoWindow;
let feedingInfoWindow;
let feedingStationMarker;
// let oms;

const createMapOptions = (maps) => ({
  fullscreenControlOptions: {
    position: maps.ControlPosition.TOP_LEFT,
  },
});

function compare(a, b) {
  // DP comes before FP
  const aID = a.FPID || a.DPID;
  const bID = b.FPID || b.DPID;
  if (aID.substring(0, 1) < bID.substring(0, 1)) return -1;
  if (aID.substring(0, 1) > bID.substring(0, 1)) return 1;

  if (aID.length < bID.length) return -1;
  if (aID.length > bID.length) return 1;
  if (aID < bID) return -1;
  if (aID > bID) return 1;
  return 0;
}

function createCommentBoxDiv(commentObject) {
  const {
    DateTime,
    FoodType,
    FoodAmount,
    ObservedPets,
    Comment,
    Name,
    PhoneNumber,
    Email,
  } = commentObject;
  const commentBoxDiv = document.createElement('div');
  commentBoxDiv.setAttribute('class', 'comment-box text-align-left border-grey margin-bottom-5');

  // Date/time
  const dateLabel = document.createTextNode('Date/Time: ');
  const boldDate = document.createElement('b');
  const dateContent = document.createTextNode(DateTime);
  boldDate.appendChild(dateContent);
  commentBoxDiv.appendChild(dateLabel);
  commentBoxDiv.appendChild(boldDate);
  commentBoxDiv.appendChild(document.createElement('br'));

  // Food Type
  const foodTypeLabel = document.createTextNode(`Food Type: ${FoodType}`);
  commentBoxDiv.appendChild(foodTypeLabel);
  commentBoxDiv.appendChild(document.createElement('br'));
  // Food Amount
  const foodAmountLabel = document.createTextNode(`Food Amount (lbs): ${FoodAmount}`);
  commentBoxDiv.appendChild(foodAmountLabel);
  commentBoxDiv.appendChild(document.createElement('br'));
  // Observed Pets
  const observedPetsLabel = document.createTextNode(`Observed Pets: ${ObservedPets}`);
  commentBoxDiv.appendChild(observedPetsLabel);
  commentBoxDiv.appendChild(document.createElement('br'));
  // Comment
  const commentLabel = document.createTextNode('Comment: ');
  const boldComment = document.createElement('b');
  const commentContent = document.createTextNode(Comment);
  boldComment.appendChild(commentContent);
  commentBoxDiv.appendChild(commentLabel);
  commentBoxDiv.appendChild(boldComment);
  commentBoxDiv.appendChild(document.createElement('br'));

  // Name
  const nameLabel = document.createTextNode(`Contact Name: ${Name}`);
  commentBoxDiv.appendChild(nameLabel);
  commentBoxDiv.appendChild(document.createElement('br'));

  // Contact Number
  const contactLabel = document.createTextNode(`Contact Number: ${PhoneNumber}`);
  commentBoxDiv.appendChild(contactLabel);
  commentBoxDiv.appendChild(document.createElement('br'));

  // Contact Email
  const emailLabel = document.createTextNode(`Contact Email: ${Email}`);
  commentBoxDiv.appendChild(emailLabel);
  commentBoxDiv.appendChild(document.createElement('br'));

  return commentBoxDiv;
}

function addToFeedingStationTimeline(inputToggleButton, inputSection) {
  if (inputSection.style.display === 'none') {
    inputSection.style.display = 'block';
    inputToggleButton.innerHTML = 'Hide Form';
  } else {
    inputSection.style.display = 'none';
    inputToggleButton.innerHTML = 'Add to Timeline';
  }
}

/**
 * EvacuationResponseMap Page
 */
const EvacuationResponseMap = ({ firestore, user }) => {
  function useStateRef(state) {
    const stateRef = useRef(state);
    useEffect(() => {
      stateRef.current = state;
    });
    return stateRef;
  }

  const [searchInput, setSearchInput] = useState('');
  const inputRef = useRef(null);
  const [searchPetInput, setSearchPetInput] = useState('');
  const searchPetInputRef = useStateRef(searchPetInput);
  const [petsNeedEvac, setPetsNeedEvac] = useState([]);
  const evacPetsRef = useStateRef(petsNeedEvac);
  const [evacPetsToShow, setEvacPetsToShow] = useState([]);
  const [lostPets, setLostPets] = useState([]);
  const lostPetsRef = useStateRef(lostPets);
  const [lostPetsToShow, setLostPetsToShow] = useState([]);
  const [feedingStations, setFeedingStations] = useState([]);

  const [markers, setMarkers] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [toggleLegend, setToggleLegend] = useState(true);
  const legendRef = useRef(null);

  // Legend States
  const [legendEvacuation, setLegendEvacuation] = useState(true);
  const [legendLost, setLegendLost] = useState(true);
  const [legendFeeding, setLegendFeeding] = useState(true);

  // Contact Form States
  const [showForm, setShowForm] = useState(false);
  const [petStatus, setPetStatus] = useState(PET_STATUS[0]);
  const [location, setLocation] = useState('IN TRANSIT/CARE only');
  const [contactName, setContactName] = useState('IN TRANSIT/CARE only');
  const [contactPhone, setContactPhone] = useState('IN TRANSIT/CARE only');
  const [contactEmail, setContactEmail] = useState('IN TRANSIT/CARE only');
  const [selectedPet, setSelectedPet] = useState(null);

  /**
   * Select pet for change status.
   */
  const handleSelectedPet = async (pet) => {
    setSelectedPet(pet);
    setShowForm(true);
  };

  /**
   * Handle change pet status and change states
   */
  const handleChangePetStatus = (e) => {
    setPetStatus(e.target.value);
    if (e.target.value !== PET_STATUS[1]) {
      setLocation('IN TRANSIT/CARE only');
      setContactName('IN TRANSIT/CARE only');
      setContactPhone('IN TRANSIT/CARE only');
      setContactEmail('IN TRANSIT/CARE only');
    } else {
      setLocation('');
      setContactName('');
      setContactPhone('');
      setContactEmail('');
    }
  };

  const submitPetStatusChange = async (e) => {
    e.preventDefault();

    const petID = selectedPet.DPID || selectedPet.FPID;
    let collection;
    let idType;
    if (petID.substring(0, 2) === 'FP') {
      collection = FOUND_PET_COLLECTION;
      idType = 'FPID';
    } else {
      collection = REGISTERED_PET_COLLECTION;
      idType = 'DPID';
    }
    let petInfoReference;
    let newLocation = '';
    let newContactName = '';
    let newContactPhoneNumber = '';
    let newContactEmail = '';
    if (petStatus === PET_STATUS[1]) {
      newLocation = location;
      newContactName = contactName;
      newContactPhoneNumber = contactPhone;
      newContactEmail = contactEmail;
    }
    try {
      await firestore.collection(PET_STATUS_UPDATE_COLLECTION).add({
        PetDbId: petID,
        NewStatus: petStatus,
        UserID: user.getUID(),
        DateUpdated: getDateTimeString(),
        NewLocation: newLocation,
        NewContactName: newContactName,
        NewContactPhoneNumber: newContactPhoneNumber,
        NewContactEmail: newContactEmail,
      });
      const snapshot = await firestore.collection(collection).where(idType, '==', petID).get();
      await snapshot.docs.forEach(async (doc) => {
        petInfoReference = await firestore.collection(collection).doc(doc.id);
      });
    } catch (err) {
      console.error(err);
    }

    try {
      await petInfoReference.update({
        PetStatus: petStatus,
        CareProviderLocation: newLocation,
        CurrentContactName: newContactName,
        CurrentContactPhoneNumber: newContactPhoneNumber,
        CurrentContactEmail: newContactEmail,
      });
      alert('You’ve successfully changed the status/location of this pet.');
      setShowForm(false);
      // eslint-disable-next-line no-use-before-define
      getAllPets();
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Submit comment to a feeding station
   */
  const submitComment = async (feedingStationId, infoWindowToClose) => {
    const commentDateTime = getDateTimeString();
    const foodType = document.getElementById('food-type-input').value;
    const foodAmount = document.getElementById('food-amount-input').value;
    const observedPets = document.getElementById('observed-pets-input').value;
    const newComment = document.getElementById('comment-input').value;
    const name = document.getElementById('name-input').value;
    const phoneNumber = document.getElementById('phone-number-input').value;
    const email = document.getElementById('email-input').value;

    if (name.length === 0 || phoneNumber.length === 0 || email.length === 0) {
      alert('Please fill out all the essential values.');
      return;
    }
    try {
      await firestore.collection('feedingStationComments').add({
        FeedingStationId: feedingStationId,
        DateTime: commentDateTime,
        FoodType: foodType,
        FoodAmount: foodAmount,
        ObservedPets: observedPets,
        Comment: newComment,
        Name: name,
        PhoneNumber: phoneNumber,
        Email: email,
      });
      infoWindowToClose.close();
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Delete feeding station and related comments.
   */
  const deleteFeedingStationAndComments = async (marker, infoWindowToClose, feedingStationId) => {
    if (window.confirm('Are you sure you want to permanently delete this Feeding Station?')) {
      infoWindowToClose.close();
      marker.setMap(null);
      try {
        await firestore.collection('feedingStations').doc(feedingStationId).delete();
        const comments = await firestore.collection('feedingStationComments').where('FeedingStationId', '==', feedingStationId).get();
        comments.docs.forEach((element) => {
          element.ref.delete();
        });
      } catch (err) {
        console.error(err);
      }
      // eslint-disable-next-line no-use-before-define
      await getAllFeedingStations();
    }
  };

  /**
   * Update markers on the map UI
   */
  const updateMarkers = () => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    const newMarkers = [];
    if (googleMapObject && googleMapsObject) {
      if (legendLost) {
        lostPetsToShow.forEach((pet) => {
          const marker = new googleMapsObject.Marker({
            position: { lat: pet.PetLattitude, lng: pet.PetLongitude },
            map: googleMapObject,
            title: 'place',
            icon: {
              url: 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png',
            },
          });
          // oms.addMarker(marker);
          marker.addListener('click', () => {
            infoWindow.setContent(`
              <div style="display: flex; margin-bottom: 20px; width: 220px; justify-content: space-between;">
                <img src=${pet.PetImageAddress} style="width: 50px; height: 50px; margin-right: 20px;" />
                <div>
                  <p style="margin-bottom: 0px; font-size: 0.8rem;">${pet.FPID || pet.DPID}</p>
                  <p style="margin-bottom: 0px; font-size: 0.8rem;">${pet.PetSpecies}</p>
                  <p style="margin-bottom: 0px; font-size: 0.8rem;">${pet.PetBreed}</p>
                </div>

                <button
                  style="color: white; background-color: red; width: 50px; height: 50px;"
                  onclick="
                    location.pathname = '/PetProfile/${pet.DPID || pet.FPID}';
                  "
                >
                  Pet Profile
                </button>

              </div>
              <p style="margin-bottom: 0px; font-size: 0.8rem;text-align:center;">${pet.PetStatus}</p>
              <p style="margin-bottom: 0px; font-size: 0.8rem;text-align:center;">${pet.PetCity || pet.StreetAddress}</p>
            `);
            infoWindow.open(googleMapObject, marker);
          });
          newMarkers.push(marker);
        });
      }
      if (legendEvacuation) {
        evacPetsToShow.forEach((pet) => {
          const marker = new googleMapsObject.Marker({
            position: { lat: pet.PetLattitude, lng: pet.PetLongitude },
            map: googleMapObject,
            icon: {
              url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
            },
          });
          // oms.addMarker(marker);
          marker.addListener('click', () => {
            infoWindow.setContent(`
              <div style="display: flex; margin-bottom: 20px; width: 220px; justify-content: space-between;">
                <img src=${pet.PetImageAddress} style="width: 50px; height: 50px;" />
                <div>
                  <p style="margin-bottom: 0px; font-size: 0.8rem;">${pet.FPID || pet.DPID}</p>
                  <p style="margin-bottom: 0px; font-size: 0.8rem;">${pet.PetSpecies}</p>
                  <p style="margin-bottom: 0px; font-size: 0.8rem;">${pet.PetBreed}</p>
                </div>
                <button
                  style="color: white; background-color: red; width: 50px; height: 50px; margin-right: 20px;"
                  onclick="
                    location.pathname = '/PetProfile/${pet.DPID || pet.FPID}';
                  "
                >
                  Pet Profile
                </button>
              </div>
              <p style="margin-bottom: 0px; font-size: 0.8rem;text-align:center;">${pet.PetStatus}</p>
              <p style="margin-bottom: 0px; font-size: 0.8rem;text-align:center;">${pet.PetCity || pet.StreetAddress}</p>
            `);
            infoWindow.open(googleMapObject, marker);
          });
          newMarkers.push(marker);
        });
      }
      if (legendFeeding) {
        feedingStations.forEach((station) => {
          const marker = new googleMapsObject.Marker({
            position: { lat: station.Location.Latitude, lng: station.Location.Longitude },
            map: googleMapObject,
            icon: {
              url: 'http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png',
            },
          });
          // oms.addMarker(marker);

          marker.addListener('click', async () => {
            const commentHTMLList = [];
            const comments = await firestore.collection('feedingStationComments').where('FeedingStationId', '==', station.ID).orderBy('DateTime', 'desc').get();
            comments.docs.forEach((comment) => {
              commentHTMLList.push(createCommentBoxDiv(comment.data()));
            });
            feedingInfoWindow.setContent(FeedingStationInfoWindow);
            feedingInfoWindow.open(googleMapObject, marker);

            feedingInfoWindow.addListener('domready', () => {
              const commentSection = document.getElementById('comment-section');
              while (commentSection.firstChild) {
                commentSection.removeChild(commentSection.lastChild);
              }
              for (let i = 0; i < commentHTMLList.length; i += 1) {
                const commentBox = commentHTMLList[i];
                commentSection.appendChild(commentBox);
              }

              document.getElementById('feeding-station-header').textContent = (station.Name === '') ? '' : `[${station.Name}]`;
              document.getElementById('feeding-station-date').value = station.DateTime;
              document.getElementById('food-place').value = station.Address;

              const inputToggleButton = document.getElementById('feeding-station-timeline-button');
              const inputSection = document.getElementById('hidden-input-section');
              inputToggleButton.onclick = () => {
                addToFeedingStationTimeline(inputToggleButton, inputSection);
              };

              const addCommentButton = document.getElementById('comment-submit-button');
              addCommentButton.onclick = () => {
                submitComment(station.ID, feedingInfoWindow);
              };

              const deleteButton = document.getElementById('feeding-station-delete-button');
              deleteButton.onclick = () => {
                deleteFeedingStationAndComments(marker, feedingInfoWindow, station.ID);
              };
            });
          });
          newMarkers.push(marker);
        });
      }
    }
    setMarkers(newMarkers);
  };

  /**
   * Get all pets from firestore
   */
  const getAllPets = async () => {
    const allPets = [];
    // Get pets from Registered Pets
    try {
      const snapshot = await firestore.collection(REGISTERED_PET_COLLECTION).get();
      snapshot.docs.forEach((element) => {
        const data = element.data();
        if (data.DPID === null || data.DPID === undefined) {
          return;
        }
        const PetStatus = String(data.PetStatus);
        if (PetStatus === 'REQUIRES EVACUATION' || PetStatus === 'LOST PET') {
          const {
            PetName, PetBreed, URL, PetAddress, PetCity, PetProvinceState, PetCountry,
            PetPostalCode, PetLattitude, PetLongitude, PetSpecies, DPID, OwnerFirstName,
            OwnerLastName, OwnerPhoneNumber, OwnerEmail, PetAdditionalDetails,
            PetChipNumber, PermissionToEnter, PetLocationKnown, petNearestCityName,
          } = data;
          let streetAddress = `${PetAddress}, ${PetCity}, ${PetProvinceState}, ${PetCountry}, ${PetPostalCode}`;
          const currentPetStatus = String(PetStatus);
          let petSuiteNumber = parseInt(data.PetSuiteNumber, 10);
          petSuiteNumber = !isNaN(petSuiteNumber) ? `#${petSuiteNumber}` : String(data.PetSuiteNumber);

          if (!PetLocationKnown) {
            streetAddress = petNearestCityName;
            petSuiteNumber = '';
          }
          allPets.push({
            DPID, PetName, PetBreed, PetImageAddress: URL, PetAddress, PetCity, PetProvinceState,
            PetCountry, PetPostalCode, PetLattitude, PetLongitude, StreetAddress: streetAddress,
            Location: new googleMapsObject.LatLng(PetLattitude, PetLongitude), PetSpecies,
            OwnerFirstName, OwnerLastName,
            OwnerPhoneNumber, OwnerEmail, PetStatus: currentPetStatus, PermissionToEnter,
            PetChipNumber, PetAdditionalDetails, PetSuiteNumber: petSuiteNumber, PetLocationKnown,
          });
        }
      });
    } catch (err) {
      console.error(err);
    }

    try {
      const snapshot = await firestore.collection(FOUND_PET_COLLECTION).get();
      snapshot.docs.forEach((element) => {
        const data = element.data();
        if (data.FPID === null || data.FPID === undefined) {
          return;
        }

        let PetStatus = String(data.PetStatus);

        if (PetStatus === 'REQUIRES EVACUATION' || PetStatus === 'LOST PET') {
          const {
            PetName, PetBreed, URL, PetAddress, PetCity, PetProvinceState, PetCountry,
            PetPostalCode, PetLattitude, PetLongitude, PetSpecies, FPID, OwnerFirstName,
            OwnerLastName, OwnerPhoneNumber, OwnerEmail, PetAdditionalDetails,
            PetChipNumber, PermissionToEnter, PetLocationKnown, petNearestCityName,
          } = data;
          let streetAddress = `${PetAddress}, ${PetCity}, ${PetProvinceState}, ${PetCountry}, ${PetPostalCode}`;
          let petSuiteNumber = parseInt(data.PetSuiteNumber, 10);
          petSuiteNumber = !isNaN(petSuiteNumber) ? `#${petSuiteNumber}` : String(data.PetSuiteNumber);

          if (!PetLocationKnown) {
            streetAddress = petNearestCityName;
            petSuiteNumber = '';
            PetStatus = 'LOST PET';
          }

          allPets.push({
            FPID, PetName, PetBreed, PetImageAddress: URL, PetAddress, PetCity, PetProvinceState,
            PetCountry, PetPostalCode, PetLattitude, PetLongitude, StreetAddress: streetAddress,
            Location: new googleMapsObject.LatLng(PetLattitude, PetLongitude), PetSpecies,
            OwnerFirstName, OwnerLastName, OwnerPhoneNumber, OwnerEmail, PetStatus,
            PermissionToEnter, PetChipNumber, PetAdditionalDetails, PetSuiteNumber: petSuiteNumber,
            PetLocationKnown,
          });
        }
      });
    } catch (err) {
      console.error(err);
    }
    const needEvac = [];
    const lost = [];
    allPets.forEach((pet) => {
      if (pet.PetStatus === 'REQUIRES EVACUATION') {
        needEvac.push(pet);
      } else if (pet.PetStatus === 'LOST PET') {
        lost.push(pet);
      }
    });
    needEvac.sort(compare);
    lost.sort(compare);
    setPetsNeedEvac(needEvac);
    setLostPets(lost);
    setEvacPetsToShow(needEvac);
    setLostPetsToShow(lost);
  };

  /**
   * Get all feeding stations from firestore.
   */
  const getAllFeedingStations = async () => {
    const newStations = [];
    const snapshot = await firestore.collection('feedingStations').get();
    snapshot.docs.forEach((element) => {
      const feedingStationId = element.id;
      const {
        Name, DateTime, Location, Address,
      } = element.data();
      newStations.push({
        ID: feedingStationId, Name, DateTime, Location, Address,
      });
    });
    setFeedingStations(newStations);
  };

  /**
   * Search Pet Functionality
   */
  const searchPets = (e) => {
    if (e) {
      e.preventDefault();
    }
    const newEvacPetsToShow = [];
    const newLostPetsToShow = [];
    const input = searchPetInputRef.current.toLowerCase();
    if (input === '') {
      evacPetsRef.current.forEach((pet) => {
        if (googleMapObject.getBounds().contains(pet.Location)) {
          newEvacPetsToShow.push(pet);
        }
      });
      lostPetsRef.current.forEach((pet) => {
        if (googleMapObject.getBounds().contains(pet.Location)) {
          newLostPetsToShow.push(pet);
        }
      });
    } else {
      evacPetsRef.current.forEach((pet) => {
        const petValues = Object.values(pet);
        for (let i = 0; i < petValues.length; i += 1) {
          if ((typeof petValues[i] === 'string' || petValues[i] instanceof String) && petValues[i].toLowerCase().indexOf(input) !== -1 && googleMapObject.getBounds().contains(pet.Location)) {
            newEvacPetsToShow.push(pet);
            break;
          }
        }
      });
      lostPetsRef.current.forEach((pet) => {
        const petValues = Object.values(pet);
        for (let i = 0; i < petValues.length; i += 1) {
          if ((typeof petValues[i] === 'string' || petValues[i] instanceof String) && petValues[i].toLowerCase().indexOf(input) !== -1 && googleMapObject.getBounds().contains(pet.Location)) {
            newLostPetsToShow.push(pet);
            break;
          }
        }
      });
    }
    setEvacPetsToShow(newEvacPetsToShow);
    setLostPetsToShow(newLostPetsToShow);
  };

  /**
   * Search map using places autocomplete
   */
  const searchMap = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!autocomplete) {
      return;
    }
    const place = autocomplete.getPlace();
    if (!place) {
      return;
    }
    if (place.formatted_address) {
      setSearchInput(place.formatted_address);
    }

    if (!place.geometry) {
      alert('Please use the mouse or arrows to select a location from the dropdown list.');
      return;
    }

    // If the place has a geometry, then present it on a map.
    if (place.geometry.viewport) {
      googleMapObject.fitBounds(place.geometry.viewport);
    } else {
      googleMapObject.setCenter(place.geometry.location);
      googleMapObject.setZoom(17);
    }
  };

  const addFeedingStation = async () => {
    const feedingStationName = document.getElementById('feeding-station-name-input').value;
    const foodType = document.getElementById('food-type-input').value;
    const foodAmount = document.getElementById('food-amount-input').value;
    const observedPets = document.getElementById('observed-pets-input').value;
    const comment = document.getElementById('comment-input').value;
    const name = document.getElementById('name-input').value;
    const phoneNumber = document.getElementById('phone-number-input').value;
    const email = document.getElementById('email-input').value;
    const dateTime = getDateTimeString();

    if (name.length === 0 || phoneNumber.length === 0 || email.length === 0) {
      alert('Please fill out all the essential values.');
      return;
    }
    let address;

    // Find address of marker
    try {
      const latLng = {
        lat: feedingStationMarker.getPosition().lat(),
        lng: feedingStationMarker.getPosition().lng(),
      };
      const geocoder = new googleMapsObject.Geocoder();
      const result = await (() => new Promise((resolve, reject) => {
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK') {
            resolve(results[0].formatted_address);
          } else {
            reject(new Error('Could not find an address'));
          }
        });
      }))();
      address = result;
    } catch (err) {
      console.log(err);
      return;
    }
    try {
      const documentRef = await firestore.collection('feedingStations').add({
        Name: feedingStationName,
        Location: {
          Latitude: feedingStationMarker.getPosition().lat(),
          Longitude: feedingStationMarker.getPosition().lng(),
        },
        Address: address,
        DateTime: dateTime,
      });
      await firestore.collection('feedingStationComments').add({
        FeedingStationId: documentRef.id,
        DateTime: dateTime,
        FoodType: foodType,
        FoodAmount: foodAmount,
        ObservedPets: observedPets,
        Comment: comment,
        Name: name,
        PhoneNumber: phoneNumber,
        Email: email,
      });
    } catch (err) {
      console.error(err);
      return;
    }

    feedingStationMarker.setMap(null);
    feedingStationMarker = null;
    getAllFeedingStations();
  };

  /**
   * Place feeding station on the map
   */
  const placeFeedingStation = () => {
    if (googleMapsObject && !feedingStationMarker) {
      feedingStationMarker = new googleMapsObject.Marker({
        type: 'Feeding Station',
        icon: 'http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png',
        position: googleMapObject.getCenter(),
        map: googleMapObject,
        draggable: true,
        animation: googleMapsObject.Animation.BOUNCE,
        zIndex: googleMapsObject.Marker.MAX_ZINDEX,
      });

      const feedingStationInfoWindow = new googleMapsObject.InfoWindow({
        content: `${FeedingStationForm}`,
      });

      feedingStationMarker.addListener('click', () => {
        const map = feedingStationInfoWindow.getMap();

        if (!(map !== null && typeof map !== 'undefined')) {
          feedingStationInfoWindow.open(googleMapObject, feedingStationMarker);
        } else {
          feedingStationInfoWindow.close();
        }
      });

      feedingStationInfoWindow.addListener('domready', () => {
        // Add autocomplete places to location input
        const locationInput = document.getElementById('location-input');
        const feedingStationLocation = new googleMapsObject.places.Autocomplete(locationInput);
        feedingStationLocation.addListener('place_changed', () => {
          const place = feedingStationLocation.getPlace();
          if (place) {
            feedingStationMarker.setPosition(place.geometry.location);
          }
        });

        // Add button functionality
        const addButton = document.getElementById('feeding-station-submit-button');
        addButton.onclick = addFeedingStation;

        // Cancel button functionality
        const cancelButton = document.getElementById('feeding-station-cancel-button');
        cancelButton.onclick = () => {
          feedingStationMarker.setMap(null);
        };
      });
    }
  };

  const handleApiLoaded = (map, maps) => {
    googleMapObject = map;
    googleMapsObject = maps;

    if (!maps || !maps.places || !map) {
      window.location.reload();
    }

    autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, { types: ['geocode'] });

    autocomplete.addListener('place_changed', () => {
      searchMap();
    });
    googleMapObject.addListener('bounds_changed', () => {
      searchPets();
    });

    infoWindow = new googleMapsObject.InfoWindow({
      content: '',
    });
    feedingInfoWindow = new googleMapsObject.InfoWindow({
      content: '',
    });
    googleMapsObject.event.addListener(feedingInfoWindow, 'closeclick', () => {
      googleMapsObject.event.clearListeners(feedingInfoWindow, 'domready');
    });
    googleMapsObject.event.addListenerOnce(googleMapObject, 'idle', () => {
      legendRef.current.visibility = 'visible';
    });
    setMapLoaded(true);
    googleMapObject.controls[googleMapsObject.ControlPosition.TOP_RIGHT].push(legendRef.current);
    // const OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier');
    // const options = { markersWontMove: true, markersWontHide: true, basicFormatEvents: true };
    // oms = new OverlappingMarkerSpiderfier(map, options);

    // After loading google map api, get all pets
    getAllPets();
    getAllFeedingStations();
  };

  useEffect(() => {
    updateMarkers();
    // eslint-disable-next-line
  }, [lostPets, petsNeedEvac, feedingStations, legendEvacuation, legendLost, legendFeeding, evacPetsToShow, lostPetsToShow]);

  useEffect(() => {
    searchPets();
    console.log(searchPetInput);
  }, [searchPetInput]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="align-items-center">
      {/* Title */}
      <div className="text-align-center">
        <h2 className="margin-top-20 margin-bottom-10">Evacuation Response Map</h2>
        <p>Lists pets with &quot;Requires Evacuation&quot; and &quot;Lost Pet&quot; status</p>
      </div>
      {/* Buttons */}
      <div className="map-form-inputs justify-content-space-between margin-bottom-20 align-items-center">
        <form className="margin-left-20" onSubmit={searchMap}>
          <input value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder="Enter a location" ref={inputRef} />
          <input type="submit" value="Search" className="button background-four color-white padding-5 margin-left-5 padding-left-10 padding-right-10 cursor-pointer" />
        </form>
        <button onClick={placeFeedingStation} type="button" className="margin-right-20 background-four color-white border-radius-5 flex-column align-items-center justify-content-center padding-5 cursor-pointer">
          Place Feeding Station
          <img src={BOWL_IMG} alt="Bowl" width={30} height={30} />
        </button>
      </div>

      {/* Map */}
      <div id="map" style={{ width: '100%', height: '50vh', minHeight: 300 }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY, libraries: ['places', 'geocoder'] }}
          defaultCenter={{ lat: 49.251, lng: -122.999 }}
          zoom={12}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          options={createMapOptions}
        />
        {mapLoaded && (
          <div ref={legendRef} className="text-align-right margin-right-10 margin-top-10" style={{ visibility: 'hidden' }}>
            <button className="margin-bottom-10 padding-5 background-four cursor-pointer color-white" type="button" onClick={() => setToggleLegend(!toggleLegend)}>
              {toggleLegend ? 'Hide Legend' : 'Show Legend'}
            </button>
            {toggleLegend && (
              <div className="flex-column background-white border padding-10 text-align-center">
                <h3 className="margin-0">Legend</h3>
                <div className="text-align-left flex-column align-items-left">
                  <label htmlFor="Requires Evacuation">
                    <input type="checkbox" id="Requires Evacuation" name="Requires Evacuation" checked={legendEvacuation} onChange={() => setLegendEvacuation(!legendEvacuation)} />
                    <img src="http://maps.google.com/mapfiles/ms/icons/red-dot.png" alt="Red Marker" />
                    Requires Evacuation
                  </label>
                  <label htmlFor="Lost Pet">
                    <input type="checkbox" id="Lost Pet" name="Lost Pet" checked={legendLost} onChange={() => setLegendLost(!legendLost)} />
                    <img src="http://maps.google.com/mapfiles/ms/icons/purple-dot.png" alt="Purple Marker" />
                    Lost Pet
                  </label>
                  <label htmlFor="Feeding Station">
                    <input type="checkbox" id="Feeding Station" name="Feeding Station" checked={legendFeeding} onChange={() => setLegendFeeding(!legendFeeding)} />
                    <img src="http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png" alt="Light Blue Marker" />
                    Feeding Station
                  </label>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="margin-top-30 text-align-center">
        <button className="padding-top-5 padding-bottom-5 padding-left-20 padding-right-20 h3 background-four color-white cursor-pointer border-grey" type="button" onClick={() => window.print()}>Print Page</button>
        <br />
        <strong>IMPORTANT NOTES:</strong>
        <p className="margin-0">Save lives and resource.</p>
        <p className="margin-0" style={{ color: 'red' }}><strong>Change EVACUATION STATUS as soon as a pet is rescued.</strong></p>
        <p>
          This will prevent unnecessary rescue attempts for pets which have already been evacuated.
        </p>

        <form className="align-items-center map-form-inputs justify-content-center margin-bottom-40" onSubmit={searchPets}>
          <label htmlFor="petSearch">
            <b>Search:</b>
            <input type="text" id="petSearch" value={searchPetInput} onChange={(e) => setSearchPetInput(e.target.value)} />
          </label>
          <input className="max-height button h3 background-four color-white cursor-pointer margin-left-10 border-grey" type="submit" value="Search" />
        </form>
      </div>

      <div className="phoneMessage">
        <p>* Turn phone sideways for full view of list</p>
      </div>

      <table className="margin-bottom-20" style={{ borderCollapse: 'collapse', fontSize: '10rem !important' }}>
        <tbody>
          {legendEvacuation && evacPetsToShow.map((pet) => (
            <tr key={pet.DPID || pet.FPID}>
              {/* First Column */}
              <td className="border text-align-center" style={{ width: '25%' }}>
                <img src={pet.PetImageAddress} style={{ width: '80%' }} alt={pet.PetName} />
              </td>
              {/* Second Column */}
              <td className="border text-align-left font-size-1rem erm-second-column" style={{ width: '55%', display: 'table-cell', verticalAlign: 'top' }}>
                <p className="margin-0 font-size-1rem">
                  <span>
                    <b>Disaster Pets ID #: </b>
                    {pet.DPID || pet.FPID}
                  </span>
                </p>
                <p className="font-size-1rem">
                  <span>
                    <b>Name: </b>
                    {pet.PetName}
                  </span>
                </p>
                <p className="margin-0 font-size-1rem">
                  <span>
                    <b>Species: </b>
                    {pet.PetSpecies}
                  </span>
                </p>
                <p className="font-size-1rem">
                  <span>
                    <b>Rescue Location: </b>
                    {`${pet.PetSuiteNumber} ${pet.StreetAddress}`}
                  </span>
                </p>
                <p className="margin-0 font-size-1rem">
                  <b>Owner Contact: </b>
                </p>
                <p className="margin-0 font-size-1rem">{`${pet.OwnerFirstName} ${pet.OwnerLastName}`}</p>
                <p className="margin-0 font-size-1rem">
                  <span>
                    <b>P)</b>
                    {pet.OwnerPhoneNumber}
                  </span>
                </p>
                <p className="font-size-1rem">
                  <span>
                    <b>E)</b>
                    {pet.OwnerEmail}
                  </span>
                </p>
                <p className="font-size-1rem">
                  <b>*Permission to Use Forcible Entry: </b>
                  {pet.PermissionToEnter === 'Yes' ? 'Yes' : 'No'}
                </p>
                <div className="text-align-center">
                  <Link to={`/PetProfile/${pet.DPID || pet.FPID}`}>
                    <button className="background-four color-white h4 padding-5 cursor-pointer margin-bottom-20 border-grey" type="button">Go To Pet Profile</button>
                  </Link>
                </div>
              </td>
              {/* Third Column */}
              <td className="border text-align-center" style={{ width: '20%' }}>
                <p className="margin-0" style={{ color: 'red' }}>
                  <b>REQUIRES EVACUATION</b>
                </p>
                <button
                  className="background-four color-white h5 padding-5 cursor-pointer border-grey"
                  type="button"
                  onClick={() => handleSelectedPet(pet)}
                >
                  CHANGE EVACUATION STATUS
                </button>
              </td>
            </tr>
          ))}
          {legendLost && lostPetsToShow.map((pet) => (
            <tr key={pet.DPID || pet.FPID}>
              {/* First Column */}
              <td className="border text-align-center" style={{ width: '25%' }}>
                <img src={pet.PetImageAddress} style={{ width: '80%' }} alt={pet.PetName} />
              </td>
              {/* Second Column */}
              <td className="border text-align-left erm-second-column" style={{ width: '55%', display: 'table-cell', verticalAlign: 'top' }}>
                <p className="margin-0 font-size-1rem">
                  <span>
                    <b>Disaster Pets ID #: </b>
                    {pet.DPID || pet.FPID}
                  </span>
                </p>
                <p className="font-size-1rem">
                  <span>
                    <b>Name: </b>
                    {pet.PetName}
                  </span>
                </p>
                <p className="margin-0 font-size-1rem">
                  <span>
                    <b>Species: </b>
                    {pet.PetSpecies}
                  </span>
                </p>
                <p className="font-size-1rem">
                  <span>
                    <b>Rescue Location: </b>
                    {`${pet.PetSuiteNumber} ${pet.StreetAddress}`}
                  </span>
                </p>
                <p className="margin-0 font-size-1rem">
                  <b>Owner Contact: </b>
                </p>
                <p className="margin-0 font-size-1rem">{`${pet.OwnerFirstName} ${pet.OwnerLastName}`}</p>
                <p className="margin-0 font-size-1rem">
                  <span>
                    <b>P)</b>
                    {pet.OwnerPhoneNumber}
                  </span>
                </p>
                <p className="font-size-1rem">
                  <span>
                    <b>E)</b>
                    {pet.OwnerEmail}
                  </span>
                </p>
                <p className="font-size-1rem">
                  <b>*Permission to Use Forcible Entry: </b>
                  {pet.PermissionToEnter === 'Yes' ? 'Yes' : 'No'}
                </p>
                <div className="text-align-center">
                  <Link to={`/PetProfile/${pet.DPID || pet.FPID}`}>
                    <button className="background-four color-white h4 padding-5 cursor-pointer border-grey" type="button">Go To Pet Profile</button>
                  </Link>
                </div>
              </td>
              {/* Third Column */}
              <td className="border text-align-center" style={{ width: '20%' }}>
                <p className="margin-0" style={{ color: 'rgb(191, 128, 255)' }}>
                  <b>LOST PET</b>
                </p>
                <button
                  className="background-four color-white h5 padding-5 cursor-pointer margin-bottom-20 border-grey"
                  type="button"
                  onClick={() => handleSelectedPet(pet)}
                >
                  CHANGE EVACUATION STATUS
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <p style={{ fontSize: '0.8rem', marginLeft: '8.3%', marginRight: '8.3%' }}>
        *Disaster Pets is a communications platform and does not have the legal authority to give
        permission to individuals or organizations to enter a dwelling or private property.
        <br />
        Additionally, we have no ability to verify that the individual who is registering the
        pet is actually the owner of either that pet or the property. In order to cover
        yourself legally, Disaster Pets recommends that any groups or individuals
        first obtain explicit permission from authorities to enter a dwelling or
        property before doing so. Disaster Pets does not take responsibility for the
        actions taken by others and does not take on the liability for any damage caused
        to property, or any injury or death to pets or responders.
      </p>

      {/* Form to change pet status */}
      {showForm && (
        <div className="changeStatusModal overflowModal" id="myForm">
          <form className="flex-column padding-10" style={{ maxWidth: '30rem' }} onSubmit={submitPetStatusChange}>
            <h2 className="margin-0 margin-bottom-20">Change Evacuation Status</h2>

            <br />
            <select className="margin-bottom-10" id="status_drop_down" onChange={handleChangePetStatus}>
              {PET_STATUS.map((status) => (
                <option value={status} key={status}>{status}</option>
              ))}
            </select>

            <label htmlFor="new_location" className="flex-column">
              <b>Location:</b>
              <input
                className="padding-10 background-grey border-grey"
                type="text"
                name="new_location"
                required
                disabled={petStatus === PET_STATUS[0] || petStatus === PET_STATUS[2]}
                value={location}
                placeholder="Example: Kelowna Fairgrounds or BC SPCA Victoria"
                onChange={(e) => setLocation(e.target.value)}
                style={{ backgroundColor: `${petStatus === PET_STATUS[1] ? 'lightgrey' : 'rgb(142, 142, 142)'}` }}
              />
            </label>

            <label htmlFor="new_contact_name" className="flex-column">
              <b>Contact Name:</b>
              <input
                className="padding-10 background-grey border-grey"
                type="text"
                name="new_contact_name"
                id="new_contact_name"
                required
                disabled={petStatus === PET_STATUS[0] || petStatus === PET_STATUS[2]}
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                style={{ backgroundColor: `${petStatus === PET_STATUS[1] ? 'lightgrey' : 'rgb(142, 142, 142)'}` }}
              />
            </label>

            <label htmlFor="new_contact_phone" className="flex-column">
              <b>Contact Phone #:</b>
              <input
                className="padding-10 background-grey border-grey"
                type="tel"
                name="new_contact_phone_number"
                required
                disabled={petStatus === PET_STATUS[0] || petStatus === PET_STATUS[2]}
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
                style={{ backgroundColor: `${petStatus === PET_STATUS[1] ? 'lightgrey' : 'rgb(142, 142, 142)'}` }}
              />
            </label>

            <label htmlFor="new_contact_email" className="flex-column margin-bottom-20">
              <b>Contact Email:</b>
              <input
                className="padding-10 background-grey border-grey"
                type="email"
                name="new_contact_email"
                id="new_contact_email"
                required
                disabled={petStatus === PET_STATUS[0] || petStatus === PET_STATUS[2]}
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                style={{ backgroundColor: `${petStatus === PET_STATUS[1] ? 'lightgrey' : 'rgb(142, 142, 142)'}` }}
              />
            </label>

            <input value="Submit" type="submit" className="padding-5 background-four color-white border-radius-5 h3 cursor-pointer margin-bottom-5" />
            <button type="button" className="padding-5 color-white border-radius-5 h3 cursor-pointer" style={{ backgroundColor: 'red' }} onClick={() => setShowForm(false)}>
              Close
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

EvacuationResponseMap.propTypes = {
  firestore: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
};

export default EvacuationResponseMap;
