import React from 'react';
import SubPolicyWithList from './SubPolicyWithList';
import SubPolicyNoList from './SubPolicyNoList';

const PolicyText = ({ policyID, list }) => list.map((item) => {
  const uniqueKey = `${policyID}.${item.subPolicyID}`;
  if (item.list) {
    return (
      <SubPolicyWithList
        key={uniqueKey}
        uniqueKey={uniqueKey}
        policyID={policyID}
        subPolicyID={item.subPolicyID}
        subPolicyText={item.subPolicyText}
        subPolicyList={item.list}
      />
    );
  }
  return (
    <SubPolicyNoList
      key={uniqueKey}
      uniqueKey={uniqueKey}
      policyID={policyID}
      subPolicyID={item.subPolicyID}
      subPolicyText={item.subPolicyText}
    />
  );
});

export default PolicyText;
