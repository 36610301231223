import React from 'react';
import PropTypes from 'prop-types';

const SubordinateField = ({ fields, openModal, remove }) => (
  <li
    className="row list-group-item padding-10 margin-0"
    style={{ listStyleType: 'none' }}
  >
    <span className="col-lg-3 float-left">
      {fields.dataEmail}
    </span>
    <span className="col-lg-3 float-left">
      {fields.role}
    </span>
    <button type="button" onClick={() => openModal(fields)} className="col-lg-2 offset-lg-1 btn btn-warning">
      Transfer Access
    </button>
    <button type="button" className="col-lg-2 offset-lg-1 btn btn-danger" onClick={(event) => remove(event, fields)}>
      Remove Access
    </button>
  </li>
);

SubordinateField.propTypes = {
  remove: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  fields: PropTypes.shape().isRequired,
};
export default SubordinateField;
