const InfoWindowContent = (pet) => (`
  <div style="display: flex; margin-bottom: 20px; width: 220px; justify-content: space-between;">
    <img src=${pet.PetImageAddress} style="width: 50px; height: 50px;" />
    <div>
      <p style="margin-bottom: 0px;font-size: 0.8rem;">${pet.FPID || pet.DPID}</p>
      <p style="margin-bottom: 0px;font-size: 0.8rem;">${pet.PetSpecies}</p>
      <p style="margin-bottom: 0px;font-size: 0.8rem;">${pet.PetBreed}</p>
    </div>
    <button
      style="color: white; background-color: red; width: 50px; height: 50px; margin-right: 20px;"
      onclick="
        window.location.pathname = '/PetProfile/${pet.DPID || pet.FPID}';
      "
    >
      Pet Profile
    </button>

  </div>
  <p style="margin-bottom: 0px; font-size: 0.8rem;text-align:center;">${pet.PetStatus}</p>
  <p style="margin-bottom: 0px; font-size: 0.8rem;text-align:center;">${pet.PetCity || pet.StreetAddress}</p>
`);

export default InfoWindowContent;
