/* eslint-disable global-require */
/* eslint-disable object-property-newline */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import InfoWindowContent from './InfoWindowContent';
import PetRow from './PetDatabaseTable/PetRow';
import getDateTimeString from '../../helpers/getDateTimeString';

const REGISTERED_PET_COLLECTION = 'registeredPets';
const FOUND_PET_COLLECTION = 'foundPets';
const PET_STATUS_UPDATE_COLLECTION = 'petStatusUpdates';

let googleMapObject;
let googleMapsObject;
let autocomplete;
let infoWindow;
let drawingManager;
let aPolygon;
let selectedShape;
// let oms;

// Order is important
const PET_STATUS = [
  'REQUIRES EVACUATION',
  'IN TRANSIT/CARE',
  'REUNITED WITH OWNER',
];

function compare(a, b) {
  // DP comes before FP
  const aID = a.FPID || a.DPID;
  const bID = b.FPID || b.DPID;
  if (aID.substring(0, 1) < bID.substring(0, 1)) return -1;
  if (aID.substring(0, 1) > bID.substring(0, 1)) return 1;

  if (aID.length < bID.length) return -1;
  if (aID.length > bID.length) return 1;
  if (aID < bID) return -1;
  if (aID > bID) return 1;
  return 0;
}

const createMapOptions = (maps) => ({
  fullscreenControlOptions: {
    position: maps.ControlPosition.TOP_LEFT,
  },
});

const SearchPetDatabasePage = ({ firestore, user }) => {
  function useStateRef(state) {
    const stateRef = useRef(state);
    useEffect(() => {
      stateRef.current = state;
    });
    return stateRef;
  }

  const [markers, setMarkers] = useState([]);

  const [evacPets, setEvacPets] = useState([]);
  const [evacPetsToShow, setEvacPetsToShow] = useState([]);
  const [lostPets, setLostPets] = useState([]);
  const [lostPetsToShow, setLostPetsToShow] = useState([]);
  const [inTransitCarePets, setInTransitCarePets] = useState([]);
  const [inTransitCarePetsToShow, setInTransitCarePetsToShow] = useState([]);
  const [foundPets, setFoundPets] = useState([]);
  const [foundPetsToShow, setFoundPetsToShow] = useState([]);
  const [reunitedWithOwnerPets, setReunitedWithOwnerPets] = useState([]);
  const [reunitedWithOwnerPetsToShow, setReunitedWithOwnerPetsToShow] = useState([]);

  const evacPetsRef = useStateRef(evacPets);
  const lostPetsRef = useStateRef(lostPets);
  const inTransitCarePetsRef = useStateRef(inTransitCarePets);
  const foundPetsRef = useStateRef(foundPets);
  const reunitedWithOwnerPetsRef = useStateRef(reunitedWithOwnerPets);

  const [legendEvacuation, setLegendEvacuation] = useState(true);
  const [legendLost, setLegendLost] = useState(true);
  const [legendInTransit, setLegendInTransit] = useState(true);
  const [legendFound, setLegendFound] = useState(true);
  const [legendReunited, setLegendReunited] = useState(true);

  const [searchInput, setSearchInput] = useState('');
  const inputRef = useRef(null);
  const [searchPetInput, setSearchPetInput] = useState('');
  const searchPetInputRef = useStateRef(searchPetInput);
  const legendRef = useRef(null);
  const [toggleLegend, setToggleLegend] = useState(true);

  // Contact Form States
  const [showForm, setShowForm] = useState(false);
  const [petStatus, setPetStatus] = useState(PET_STATUS[0]);
  const [location, setLocation] = useState('IN TRANSIT/CARE only');
  const [contactName, setContactName] = useState('IN TRANSIT/CARE only');
  const [contactPhone, setContactPhone] = useState('IN TRANSIT/CARE only');
  const [contactEmail, setContactEmail] = useState('IN TRANSIT/CARE only');
  const [selectedPet, setSelectedPet] = useState(null);

  /**
   * Handle change pet status and change states
   */
  const handleChangePetStatus = (e) => {
    setPetStatus(e.target.value);
    if (e.target.value !== PET_STATUS[1]) {
      setLocation('IN TRANSIT/CARE only');
      setContactName('IN TRANSIT/CARE only');
      setContactPhone('IN TRANSIT/CARE only');
      setContactEmail('IN TRANSIT/CARE only');
    } else {
      setLocation('');
      setContactName('');
      setContactPhone('');
      setContactEmail('');
    }
  };

  /**
   * Select pet for change status.
   */
  const handleSelectedPet = async (pet) => {
    setSelectedPet(pet);
    setShowForm(true);
    handleChangePetStatus({ target: { value: PET_STATUS[0] } });
  };

  const submitPetStatusChange = async (e) => {
    e.preventDefault();

    const petID = selectedPet.DPID || selectedPet.FPID;
    let collection;
    let idType;
    if (petID.substring(0, 2) === 'FP') {
      collection = FOUND_PET_COLLECTION;
      idType = 'FPID';
    } else {
      collection = REGISTERED_PET_COLLECTION;
      idType = 'DPID';
    }
    let petInfoReference;
    let newLocation = '';
    let newContactName = '';
    let newContactPhoneNumber = '';
    let newContactEmail = '';
    if (petStatus === PET_STATUS[1]) {
      newLocation = location;
      newContactName = contactName;
      newContactPhoneNumber = contactPhone;
      newContactEmail = contactEmail;
    }
    try {
      await firestore.collection(PET_STATUS_UPDATE_COLLECTION).add({
        PetDbId: petID,
        NewStatus: petStatus,
        UserID: user.getUID(),
        DateUpdated: getDateTimeString(),
        NewLocation: newLocation,
        NewContactName: newContactName,
        NewContactPhoneNumber: newContactPhoneNumber,
        NewContactEmail: newContactEmail,
      });
      const snapshot = await firestore.collection(collection).where(idType, '==', petID).get();
      await snapshot.docs.forEach(async (doc) => {
        petInfoReference = await firestore.collection(collection).doc(doc.id);
      });
    } catch (err) {
      console.error(err);
    }

    try {
      await petInfoReference.update({
        PetStatus: petStatus,
        CareProviderLocation: newLocation,
        CurrentContactName: newContactName,
        CurrentContactPhoneNumber: newContactPhoneNumber,
        CurrentContactEmail: newContactEmail,
      });
      alert('You’ve successfully changed the status/location of this pet.');
      setShowForm(false);
      // eslint-disable-next-line no-use-before-define
      getAllPets();
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Search map using places autocomplete
   */
  const searchMap = (e) => {
    if (e) {
      e.preventDefault();
    }
    const place = autocomplete.getPlace();
    if (!place) {
      return;
    }
    if (place.formatted_address) {
      setSearchInput(place.formatted_address);
    }

    if (!place.geometry) {
      alert('Please use the mouse or arrows to select a location from the dropdown list.');
      return;
    }

    // If the place has a geometry, then present it on a map.
    if (place.geometry.viewport) {
      googleMapObject.fitBounds(place.geometry.viewport);
    } else {
      googleMapObject.setCenter(place.geometry.location);
      googleMapObject.setZoom(17);
    }
  };

  const getAllPets = async () => {
    const allPets = [];
    try {
      const snapshot = await firestore.collection(REGISTERED_PET_COLLECTION).get();
      snapshot.docs.forEach((element) => {
        const {
          PetName, PetBreed, URL, PetAddress, PetCity, PetProvinceState, PetCountry, PetPostalCode,
          PetLattitude, PetLongitude, PetSpecies, DPID, OwnerFirstName, OwnerLastName,
          OwnerPhoneNumber, OwnerEmail, PetStatus, PetAdditionalDetails, PetChipNumber,
          PetTattooNumber, PetSuiteNumber, PetLocationKnown, petNearestCityName, PermissionToEnter,
        } = element.data();
        if (DPID === null || DPID === undefined) {
          return;
        }
        let strAddress = `${PetAddress}, ${PetCity}, ${PetProvinceState}, ${PetCountry}, ${PetPostalCode}`;
        const currentPetStatus = String(PetStatus);
        let petSuiteNumber = String(PetSuiteNumber);
        petSuiteNumber = petSuiteNumber !== '' ? `#${petSuiteNumber}` : petSuiteNumber;
        if (!PetLocationKnown) {
          strAddress = petNearestCityName;
          petSuiteNumber = '';
        }
        allPets.push({
          DPID, PetName, PetBreed, PetImageAddress: URL, PetAddress, PetCity, PetProvinceState,
          PetCountry, PetPostalCode, PetLattitude, PetLongitude, StreetAddress: strAddress,
          Location: new googleMapsObject.LatLng(PetLattitude, PetLongitude), PetSpecies,
          OwnerFirstName, OwnerLastName, OwnerPhoneNumber, OwnerEmail,
          PetStatus: currentPetStatus, PetTattooNumber, PermissionToEnter,
          PetChipNumber, PetAdditionalDetails, PetSuiteNumber: petSuiteNumber, PetLocationKnown,
        });
      });
    } catch (err) {
      console.error(err);
    }
    try {
      const snapshot = await firestore.collection(FOUND_PET_COLLECTION).get();
      snapshot.docs.forEach((element) => {
        const {
          PetName, PetBreed, URL, PetAddress, PetCity, PetProvinceState, PetCountry, PetPostalCode,
          PetLattitude, PetLongitude, PetSpecies, FPID, OwnerFirstName, OwnerLastName,
          OwnerPhoneNumber, OwnerEmail, PetStatus, PetAdditionalDetails, PetChipNumber,
          PetTattooNumber, PetSuiteNumber, PetLocationKnown, petNearestCityName, PermissionToEnter,
        } = element.data();
        if (FPID === null || FPID === undefined) {
          return;
        }
        let strAddress = `${PetAddress}, ${PetCity}, ${PetProvinceState}, ${PetCountry}, ${PetPostalCode}`;
        const currentPetStatus = String(PetStatus);
        let petSuiteNumber = String(PetSuiteNumber);
        petSuiteNumber = petSuiteNumber !== '' ? `#${petSuiteNumber}` : petSuiteNumber;
        if (!PetLocationKnown) {
          strAddress = petNearestCityName;
          petSuiteNumber = '';
        }
        allPets.push({
          FPID, PetName, PetBreed, PetImageAddress: URL, PetAddress, PetCity, PetProvinceState,
          PetCountry, PetPostalCode, PetLattitude, PetLongitude, StreetAddress: strAddress,
          Location: new googleMapsObject.LatLng(PetLattitude, PetLongitude), PetSpecies,
          OwnerFirstName, OwnerLastName, OwnerPhoneNumber, OwnerEmail,
          PetStatus: currentPetStatus, PetTattooNumber, PermissionToEnter,
          PetChipNumber, PetAdditionalDetails, PetSuiteNumber: petSuiteNumber, PetLocationKnown,
        });
      });
    } catch (err) {
      console.error(err);
    }

    const evacPetList = [];
    const lostPetList = [];
    const inTransitPetList = [];
    const foundPetList = [];
    const reunitedPetList = [];

    allPets.forEach((pet) => {
      if (pet.PetStatus === 'REQUIRES EVACUATION') {
        evacPetList.push(pet);
      } else if (pet.PetStatus === 'LOST PET') {
        lostPetList.push(pet);
      } else if (pet.PetStatus === 'IN TRANSIT/CARE') {
        inTransitPetList.push(pet);
      } else if (pet.PetStatus === 'FOUND PET') {
        foundPetList.push(pet);
      } else if (pet.PetStatus === 'REUNITED WITH OWNER') {
        reunitedPetList.push(pet);
      }
    });

    evacPetList.sort(compare);
    lostPetList.sort(compare);
    inTransitPetList.sort(compare);
    foundPetList.sort(compare);
    reunitedPetList.sort(compare);

    setEvacPets(evacPetList);
    setEvacPetsToShow(evacPetList);
    setLostPets(lostPetList);
    setLostPetsToShow(lostPetList);
    setInTransitCarePets(inTransitPetList);
    setInTransitCarePetsToShow(inTransitPetList);
    setFoundPets(foundPetList);
    setFoundPetsToShow(foundPetList);
    setReunitedWithOwnerPets(reunitedPetList);
    setReunitedWithOwnerPetsToShow(reunitedPetList);
  };

  const searchPets = (e) => {
    if (e) {
      e.preventDefault();
    }
    const newEvacPetsToShow = [];
    const newLostPetsToShow = [];
    const newInTransitCarePetsToShow = [];
    const newFoundPetsToShow = [];
    const newReunitedPetsToShow = [];
    const input = searchPetInputRef.current.toLowerCase();
    if (input === '') {
      evacPetsRef.current.forEach((pet) => {
        if (googleMapObject.getBounds().contains(pet.Location) && (
          !aPolygon || (
            aPolygon && googleMapsObject.geometry.poly.containsLocation(pet.Location, aPolygon)))) {
          newEvacPetsToShow.push(pet);
        }
      });
      lostPetsRef.current.forEach((pet) => {
        if (googleMapObject.getBounds().contains(pet.Location) && (
          !aPolygon || (
            aPolygon && googleMapsObject.geometry.poly.containsLocation(pet.Location, aPolygon)))) {
          newLostPetsToShow.push(pet);
        }
      });
      inTransitCarePetsRef.current.forEach((pet) => {
        if (googleMapObject.getBounds().contains(pet.Location) && (
          !aPolygon || (
            aPolygon && googleMapsObject.geometry.poly.containsLocation(pet.Location, aPolygon)))) {
          newInTransitCarePetsToShow.push(pet);
        }
      });
      foundPetsRef.current.forEach((pet) => {
        if (googleMapObject.getBounds().contains(pet.Location) && (
          !aPolygon || (
            aPolygon && googleMapsObject.geometry.poly.containsLocation(pet.Location, aPolygon)))) {
          newFoundPetsToShow.push(pet);
        }
      });
      reunitedWithOwnerPetsRef.current.forEach((pet) => {
        if (googleMapObject.getBounds().contains(pet.Location) && (
          !aPolygon || (
            aPolygon && googleMapsObject.geometry.poly.containsLocation(pet.Location, aPolygon)))) {
          newReunitedPetsToShow.push(pet);
        }
      });
    } else {
      evacPetsRef.current.forEach((pet) => {
        if (googleMapObject.getBounds().contains(pet.Location) && (
          !aPolygon || (
            aPolygon && googleMapsObject.geometry.poly.containsLocation(pet.Location, aPolygon)))) {
          const petValues = Object.values(pet);
          for (let i = 0; i < petValues.length; i += 1) {
            if ((typeof petValues[i] === 'string' || petValues[i] instanceof String) && petValues[i].toLowerCase().indexOf(input) !== -1) {
              newEvacPetsToShow.push(pet);
              break;
            }
          }
        }
      });
      lostPetsRef.current.forEach((pet) => {
        if (googleMapObject.getBounds().contains(pet.Location) && (
          !aPolygon || (
            aPolygon && googleMapsObject.geometry.poly.containsLocation(pet.Location, aPolygon)))) {
          const petValues = Object.values(pet);
          for (let i = 0; i < petValues.length; i += 1) {
            if ((typeof petValues[i] === 'string' || petValues[i] instanceof String) && petValues[i].toLowerCase().indexOf(input) !== -1) {
              newLostPetsToShow.push(pet);
              break;
            }
          }
        }
      });
      inTransitCarePetsRef.current.forEach((pet) => {
        if (googleMapObject.getBounds().contains(pet.Location) && (
          !aPolygon || (
            aPolygon && googleMapsObject.geometry.poly.containsLocation(pet.Location, aPolygon)))) {
          const petValues = Object.values(pet);
          for (let i = 0; i < petValues.length; i += 1) {
            if ((typeof petValues[i] === 'string' || petValues[i] instanceof String) && petValues[i].toLowerCase().indexOf(input) !== -1) {
              newInTransitCarePetsToShow.push(pet);
              break;
            }
          }
        }
      });
      foundPetsRef.current.forEach((pet) => {
        if (googleMapObject.getBounds().contains(pet.Location) && (
          !aPolygon || (
            aPolygon && googleMapsObject.geometry.poly.containsLocation(pet.Location, aPolygon)))) {
          const petValues = Object.values(pet);
          for (let i = 0; i < petValues.length; i += 1) {
            if ((typeof petValues[i] === 'string' || petValues[i] instanceof String) && petValues[i].toLowerCase().indexOf(input) !== -1) {
              newFoundPetsToShow.push(pet);
              break;
            }
          }
        }
      });
      reunitedWithOwnerPetsRef.current.forEach((pet) => {
        if (googleMapObject.getBounds().contains(pet.Location) && (
          !aPolygon || (
            aPolygon && googleMapsObject.geometry.poly.containsLocation(pet.Location, aPolygon)))) {
          const petValues = Object.values(pet);
          for (let i = 0; i < petValues.length; i += 1) {
            if ((typeof petValues[i] === 'string' || petValues[i] instanceof String) && petValues[i].toLowerCase().indexOf(input) !== -1) {
              newReunitedPetsToShow.push(pet);
              break;
            }
          }
        }
      });
    }
    setEvacPetsToShow(newEvacPetsToShow);
    setLostPetsToShow(newLostPetsToShow);
    setInTransitCarePetsToShow(newInTransitCarePetsToShow);
    setFoundPetsToShow(newFoundPetsToShow);
    setReunitedWithOwnerPetsToShow(newReunitedPetsToShow);
  };

  const updateMarkers = () => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    const newMarkers = [];
    if (googleMapObject && googleMapsObject) {
      if (legendLost) {
        lostPetsToShow.forEach((pet) => {
          const marker = new googleMapsObject.Marker({
            position: { lat: pet.PetLattitude, lng: pet.PetLongitude },
            map: googleMapObject,
            icon: {
              url: 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png',
            },
          });
          // oms.addMarker(marker);
          marker.addListener('click', () => {
            infoWindow.setContent(InfoWindowContent(pet));
            infoWindow.open(googleMapObject, marker);
          });
          newMarkers.push(marker);
        });
      }
      if (legendEvacuation) {
        evacPetsToShow.forEach((pet) => {
          const marker = new googleMapsObject.Marker({
            position: { lat: pet.PetLattitude, lng: pet.PetLongitude },
            map: googleMapObject,
            icon: {
              url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
            },
          });
          // oms.addMarker(marker);
          marker.addListener('click', () => {
            infoWindow.setContent(InfoWindowContent(pet));
            infoWindow.open(googleMapObject, marker);
          });
          newMarkers.push(marker);
        });
      }
      if (legendFound) {
        foundPetsToShow.forEach((pet) => {
          const marker = new googleMapsObject.Marker({
            position: pet.Location,
            map: googleMapObject,
            icon: {
              url: 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png',
            },
          });
          // oms.addMarker(marker);
          marker.addListener('click', () => {
            infoWindow.setContent(InfoWindowContent(pet));
            infoWindow.open(googleMapObject, marker);
          });
          newMarkers.push(marker);
        });
      }
    }
    setMarkers(newMarkers);
  };

  const deleteDrawnShape = () => {
    if (selectedShape) {
      selectedShape.setMap(null);
      drawingManager.setOptions({
        drawingControl: true,
      });
      aPolygon = '';
      searchPets();
    }
  };

  const setSelectedShape = (shape) => {
    selectedShape = shape;
    shape.setEditable(false);
  };

  const handleApiLoaded = (map, maps) => {
    googleMapObject = map;
    googleMapsObject = maps;
    if (!maps || !maps.places || !maps.drawing || !map) {
      window.location.reload();
    }
    autocomplete = new maps.places.Autocomplete(inputRef.current, { types: ['geocode'] });

    drawingManager = new maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
        drawingModes: ['polygon'],
      },
    });
    drawingManager.setMap(map);

    autocomplete.addListener('place_changed', () => {
      searchMap();
    });
    googleMapObject.addListener('bounds_changed', () => {
      searchPets();
    });
    googleMapsObject.event.addListener(drawingManager, 'overlaycomplete', (event) => {
      if (event.type !== maps.drawing.OverlayType.MARKER) {
        drawingManager.setDrawingMode(null);
        drawingManager.setOptions({
          drawingControl: false,
        });
        // Add an event listener that selects the newly-drawn shape when the user
        // mouses down on it.
        aPolygon = event.overlay;
        const newPolygon = event.overlay;
        newPolygon.type = event.type;
        googleMapsObject.event.addListener(newPolygon, 'click', (e) => {
          if (e.vertex !== undefined) {
            if (newPolygon.type === googleMapsObject.drawing.OverlayType.POLYGON) {
              const path = newPolygon.getPaths().getAt(e.path);
              path.removeAt(e.vertex);
              if (path.length < 3) {
                newPolygon.setMap(null);
              }
            }
          }
          setSelectedShape(newPolygon);
        });
        setSelectedShape(newPolygon);
        searchPets();
      }
    });
    googleMapsObject.event.addListenerOnce(googleMapObject, 'idle', () => {
      legendRef.current.visibility = 'visible';
    });
    infoWindow = new googleMapsObject.InfoWindow({
      content: '',
    });

    googleMapObject.controls[googleMapsObject.ControlPosition.TOP_RIGHT].push(legendRef.current);

    // const OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier');
    // const options = { legWeight: 3, markersWontMove: true };
    // oms = new OverlappingMarkerSpiderfier(map, options);


    // After Google API loads, get all pets
    getAllPets();
  };

  useEffect(() => {
    updateMarkers();
  }, [
    lostPets, evacPets, inTransitCarePets, foundPets,
    reunitedWithOwnerPets, legendLost, legendEvacuation,
    legendInTransit, legendFound, legendReunited, lostPetsToShow, evacPetsToShow,
    foundPetsToShow,
  ]);

  useEffect(() => {
    searchPets();
  }, [searchPetInput]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="align-items-center">
      {/* Title */}
      <div className="text-align-center">
        <h2 className="margin-top-20 margin-bottom-10">Search Pet Database</h2>
        <p>Lists all animals that are registered in Disaster Pets, regardless of status.</p>
      </div>

      <div>
        <form className="margin-left-20" onSubmit={searchMap}>
          <input value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder="Enter a location" ref={inputRef} />
          <input type="submit" value="Search" className="button background-four color-white padding-5 margin-left-5 padding-left-10 padding-right-10 cursor-pointer" />
        </form>
      </div>

      <div id="map" style={{ width: '100%', height: '50vh', minHeight: 300 }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY, libraries: ['places', 'geocoder', 'drawing'] }}
          defaultCenter={{ lat: 49.251, lng: -122.999 }}
          zoom={12}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          options={createMapOptions}
        />
        <div ref={legendRef} className="text-align-right margin-right-10 margin-top-10" style={{ visibility: 'hidden' }}>
          <button className="margin-bottom-10 padding-5 background-four cursor-pointer color-white" type="button" onClick={() => setToggleLegend(!toggleLegend)}>
            {toggleLegend ? 'Hide Legend' : 'Show Legend'}
          </button>
          {toggleLegend && (
            <div className="flex-column background-white border-black padding-10 text-align-center">
              <h3 className="margin-0">Legend</h3>
              <div className="text-align-left flex-column align-items-left">
                <label htmlFor="Requires Evacuation">
                  <input type="checkbox" id="Requires Evacuation" name="Requires Evacuation" checked={legendEvacuation} onChange={() => setLegendEvacuation(!legendEvacuation)} />
                  <img src="http://maps.google.com/mapfiles/ms/icons/red-dot.png" alt="Red Marker" />
                  <span style={{ backgroundColor: 'salmon' }}>Requires Evacuation</span>
                </label>
                <label htmlFor="Lost Pet">
                  <input type="checkbox" id="Lost Pet" name="Lost Pet" checked={legendLost} onChange={() => setLegendLost(!legendLost)} />
                  <img src="http://maps.google.com/mapfiles/ms/icons/purple-dot.png" alt="Blue Marker" />
                  <span style={{ backgroundColor: 'violet' }}>Lost Pet</span>
                </label>
                <label htmlFor="Found Pet">
                  <input type="checkbox" id="Found Pet" name="Found Pet" checked={legendFound} onChange={() => setLegendFound(!legendFound)} />
                  <img src="http://maps.google.com/mapfiles/ms/icons/orange-dot.png" alt="Orange Marker" />
                  <span style={{ backgroundColor: 'orange' }}>Found Pet</span>
                </label>
                <label htmlFor="In Transit/Care">
                  <input type="checkbox" id="In Transit/Care" name="In Transit/Care" checked={legendInTransit} onChange={() => setLegendInTransit(!legendInTransit)} />
                  <span style={{ backgroundColor: 'yellow', marginLeft: 10 }}>In Transit/Care</span>
                </label>
                <label htmlFor="Reunited with Owner">
                  <input type="checkbox" id="Reunited with Owner" name="Reunited with Owner" checked={legendReunited} onChange={() => setLegendReunited(!legendReunited)} />
                  <span style={{ backgroundColor: 'chartreuse', marginLeft: 10 }}>Reunited with Owner</span>
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="text-align-center margin-bottom-10">
        <div className="margin-bottom-20">
          <span>
            <b>Set Boundary: </b>
            Click the icon on the top right of the map then click desired points on the
            map to outline a specified boundary and view pets within.
          </span>
        </div>
        <div className="margin-bottom-20">
          <form className="align-items-center map-form-inputs justify-content-center margin-bottom-40" onSubmit={searchPets}>
            <div className="flex-row justify-content-center align-items-center">
              <label htmlFor="petSearch">
                <b>Search:</b>
                <input type="text" id="petSearch" value={searchPetInput} onChange={(e) => setSearchPetInput(e.target.value)} />
              </label>
              <input
                className="button h4 background-four color-white cursor-pointer margin-left-10 border-grey"
                type="submit"
                value="Search"
                style={{ height: '100%' }}
              />
            </div>

            <button
              className="margin-left-20 h4 background-four color-white cursor-pointer border-grey"
              type="button"
              onClick={deleteDrawnShape}
              style={{ height: '100%' }}
            >
              Delete Selected Boundary
            </button>
          </form>
        </div>
      </div>
      <div className="phoneMessage">
        <p>* Turn phone sideways for full view of list</p>
      </div>
      {/* Pet Table */}
      <table className="margin-bottom-20 margin-left-20 margin-right-20" style={{ borderCollapse: 'collapse', width: 'calc(100% - 40px)' }}>
        <thead>
          <tr>
            <td
              className="petsData text-align-center background-white border-black"
              style={{ width: '10%' }}
            >
              <b>Disaster Pets ID #</b>
            </td>
            <td
              className="petsData text-align-center background-white border-black"
              style={{ width: '10%' }}
            >
              <b>Rescue Location</b>
            </td>
            <td
              className="petsData text-align-center background-white border-black"
              style={{ width: '10%' }}
            >
              <b>Image Thumbnail</b>
            </td>
            <td
              className="petsData text-align-center background-white border-black"
              style={{ width: '10%' }}
            >
              <b>Species</b>
            </td>
            <td
              className="petsData text-align-center background-white border-black"
              style={{ width: '10%' }}
            >
              <b>Breed</b>
            </td>
            <td
              className="petsData text-align-center background-white border-black"
              style={{ width: '10%' }}
            >
              <b>Additional Details</b>
            </td>
            <td
              className="petsData text-align-center background-white border-black"
              style={{ width: '10%' }}
            >
              <b>Name</b>
            </td>
            <td
              className="petsData text-align-center background-white border-black"
              style={{ width: '10%' }}
            >
              <b>Owner Contact</b>
            </td>
            <td
              className="petsData text-align-center background-white border-black"
              style={{ width: '10%' }}
            >
              <b>Details</b>
            </td>
            <td
              className="petsData text-align-center background-white border-black"
              style={{ width: '10%' }}
            >
              <b>Status</b>
            </td>
          </tr>
        </thead>

        <tbody>
          {legendEvacuation && evacPetsToShow.map((pet) => (
            <PetRow pet={pet} colour="lightpink" key={pet.FPID || pet.DPID} selectPet={handleSelectedPet} />
          ))}
          {legendLost && lostPetsToShow.map((pet) => (
            <PetRow pet={pet} colour="rgb(191, 128, 255)" key={pet.FPID || pet.DPID} selectPet={handleSelectedPet} />
          ))}
          {legendInTransit && inTransitCarePetsToShow.map((pet) => (
            <PetRow pet={pet} colour="yellow" key={pet.FPID || pet.DPID} selectPet={handleSelectedPet} />
          ))}
          {legendFound && foundPetsToShow.map((pet) => (
            <PetRow pet={pet} colour="orange" key={pet.FPID || pet.DPID} selectPet={handleSelectedPet} />
          ))}
          {legendReunited && reunitedWithOwnerPetsToShow.map((pet) => (
            <PetRow pet={pet} colour="chartreuse" key={pet.FPID || pet.DPID} selectPet={handleSelectedPet} />
          ))}
        </tbody>
      </table>

      {/* Form to change pet status */}
      {showForm && (
        <div className="changeStatusModal overflowModal" id="myForm">
          <form className="flex-column padding-10" style={{ maxWidth: '30rem' }} onSubmit={submitPetStatusChange}>
            <h1 className="margin-0 margin-bottom-20">Change Evacuation Status</h1>

            <br />
            <select className="margin-bottom-10" id="status_drop_down" onChange={handleChangePetStatus}>
              {PET_STATUS.map((status) => (
                <option value={status} key={status}>{status}</option>
              ))}
            </select>

            <label htmlFor="new_location" className="flex-column">
              <b>Location:</b>
              <input
                className="padding-10 background-grey border-grey"
                type="text"
                name="new_location"
                required
                disabled={petStatus === PET_STATUS[0] || petStatus === PET_STATUS[2]}
                value={location}
                placeholder="Example: Kelowna Fairgrounds or BC SPCA Victoria"
                onChange={(e) => setLocation(e.target.value)}
                style={{ backgroundColor: `${petStatus === PET_STATUS[1] ? 'lightgrey' : 'rgb(142, 142, 142)'}` }}
              />
            </label>

            <label htmlFor="new_contact_name" className="flex-column">
              <b>Contact Name:</b>
              <input
                className="padding-10 background-grey border-grey"
                type="text"
                name="new_contact_name"
                id="new_contact_name"
                required
                disabled={petStatus === PET_STATUS[0] || petStatus === PET_STATUS[2]}
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                style={{ backgroundColor: `${petStatus === PET_STATUS[1] ? 'lightgrey' : 'rgb(142, 142, 142)'}` }}
              />
            </label>

            <label htmlFor="new_contact_phone" className="flex-column">
              <b>Contact Phone #:</b>
              <input
                className="padding-10 background-grey border-grey"
                type="tel"
                name="new_contact_phone_number"
                required
                disabled={petStatus === PET_STATUS[0] || petStatus === PET_STATUS[2]}
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
                style={{ backgroundColor: `${petStatus === PET_STATUS[1] ? 'lightgrey' : 'rgb(142, 142, 142)'}` }}
              />
            </label>

            <label htmlFor="new_contact_email" className="flex-column margin-bottom-20">
              <b>Contact Email:</b>
              <input
                className="padding-10 background-grey border-grey"
                type="email"
                name="new_contact_email"
                id="new_contact_email"
                required
                disabled={petStatus === PET_STATUS[0] || petStatus === PET_STATUS[2]}
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                style={{ backgroundColor: `${petStatus === PET_STATUS[1] ? 'lightgrey' : 'rgb(142, 142, 142)'}` }}
              />
            </label>

            <input value="Submit" type="submit" className="padding-5 background-four color-white border-radius-5 h3 cursor-pointer margin-bottom-5" />
            <button type="button" className="padding-5 color-white border-radius-5 h3 cursor-pointer" style={{ backgroundColor: 'red' }} onClick={() => setShowForm(false)}>
              Close
            </button>
          </form>
        </div>
      )}

    </div>
  );
};

SearchPetDatabasePage.propTypes = {
  firestore: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
};
export default SearchPetDatabasePage;
