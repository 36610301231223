import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';

let googleMapObject;
let googleMapsObject;
let autocomplete;
let docId = '';
const EditPetProfile = ({ firestore, user, fbStorage}) => {
  let collectionName;
  let idType;
  const history = useHistory();

  const [searchInput, setSearchInput] = useState('');
  const searchBarRef = useRef(null);
  const inputRef = useRef(null);
  const params = useParams();
  const petDbId = params.id;
  const userID = user.getUID();

  const [petCoord, setPetCoord] = useState({});
  const [marker, setMarker] = useState({});
  const [profileData, setProfileData] = useState({});
  const [confirmEmail, setConfirmEmail] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [preRegisteredImageURL, setPreRegisteredImageURL] = useState('');

  const defaultImgURL = 'https://firebasestorage.googleapis.com/v0/b/disaster-pets.appspot.com/o/notfound.png?alt=media&token=b0a8286e-93d4-4257-8c41-ba9745c1f436';

  if (petDbId.startsWith('FP')) {
    collectionName = 'foundPets';
    idType = 'FPID';
  }
  else if (petDbId.startsWith('PP')) {
    collectionName = 'preregisteredPet';
    idType = 'PPID';
  }
  else {
    collectionName = 'registeredPets';
    idType = 'DPID';
  }

  const getPetProfile = async(petDbId) => {
    try {
      const snapshot = await firestore.collection(collectionName).where(idType, '==', petDbId).get();
      snapshot.docs.forEach((element) => { docId = element.id; });
      return snapshot.docs.map((element) => element.data());
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  const updateMarker = () => {
    const markers = [];
    if (googleMapObject && googleMapsObject) {
      markers.push(new googleMapsObject.Marker({
        position: {
          lat: petCoord.lat,
          lng: petCoord.lng,
        },
        draggable: true,
        map: googleMapObject,
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
        },
      }));
    }
    setMarker(markers[0]);
  };

  useEffect(() => {
    const getPetData = async () => {
      const newPetProfile = await getPetProfile(petDbId);
      if (newPetProfile[0].UserID !== userID) {
        alert('Error: you dont have access rights to this page');
        history.push(`/PetProfile/${petDbId}`);
      }
      var preview = document.getElementById('editPetImage');
      setPreRegisteredImageURL(newPetProfile[0].URL);
      preview.src = newPetProfile[0].URL;
      preview.style.display = 'block';
      if (newPetProfile[0].URL != defaultImgURL) {
          document.getElementById("removePhotoButton").style.display = 'block';
      }
      setProfileData(newPetProfile[0]);
      setPetCoord({ lng: newPetProfile[0].PetLongitude, lat: newPetProfile[0].PetLattitude });
      setConfirmEmail(newPetProfile[0].OwnerEmail);
    };
    if (user.isAuthenticated()) {
      getPetData();
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    updateMarker();
  }, [petCoord]);

  const handleFormFieldChange = (field, event) => {
    if (field === 'OwnerEmailConfirm') {
      setConfirmEmail(event.target.value);
    } else {
      const updatedProfile = profileData;
      updatedProfile[field] = event.target.value;
      setProfileData(updatedProfile);
    }
  };

  const handleNewImage = (event) => {
    var file = event.target.files[0];
    if (file != null) {
      setSelectedFile(file);
      const preview = document.getElementById('editPetImage');
      preview.style.display = 'block';
      preview.src = URL.createObjectURL(file);
      document.getElementById("imageFileName").textContent = file.name;
      document.getElementById("removePhotoButton").style.display = 'block';
      console.log(file.name);
    }
  };

  const removePhoto = () => {
    const preview = document.getElementById('editPetImage');
    const fileName = document.getElementById("imageFileName");
    const removePhoto = document.getElementById("removePhotoButton");
    
    setSelectedFile(null);
    preview.src = '';
    preview.style.display = 'none';
    fileName.textContent = '';
    removePhoto.style.display = 'none';
    setPreRegisteredImageURL(undefined);
    document.getElementById("image_button").value = "";
  }

  const deleteImage = async (imageRef) => {
    try {
      await imageRef.delete();
    } catch (err) {
      console.log('no such image');
    }
  };

  const uploadImage = async (imageRef) => {
    const metadata = {
      contentType: "image/jpeg"
    };
    try {
      const uploadTaskSnapShot = await imageRef.put(selectedFile, metadata);
      const downLoadURL = await uploadTaskSnapShot.ref.getDownloadURL();
      return downLoadURL;
    } catch (err) {
      console.log(err);
      alert('Photo upload failed. Please refresh the page and try again.');
      return '';
    }
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    const imageRef = await fbStorage.ref(`/petImages/${idType}-${petDbId}-Image-${userID}`);

    if (selectedFile !== null) {
      // A new photo has been uploaded.
      const fbNewImage = async () => {
        if (profileData.URL != defaultImgURL) {
          await deleteImage(imageRef);
        }
        const newImageURL = await uploadImage(imageRef);
        const updatedImageProfile = profileData;
        profileData.URL = newImageURL;
        setProfileData(updatedImageProfile);
      };

      await fbNewImage();

    } else if (!preRegisteredImageURL) {
      // The existing image has been removed. We record the default paw image.
      if (profileData.URL != defaultImgURL) {
          await deleteImage(imageRef);
      }

      const updatedImageProfile = profileData;
      profileData.URL = defaultImgURL;
      setProfileData(updatedImageProfile);
    }

    if (!profileData.PetLocationKnown) {
      const updateLocationProfile = profileData;
      updateLocationProfile.PetLongitude = petCoord.lng;
      updateLocationProfile.PetLattitude = petCoord.lat;
      setProfileData(updateLocationProfile);
    } else {
      const GeoCode = async () => {
        const geoAddress = `${profileData.PetAddress}${profileData.PetCity}${profileData.PetProvinceState}${profileData.PetCountry}`;
        try {
          const geocoder = new googleMapsObject.Geocoder();
          const result = await (() => new Promise((resolve, reject) => {
            geocoder.geocode({ address: geoAddress }, (results, status) => {
              if (status === 'OK') {
                resolve({lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng()});
              } else {
                reject(new Error('Could not find an address'));
              }
            });
          }))();
          const updateLocationGeoProfile = profileData;
          console.log(result);
          updateLocationGeoProfile.PetLattitude = result.lat;
          updateLocationGeoProfile.PetLongitude = result.lng;
          setProfileData(updateLocationGeoProfile);
        } catch (err) {
          console.log(err);
        }
      };
      await GeoCode();
    }
    if (profileData.OwnerEmail !== confirmEmail) {
      alert("Error Owner email and Confirmation email don't match");
    } else {
      await firestore.collection(collectionName).doc(docId).update(profileData);
      alert("Thank you for your patience, this will take just a moment. Please click “Okay” and then wait until you are taken to the pet's profile page");
      history.push(`/PetProfile/${petDbId}`);
    }
  };

  const createMapOptions = (maps) => ({
    streetViewControl: true,
    streetViewControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.LEFT_CENTER,
    },
    fullscreenControlOptions: {
      position: maps.ControlPosition.TOP_LEFT,
    },
  });

  const MoveLocation = (obj) => {
    if (marker) {
      marker.setMap(null);
      console.log(obj.lng, obj.lat);
      setPetCoord({ lng: obj.lng, lat: obj.lat });
    }
  };

  const searchMap = (e) => {
    if (e) {
      e.preventDefault();
    }
    const place = autocomplete.getPlace();
    if (!place) {
      return;
    }
    if (place.formatted_address) {
      setSearchInput(place.formatted_address);
    }

    if (!place.geometry) {
      alert('Please use the mouse or arrows to select a location from the dropdown list.');
      return;
    }

    // If the place has a geometry, then present it on a map.
    if (place.geometry.viewport) {
      googleMapObject.fitBounds(place.geometry.viewport);
    } else {
      googleMapObject.setCenter(place.geometry.location);
      googleMapObject.setZoom(17);
    }
    MoveLocation({ lng: place.geometry.location.lng(), lat: place.geometry.location.lat() });
  };

  const handleApiLoaded = (map, maps) => {
    googleMapObject = map;
    googleMapsObject = maps;
    autocomplete = new maps.places.Autocomplete(inputRef.current, { types: ['address'] });

    autocomplete.addListener('place_changed', () => {
      searchMap();
    });
    googleMapObject.controls[googleMapsObject.ControlPosition.TOP_LEFT].push(searchBarRef.current);
  };

  return (
    <div className="PetProfileContainer" style={{ alignItems: 'center'}}>
      <div className="content-wrap">
        <Link to={`/PetProfile/${petDbId}`}>
          <button className="margin-top-30 margin-bottom-30 background-four color-white padding-5 padding-left-10 padding-right-10 h4" id="editPetButton" type="button">Go Back to Pet Profile Page</button>
        </Link>
        <div className="EditPetProfileWrapper">
          <form className="EditProfileForm" onSubmit={SubmitForm}>
            <br />
            <h3>Edit your Pet's information</h3>
            <h4 className="margin-top-20">Owner Information</h4>
            <label htmlFor="new_first_name" className="EditProfileFormLabel">
              Owner First Name:
              <input
                className="form-control"
                style={{ width: '100%' }}
                type="text"
                name="new_first_name"
                defaultValue={profileData.OwnerFirstName}
                required
                onChange={(event) => {
                  handleFormFieldChange('OwnerFirstName', event);
                }}
              />
            </label>
            <label htmlFor="new_last_name" className="EditProfileFormLabel">
              Owner Last Name:
              <input
                className="form-control"
                style={{ width: '100%' }}
                type="text"
                name="new_last_name"
                defaultValue={profileData.OwnerLastName}
                required
                onChange={(event) => {
                  handleFormFieldChange('OwnerLastName', event);
                }}
              />
            </label>
            <label htmlFor="new_phonenumber" className="EditProfileFormLabel">
              Owner Phone Number:
              <input
                className="form-control"
                style={{ width: '100%' }}
                type="tel"
                name="new_phonenumber"
                defaultValue={profileData.OwnerPhoneNumber}
                required
                onChange={(event) => {
                  handleFormFieldChange('OwnerPhoneNumber', event);
                }}
              />
            </label>
            <label htmlFor="new_email" className="EditProfileFormLabel">
              Owner Email:
              <input
                className="form-control"
                style={{ width: '100%' }}
                type="email"
                name="new_email"
                defaultValue={profileData.OwnerEmail}
                required
                onChange={(event) => {
                  handleFormFieldChange('OwnerEmail', event);
                }}
              />
            </label>
            <label htmlFor="confirm_new_email" className="EditProfileFormLabel">
              Confirmation Email:
              <input
                className="form-control"
                style={{ width: '100%' }}
                type="email"
                name="confirm_new_email"
                defaultValue={profileData.OwnerEmail}
                required
                onChange={(event) => {
                  handleFormFieldChange('OwnerEmailConfirm', event);
                }}
              />
            </label>
            <h4 className="margin-top-20">Pet Information</h4>
            <label htmlFor="new_petname" className="EditProfileFormLabel">
              Pet Name:
              <input
                className="form-control"
                style={{ width: '100%' }}
                type="text"
                name="new_petname"
                defaultValue={profileData.PetName}
                required
                onChange={(event) => {
                  handleFormFieldChange('PetName', event);
                }}
              />
            </label>
            <label htmlFor="new_petspecies" className="EditProfileFormLabel">
              Pet Species:
              <input
                className="form-control"
                style={{ width: '100%' }}
                type="text"
                name="new_petspecies"
                defaultValue={profileData.PetSpecies}
                required
                onChange={(event) => {
                  handleFormFieldChange('PetSpecies', event);
                }}
              />
            </label>
            <label htmlFor="new_petbreed" className="EditProfileFormLabel">
              Pet Breed:
              <input
                className="form-control"
                style={{ width: '100%' }}
                type="text"
                name="new_petbreed"
                defaultValue={profileData.PetBreed}
                onChange={(event) => {
                  handleFormFieldChange('PetBreed', event);
                }}
              />
            </label>
            <label htmlFor="new_petimg" className="EditProfileFormLabel">
              Photo of Pet:
            </label>
            <div className="text-align-left">
              <img 
                id="editPetImage" 
                className="margin-bottom-10"
                style={{ width: "200px" }} 
                alt="Profile Pic" 
              />
              <button type="button" id="removePhotoButton" onClick={removePhoto}>Remove Photo</button>
              <label 
                htmlFor="image_button" 
                className="select_photo_button">Select Photo</label>
              <span id="imageFileName"></span>
              <input
                id="image_button"
                type="file"
                style={{ visibility: 'hidden' }}
                name="new_petimg"
                defaultValue={profileData.URL}
                onChange={(event) => {
                  handleNewImage(event);
                }}
              />
            </div>
            <label htmlFor="new_additionaldetails" className="EditProfileFormLabel">
              Additional Details:
              <input
                className="form-control"
                style={{ width: '100%' }}
                type="text"
                name="new_additionaldetails"
                defaultValue={profileData.PetAdditionalDetails}
                onChange={(event) => {
                  handleFormFieldChange('PetAdditionalDetails', event);
                }}
              />
            </label>
            <label htmlFor="new_petbreed" className="EditProfileFormLabel">
              Microchip Number:
              <input
                className="form-control"
                style={{ width: '100%' }}
                type="text"
                name="new_petbreed"
                defaultValue={profileData.PetChipNumber}
                onChange={(event) => {
                  handleFormFieldChange('PetChipNumber', event);
                }}
              />
            </label>
            <label htmlFor="new_tattoo" className="EditProfileFormLabel">
              Tattoo Number:
              <input
                className="form-control"
                style={{ width: '100%' }}
                type="text"
                name="new_tattoo"
                defaultValue={profileData.PetTattooNumber}
                onChange={(event) => {
                  handleFormFieldChange('PetTattooNumber', event);
                }}
              />
            </label>
            <h4 className="margin-top-20">Pet Location</h4>
            <div style={{ display: !profileData.PetLocationKnown ? 'show' : 'none' }}>
              <div id="map" className="margin-top-30" style={{ width: '100%', height: '50vh' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY, libraries: ['places', 'geocoder'] }}
                  defaultCenter={{ lat: 49.251, lng: -122.999 }}
                  zoom={12}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, profileData)}
                  options={createMapOptions}
                  onClick={MoveLocation}
                />
              </div>
              <div className="flex-row margin-bottom-20 align-items-center margin-right-10 margin-top-10" ref={searchBarRef}>
                <input value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder="Enter a location" ref={inputRef} />
                <input type="button" onClick={searchMap} value="Search" className="button background-four color-white padding-5 margin-left-5 padding-left-10 padding-right-10 cursor-pointer" />
              </div>
            </div>
            {profileData.PetLocationKnown ? (
              <div id="outerAddressDiv">
                <label htmlFor="new_apartment" className="EditProfileFormLabel">
                  Pet's Apartment/Suite Number:
                  <input
                    className="form-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="new_apartment"
                    defaultValue={profileData.PetSuiteNumber}
                    onChange={(event) => {
                      handleFormFieldChange('PetSuiteNumber', event);
                    }}
                  />
                </label>
                <label htmlFor="new_street_address" className="EditProfileFormLabel">
                  Pet's Current Street Address: *
                  <input
                    className="form-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="new_street_address"
                    required
                    defaultValue={profileData.PetAddress}
                    onChange={(event) => {
                      handleFormFieldChange('PetAddress', event);
                    }}
                  />
                </label>
                <label htmlFor="new_city" className="EditProfileFormLabel">
                  Pet's Current City: *
                  <input
                    className="form-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="new_city"
                    required
                    defaultValue={profileData.PetCity}
                    onChange={(event) => {
                      handleFormFieldChange('PetCity', event);
                    }}
                  />
                </label>
                <label htmlFor="new_provincestate" className="EditProfileFormLabel">
                  Pet's Current Province/State: *
                  <input
                    className="form-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="new_provincestate"
                    required
                    defaultValue={profileData.PetProvinceState}
                    onChange={(event) => {
                      handleFormFieldChange('PetProvinceState', event);
                    }}
                  />
                </label>
                <label htmlFor="new_country" className="EditProfileFormLabel">
                  Pet's Current Country: *
                  <input
                    className="form-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="new_country"
                    required
                    defaultValue={profileData.PetCountry}
                    onChange={(event) => {
                      handleFormFieldChange('PetCountry', event);
                    }}
                  />
                </label>
                <label htmlFor="new_postal_code" className="EditProfileFormLabel">
                  Pet's Current Postal Code: *
                  <input
                    className="form-control"
                    style={{ width: '100%' }}
                    type="text"
                    name="new_postal_code"
                    required
                    defaultValue={profileData.PetPostalCode}
                    onChange={(event) => {
                      handleFormFieldChange('PetPostalCode', event);
                    }}
                  />
                </label>
              </div>
            ) : null}
            <button className="margin-top-30 margin-bottom-30 background-four color-white padding-5 padding-left-10 padding-right-10 h4" id="editPetButton" type="submit" value="submit">Update Pet Information</button>
          </form>
        </div>
      </div>
    </div>
  );
};

EditPetProfile.propTypes = {
  firestore: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  fbStorage: PropTypes.shape().isRequired,
};

export default EditPetProfile;
