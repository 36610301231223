import React from 'react';
import Item from './Item';

/**
 * The List component that inserts text
 * @param {object} list An object containing a key and the text.
 */
const List = ({ list }) => list.map((item) => (
  <Item
    key={item.objectID}
    item={item}
  />
));

export default List;
