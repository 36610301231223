import React, { useEffect, useState } from 'react';

const TERMS_AND_CONDITIONS_COLLECTION = 'termsAndConditions';

const TermsAndConditions = ({ firestore }) => {
  const [termsAndConditions, setTermsAndConditions] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Retrieve terms and conditions
    firestore.collection(TERMS_AND_CONDITIONS_COLLECTION).doc('page').get()
      .then((doc) => {
        if (doc.exists) {
          setTermsAndConditions(doc.data().body);
        }
      })
      .catch((error) => {
        console.log('Error getting terms and conditions: ', error);
      }); 

  }, []);

  return (
    <div className="EditPetProfileWrapper">
      <div>
        <h2 className="padding-bottom-20 margin-top-30 text-align-center">Terms and Conditions</h2>
        <pre>
          {termsAndConditions}
        </pre>
      </div>
    </div>
  );
};

export default TermsAndConditions;
