import React, { useState } from 'react';
import GoogleMap from 'google-map-react';
import PropTypes from 'prop-types';
import getPetsArrayInBound from '../FilterPetsInBound';

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const INITIAL_LAT = 49.251;
const INITIAL_LNG = -122.999;
const DEFAULT_ZOOM = 12;

let currentMinLat = 0.0;
let currentMaxLat = 0.0;

let currentMinLng = 0.0;
let currentMaxLng = 0.0;

const initialSettings = {
  startingCoordinate: {
    lat: INITIAL_LAT,
    lng: INITIAL_LNG,
  },
  zoom: DEFAULT_ZOOM,
};

const FoundPetsMap = (
  {
    setFoundPets, allFoundPets, setMapChanged,
    setMapMinLat, setMapMaxLat, setMapMinLng, setMapMaxLng,
  },
) => {
  const [currentMap, setCurrentMap] = useState();

  /**
   * Updates the bounds based on the current map after zooming in/out and dragging.
   */
  const updateCoordinates = () => {
    if (currentMap !== undefined) {
      const northEast = currentMap.getBounds().getNorthEast();
      const southWest = currentMap.getBounds().getSouthWest();

      currentMinLng = southWest.lng();
      currentMaxLng = northEast.lng();
      currentMinLat = southWest.lat();
      currentMaxLat = northEast.lat();

      setMapMinLat(currentMinLat);
      setMapMaxLat(currentMaxLat);
      setMapMinLng(currentMinLng);
      setMapMaxLng(currentMaxLng);

      setMapChanged([currentMinLng, currentMaxLng, currentMinLat, currentMaxLat]);
    }
  };

  /**
   * Initializes the map.
   * @param {*} map a Google Maps map Object
   */
  const apiIsLoaded = (map) => {
    setCurrentMap(map);
    updateCoordinates();
    setFoundPets(
      getPetsArrayInBound(
        allFoundPets,
        currentMinLat,
        currentMinLng,
        currentMaxLat,
        currentMaxLng,
      ),
    );
  };

  /**
   * Calls the updateCoordinates function only when map is loaded
   */
  const waitMapChange = () => {
    if (currentMap !== undefined) {
      updateCoordinates();
    }
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMap
        bootstrapURLKeys={{ key: API_KEY }}
        defaultCenter={initialSettings.startingCoordinate}
        defaultZoom={initialSettings.zoom}
        onGoogleApiLoaded={({ map }) => apiIsLoaded(map)}
        yesIWantToUseGoogleMapApiInternals
        onChange={() => waitMapChange()}
      />
    </div>
  );
};

FoundPetsMap.propTypes = {
  setFoundPets: PropTypes.func.isRequired,
  setMapChanged: PropTypes.func.isRequired,
  allFoundPets: PropTypes.arrayOf(PropTypes.shape).isRequired,
  setMapMinLat: PropTypes.func.isRequired,
  setMapMaxLat: PropTypes.func.isRequired,
  setMapMinLng: PropTypes.func.isRequired,
  setMapMaxLng: PropTypes.func.isRequired,
};

export default FoundPetsMap;
