/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
} from 'react-router-dom';

const REGISTERED_PET_COLLECTION = 'registeredPets';
const FOUND_PET_COLLECTION = 'foundPets';
const PREREGISTERED_PET_COLLECTION = 'preregisteredPet';

/**
 * MyRegisteredPets page.
 */
const MyRegisteredPets = ({ user, firestore }) => {
  const [registeredPets, setRegisteredPets] = useState([]);
  const [foundPets, setFoundPets] = useState([]);
  const [preregisteredPets, setPreregisteredPets] = useState([]);

  /**
   * Get pets in firestore where UserID === user UID in a given collection.
   * @param {*} collectionName Firestore Collection Name
   * @param {*} userID User UID
   */
  const getPetCollection = async (collectionName, userID) => {
    try {
      const snapshot = await firestore.collection(collectionName).where('UserID', '==', userID).orderBy('DateInitialEntry').get();
      return snapshot.docs.map((element) => element.data());
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  /**
   * Get database information if user is authenticated
   */
  useEffect(() => {
    const getAllPets = async () => {
      const userID = user.getUID();
      const newRegisteredPets = await getPetCollection(REGISTERED_PET_COLLECTION, userID);
      const newFoundPets = await getPetCollection(FOUND_PET_COLLECTION, userID);
      const newPreregisteredPets = await getPetCollection(PREREGISTERED_PET_COLLECTION, userID);
      setRegisteredPets(newRegisteredPets);
      setFoundPets(newFoundPets);
      setPreregisteredPets(newPreregisteredPets);
    };
    if (user.isAuthenticated()) {
      getAllPets();
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex-column align-items-center">
      <h2 className="margin-top-20 margin-bottom-20">My Registered Pets</h2>
      {/* Lost Pets */}
      <h2 className="background-grey max-width text-align-center padding-top-10 padding-bottom-10">
        <strong>My Lost Pets</strong>
      </h2>
      <div className="card-container justify-content-center">
        {registeredPets.map((pet) => {
          const {
            DPID, PetName, PetSpecies, PetBreed, URL, PetImage,
          } = pet;
          return (
            <div key={DPID} className="border-grey flex-column align-items-center justify-content-center">
              <img src={URL} width={300} height={300} className="margin-bottom-10" />
              <p>{`Disaster Pets ID #: ${DPID}`}</p>
              <p>{`Name: ${PetName}`}</p>
              <p>{`Species: ${PetSpecies}`}</p>
              <p>{`Breed: ${PetBreed}`}</p>
              <Link to={`/PetProfile/${pet.DPID}`}>
                <button type="button" className="margin-top-10 margin-bottom-10 background-four color-white padding-5 padding-left-10 padding-right-10 h4">
                  Go to Pet Profile Page
                </button>
              </Link>
            </div>
          );
        })}
      </div>
      {/* Found Pets */}
      <h2 className="background-grey max-width text-align-center padding-top-10 padding-bottom-10">
        <strong>My Found Pets</strong>
      </h2>
      <div className="card-container justify-content-center">
        {foundPets.map((pet) => {
          const {
            FPID, PetName, PetSpecies, PetBreed, URL, PetImage,
          } = pet;
          return (
            <div key={FPID} className="border-grey flex-column align-items-center justify-content-center">
              <img src={URL} width={300} height={300} className="margin-bottom-10" />
              <p>{`Disaster Pets ID #: ${FPID}`}</p>
              <p>{`Name: ${PetName}`}</p>
              <p>{`Species: ${PetSpecies}`}</p>
              <p>{`Breed: ${PetBreed}`}</p>
              <Link to={`/PetProfile/${pet.FPID}`}>
                <button type="button" className="margin-top-10 margin-bottom-10 background-four color-white padding-5 padding-left-10 padding-right-10 h4">
                  Go to Pet Profile Page
                </button>
              </Link>
            </div>
          );
        })}
      </div>
      {/* Pre-registered Pets */}
      <h2 className="background-grey max-width text-align-center padding-top-10 padding-bottom-10">
        <strong>My Pre-registered Pets</strong>
      </h2>
      <div className="card-container justify-content-center">
        {preregisteredPets.map((pet) => {
          const {
            PPID, PetName, PetSpecies, PetBreed, URL, PetImage,
          } = pet;
          return (
            <div key={PPID} className="border-grey flex-column align-items-center justify-content-center">
              <img src={URL} width={300} height={300} className="margin-bottom-10" />
              <p>{`Disaster Pets ID #: ${PPID}`}</p>
              <p>{`Name: ${PetName}`}</p>
              <p>{`Species: ${PetSpecies}`}</p>
              <p>{`Breed: ${PetBreed}`}</p>
              <Link to={`/PetProfile/${pet.PPID}`}>
                <button type="button" className="margin-top-10 margin-bottom-10 background-four color-white padding-5 padding-left-10 padding-right-10 h4">
                  Go to Pet Profile Page
                </button>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

MyRegisteredPets.propTypes = {
  user: PropTypes.shape().isRequired,
  firestore: PropTypes.shape().isRequired,
};


export default MyRegisteredPets;
