import React from 'react';
import { Link } from 'react-router-dom';
import PetsIcon from '@material-ui/icons/Pets';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PlaceIcon from '@material-ui/icons/Place';
import ListIcon from '@material-ui/icons/List';
import HowToRegIcon from '@material-ui/icons/HowToReg';

const LOST_PET_URL = require('../../assets/lostpets.png');
const FOUND_PET_URL = require('../../assets/foundpets.png');

/**
 * Menu that a User will see when logged in at Index.html
 */
const LoggedInMenu = () => (
  <div className="justify-content-center flex-row flex-wrap margin-top-20 margin-left-auto margin-right-auto" style={{ width: 1024, maxWidth: '100%', minHeight: '100%' }}>
    <div className="flex text-align-center margin-left-20 margin-right-20" style={{ minWidth: 200 }}>
      <h2 className="margin-bottom-20">Register Pet</h2>
      <Link to="/RegisterLostPet/:id">
        <div className="border-light-grey padding-20 border-radius-5 margin-bottom-10 cursor-pointer background-color-light-grey">
          <img src={LOST_PET_URL} width={70} alt="Lost Pet" />
          <h3>Register Lost Pet</h3>
        </div>
      </Link>
      <Link to="/RegisterFoundPet">
        <div className="border-light-grey padding-20 border-radius-5 margin-bottom-10 cursor-pointer background-color-light-grey">
          <img src={FOUND_PET_URL} width={70} alt="Lost Pet" />
          <h3>Register Found Pet</h3>
        </div>
      </Link>
      <Link to="/PreRegisterPet">
        <div className="border-light-grey padding-20 border-radius-5 margin-bottom-10 cursor-pointer background-color-light-grey">
          <PetsIcon style={{ fontSize: 70, color: '#1ab7ea' }} />
          <h3>Pre-Register Pet</h3>
          <p style={{ color: 'dimgray' }}>(No Current Emergency)</p>
        </div>
      </Link>
    </div>

    <div className="flex text-align-center margin-left-20 margin-right-20" style={{ minWidth: 200 }}>
      <h2 className="margin-bottom-20">See Pets</h2>
      <Link to="/myRegisteredPets">
        <div className="border-light-grey padding-20 border-radius-5 margin-bottom-10 cursor-pointer background-color-light-grey">
          <FavoriteIcon style={{ fontSize: 70, color: '#1ab7ea' }} />
          <h3>My Registered Pets</h3>
        </div>
      </Link>

      <Link to="/lostpets">
        <div className="border-light-grey padding-20 border-radius-5 margin-bottom-10 cursor-pointer background-color-light-grey">
          <ZoomOutIcon style={{ fontSize: 70, color: '#1ab7ea' }} />
          <h3>See All Lost Pets</h3>
        </div>
      </Link>
      {/* TODO change link */}
      <Link to="/foundpets">
        <div className="border-light-grey padding-20 border-radius-5 margin-bottom-10 cursor-pointer background-color-light-grey">
          <ZoomInIcon style={{ fontSize: 70, color: '#1ab7ea' }} />
          <h3>See All Found Pets</h3>
        </div>
      </Link>
    </div>
    <div className="flex text-align-center margin-left-20 margin-right-20" style={{ minWidth: 200 }}>
      <h2 className="margin-bottom-20">Special Access User</h2>
      <Link to="/agencies">
        <div className="border-light-grey padding-20 border-radius-5 margin-bottom-10 cursor-pointer background-color-light-grey">
          <AssignmentIndIcon style={{ fontSize: 70, color: '#1ab7ea' }} />
          <h3>Authorized Response Agencies</h3>
        </div>
      </Link>
      <Link to="/responseMap">
        <div className="border-light-grey padding-20 border-radius-5 margin-bottom-10 cursor-pointer background-color-light-grey">
          <PlaceIcon style={{ fontSize: 70, color: '#1ab7ea' }} />
          <h3>Evacuation Response Map</h3>
        </div>
      </Link>
      <Link to="/petDatabase">
        <div className="border-light-grey padding-20 border-radius-5 margin-bottom-10 cursor-pointer background-color-light-grey">
          <ListIcon style={{ fontSize: 70, color: '#1ab7ea' }} />
          <h3>Search Pet Database</h3>
        </div>
      </Link>
      <Link to="/AuthenticateUser">
        <div className="border-light-grey padding-20 border-radius-5 margin-bottom-10 cursor-pointer background-color-light-grey">
          <HowToRegIcon style={{ fontSize: 70, color: '#1ab7ea' }} />
          <h3>Authentication Manager</h3>
        </div>
      </Link>
    </div>
  </div>
);

export default LoggedInMenu;
