import React from 'react';
import PropTypes from 'prop-types';

const Item = ({ item }) => (
  <div>
    <h3 className="padding-top-30"><u>{item.title}</u></h3>
    {item.text.split('\n').map((sentence) => (
      <p key={sentence} className="margin-10">
        {sentence}
      </p>
    ))}
  </div>
);

Item.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default Item;
