/**
 * User Class to handle user information and authentication state.
 */
class User {
  constructor() {
    this.authenticated = false;
    this.authenticating = true;
    this.uid = '';
    this.email = '';
    this.isAdmin = false;
    this.isGovernment = false;
    this.isOrgAdmin = false;
    this.isVolunteer = false;
  }

  getUID() {
    return this.uid;
  }

  setInfo({ uid, email }) {
    this.uid = uid;
    this.email = email;
  }

  isAuthenticated() {
    return this.authenticated;
  }

  isAuthenticating() {
    return this.authenticating;
  }

  authenticate() {
    this.authenticated = true;
    this.authenticating = false;
  }

  noAuthentication() {
    this.authenticating = false;
  }

  setPermissions(claims) {
    this.isAdmin = !!claims.admin;
    this.isGovernment = !!claims.government;
    this.isOrgAdmin = !!claims.orgAdmin;
    this.isVolunteer = !!claims.volunteer;
  }

  getPermissions() {
    return this.isAdmin || this.isGovernment || this.isOrgAdmin || this.isVolunteer;
  }

  getAdminPermissions() {
    return this.isAdmin;
  }

  getPermissionName() {
    if (this.getPermissions()) {
      if (this.isAdmin) {
        return 'Admin';
      }
      if (this.isGovernment) {
        return 'Government Admin';
      }
      if (this.isOrgAdmin) {
        return 'Organization Admin';
      }
      if (this.isVolunteer) {
        return 'Volunteer';
      }
    }
    return '';
  }
}

export default User;
