import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import Header from './components/Header';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';
import PermissionRoute from './components/PermissionRoute';
import AdminRoute from './components/AdminRoute';

import Index from './pages/Index';
import MyRegisteredPets from './pages/MyRegisteredPets';
import Donate from './pages/Donate';
import About from './pages/About';
import TermsAndConditions from './pages/TermsAndConditions';
import Contact from './pages/Contact';
import ForgotPassword from './pages/ForgotPassword';
import EditPetProfile from './pages/PetProfile/EditPetProfile';
import PetProfile from './pages/PetProfile';
import RegisterLostPet from './pages/RegisterLostPet';
import ResponseMap from './pages/EvacuationResponseMap';
import PetDatabase from './pages/SearchPetDatabase';
import PreRegisterPet from './pages/PreRegisterPet';
import PreRegisterConfirm from './pages/PreRegisterConfirm';
import RegisterFoundPet from './pages/RegisterFoundPet';
import RegisterConfirm from './pages/RegisterConfirm';
import AuthorizedResponseAgencies from './pages/AuthorizedResponseAgencies';
import AuthenticateUser from './pages/AuthenticateUser';

import User from './modules/User/User';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LostPets from './pages/LostPets';
import FoundPets from './pages/FoundPets';
import AdminPage from './pages/Admin';

const config = {
  apiKey: "AIzaSyB9GhVcg9i6Sv7BY8k3P4MPfNIZrJhDQwI",
  authDomain: "disaster-pets.firebaseapp.com",
  databaseURL: "https://disaster-pets.firebaseio.com",
  projectId: "disaster-pets",
  storageBucket: "disaster-pets.appspot.com",
  messagingSenderId: "948435062409",
  appId: "1:948435062409:web:716f9290b46c64c77fc058"
};
firebase.initializeApp(config);
const firestore = firebase.firestore();
const fbFunctions = firebase.functions();
const fbStorage = firebase.storage();
const fbAuth = firebase.auth();

const App = () => {
  const [user, setUser] = useState(new User());

  /**
   * Check for User authentication
   */
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (authUser) => {
      const newUser = { ...user };
      Object.setPrototypeOf(newUser, User.prototype);
      if (authUser && authUser.emailVerified) {
        const { uid, email } = authUser;
        newUser.setInfo({ uid, email });
        const idTokenResult = await authUser.getIdTokenResult();
        newUser.setPermissions(idTokenResult.claims);
        newUser.authenticate();
      } else {
        newUser.noAuthentication();
      }
      setUser(newUser);
    });
  }, []);

  return (
    <Router>

      <Header user={user} />
      <div className="page">
        <Switch>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route
            path="/forgot_password"
            render={(props) => (
              <ForgotPassword {...props} firebase={firebase} />
            )}
          />
          <Route path="/about">
            <About />
          </Route>
          <Route path="/terms">
            <TermsAndConditions firestore={firestore} />
          </Route>
          <Route exact path="/donate">
            <Donate />
          </Route>
          <Route exact path="/privacy_policy">
            <PrivacyPolicy />
          </Route>
          <PrivateRoute
            exact
            path="/agencies"
            component={AuthorizedResponseAgencies}
            firestore={firestore}
            user={user}
          />
          <PrivateRoute
            exact
            path="/AuthenticateUser"
            component={AuthenticateUser}
            user={user}
            firestore={firestore}
            fbFunctions={fbFunctions}
            fbAuth={fbAuth}
          />
          <PrivateRoute
            exact
            path="/lostpets"
            component={LostPets}
            user={user}
            firestore={firestore}
            fbFunctions={fbFunctions}
          />

          <PrivateRoute
            exact
            path="/foundpets"
            component={FoundPets}
            user={user}
            firestore={firestore}
            fbFunctions={fbFunctions}
          />

          <PermissionRoute
            exact
            path="/responseMap"
            component={ResponseMap}
            user={user}
            firestore={firestore}
          />
          <PermissionRoute
            exact
            path="/petDatabase"
            component={PetDatabase}
            user={user}
            firestore={firestore}
          />
          <PrivateRoute
            exact
            path="/myRegisteredPets"
            component={MyRegisteredPets}
            user={user}
            firestore={firestore}
          />
          <PrivateRoute
            exact
            path="/RegisterLostPet/:id"
            component={RegisterLostPet}
            user={user}
            firestore={firestore}
            firebase={firebase}
          />
          <PrivateRoute
            exact
            path="/PreRegisterConfirm"
            component={PreRegisterConfirm}
            user={user}
            firestore={firestore}
          />
          <PrivateRoute
            exact
            path="/PreRegisterPet"
            component={PreRegisterPet}
            user={user}
            firestore={firestore}
            firebase={firebase}
          />
          <PrivateRoute
            exact
            path="/RegisterFoundPet"
            component={RegisterFoundPet}
            user={user}
            firestore={firestore}
            firebase={firebase}
          />
          <PrivateRoute
            exact
            path="/RegisterConfirm"
            component={RegisterConfirm}
            user={user}
            firestore={firestore}
          />
          <PrivateRoute
            exact
            path="/PetProfile/:id"
            component={PetProfile}
            user={user}
            firestore={firestore}
          />
          <PrivateRoute
            exact
            path="/EditPetProfile/:id"
            component={EditPetProfile}
            user={user}
            firestore={firestore}
            fbStorage={fbStorage}
          />
          <AdminRoute
            exact
            path="/admin"
            component={AdminPage}
            user={user}
            firestore={firestore}
          />
          <Route
            exact
            path="/"
            render={(props) => (
              <Index {...props} user={user} firestore={firestore} firebase={firebase} />
            )}
          />
        </Switch>
      </div>
      <Footer firebase={firebase} user={user} />

    </Router>
  );
};

export default App;
