import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';


/**
 * Footer Component
 */
const Footer = ({ firebase, user }) => {
  const location = useLocation();
  /**
   * Log out of Firebase.
   */
  const signOut = (e) => {
    e.preventDefault();
    try {
      firebase.auth().signOut();
      window.scrollTo(0, 0);
    } catch (err) {
      console.log(err);
    }
  };

  const getNotLoggedInMessage = () => (
    <p>
      Welcome to Disaster Pets
    </p>
  );

  const getLoggedInMessage = () => (
    <div>
      <p>
        {`Logged in as ${user.email} `}
        <b>{`${user.getPermissionName()}`}</b>
        <button className="color-white margin-left-20 padding-5 border-radius-5 padding-left-50 padding-right-50" style={{ backgroundColor: '#6c757d' }} type="button" onClick={signOut}>Logout</button>
      </p>
    </div>
  );

  return (
    <footer className="text-align-center padding-30 color-grey">
      {user.isAuthenticated() ? getLoggedInMessage() : getNotLoggedInMessage()}
      {location.pathname === '/' && user.isAuthenticated() && (
        <div>
          Icons made by
          <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
            Freepik
          </a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
      )}
      {location.pathname === '/responseMap' && user.isAuthenticated() && (
        <div>
          Icons made by <a href="https://www.flaticon.com/authors/good-ware" title="Good Ware">Good Ware</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
        </div>
      )}
    </footer>
  );
};

Footer.propTypes = {
  user: PropTypes.shape().isRequired,
  firebase: PropTypes.shape().isRequired,
};

export default Footer;
